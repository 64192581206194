
// Internal
import { WovEnv } from './env'
import {
    WovMarketPlaceGRAPHQLRequestor as WovMarketPlaceRequestor, FilterTypes as WovMarketPlaceAPIFilterTypes,
    OrderByTypes as WovMarketPlaceAPIOrderByTypes
} from './marketplace_graphql'
import { evmToPrintable } from '../crypto/utils/bignumber'
import { logError, logText, logData, getTwitterHandle, getFormattedLocalNumber, getLocalFormattedDate, deepCopyFunction, getRandomElementFromData }
    from '../app/utils/common'
import { cAccountTypes, cTokenDetailsTypes } from '../common/constants'

class WovAccountBase {

    cRequestAPILimitUnlimited = 100
    cUserNameUnknown = '?'
    cQueryParamAddress = 'address'
    cTopCount = 5
    cAllCount = 99999

    _walletAddress = null
    _walletAddressLow = null
    _walletAddressOverruled = false
    _accountType = null
    _isWovAccount = false
    _hasAccountInfo = false
    _accountName = null
    _accountInfo = null
    _chainConnector = null
    _marketplaceAPIReq = null
    _WovProfileID = 0
    _VeBalanceInfo = null
    _WovBalanceInfo = null
    _ipfsQIDMetaData = null
    _ipfsQIDThumb = null
    _midPrice = null

    _marketPlaceTopCollectors = null
    _marketPlaceTopArtists = null

    _tokensInfo = new Map()
    _collectorsInfo = new Map()

    constructor(chainConnector, accountType = cAccountTypes._Base) {

        this._chainConnector = chainConnector
        this._accountType = accountType

        if (this._chainConnector === null) {

            return
        }

        this._walletAddress = this._chainConnector.getWalletAddress().getAddress()

        this._checkForOverruledAddress()

        if (this._walletAddress === null || this._walletAddress.length === 0) {

            return
        }

        this._walletAddressLow = this._walletAddress.toLowerCase()
        this._marketplaceAPIReq = new WovMarketPlaceRequestor()
    }

    _getWoVIDFromTokenID(tokenID) {

        return Math.floor(tokenID / 100000) * 100000
    }

    _getEditionIDFromTokenID(tokenID) {

        return Number(tokenID)
    }

    _getEditionNumberFromTokenID(editionID) {

        let myTokenID = Number.parseInt(this._getWoVIDFromTokenID(editionID))
        let myEditionID = Number.parseInt(editionID)

        return myEditionID - myTokenID
    }

    _convertPriceWOVToVET(priceValue) {

        if (!this._midPrice.isValid) return 0

        return Math.round(priceValue * this._midPrice.toVET)
    }

    _convertPriceVETToWOV(priceValue) {

        if (!this._midPrice.isValid) return 0

        return Math.round(priceValue * this._midPrice.toWOV)
    }

    async _readAccountBlockChain() {

        // Read account VeChain
        this._VeBalanceInfo = await this._chainConnector.getVeChainAccountInfo()

        // Read account info from blockchain smart contract
        const WovInfo = await this._chainConnector.getWoVAccountInfo(WovEnv.smartcontract_wov_accounts_address)

        if (WovInfo !== null && WovInfo[0] !== "0") {

            this._WovProfileID = WovInfo[0]
            this._accountName = WovInfo[4]
            this._ipfsQIDMetaData = WovInfo[2]
            this._ipfsQIDThumb = WovInfo[3]

            // Read WoV Balance
            const myWoVBalance = await this._chainConnector.getWoVBalance(WovEnv.smartcontract_wov_vip180_address)

            if (myWoVBalance) {

                this._WovBalanceInfo = {
                    balance: myWoVBalance,
                    vVET: 0,
                    staked: 0,
                    rewards: 0
                }

                const myWoVStakedBalance = await this._chainConnector.getWoVStakedBalance(WovEnv.stakingAddressList)

                if (myWoVStakedBalance) {

                    this._WovBalanceInfo.staked = myWoVStakedBalance

                    this._WovBalanceInfo.rewards = await this._chainConnector.getWoVStakedRewards(WovEnv.stakingAddressList)
                }

                // Read vVET Balance
                this._WovBalanceInfo.vVET = await this._chainConnector.getWoVBalance(WovEnv.smartcontract_vvet_vip180_address)
            }

            return true

        } else {

            return false
        }

    }

    _isValueValidAddress(value) {

        if (!/^0x[0-9a-f]+$/i.test(value) || value.length % 2 !== 0) {

            return false
        } else return true

    }

    _checkForOverruledAddress() {

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

            const params = new URLSearchParams(window.location.search)

            if (params.has(this.cQueryParamAddress)) {

                const myAccountAddress = params.get(this.cQueryParamAddress)

                if (this._isValueValidAddress(myAccountAddress)) {

                    this._walletAddress = myAccountAddress

                    this._walletAddressOverruled = true

                }
            }
        }
    }

    async _readAccountInfoMarketPlace() {

        let userFromMarketPlace = true

        const userInfo = await this._marketplaceAPIReq.getUserInfo(this._walletAddress)

        if (userInfo === null) {

            userInfo = await window.fetch(WovEnv.url_ipfs + this._ipfsQIDMetaData).then(response => response.json());
            userFromMarketPlace = false

        }

        if (userInfo !== null) {

            this._setUserAccountInfo(userInfo, userFromMarketPlace)

            return true

        } else {

            return false
        }

    }

    // Get the VET/WOV Trading price info
    async _getWOVTradingPrice() {

        let midPriceWov = 0

        try {

            midPriceWov = await this._chainConnector.getVEXTokenTradingPrice(WovEnv.tokenWOV)

        } catch (error) {

            logError('VEX Mid Price - Unable to retrieve ', error)
        }

        this._midPrice = {
            toWOV: midPriceWov,
            toVET: 0,
            isValid: (midPriceWov !== 0 ? true : false)
        }

        if (this._midPrice.isValid) {

            this._midPrice.toVET = (1 / this._midPrice.toWOV)
            this._midPrice.toVET = this._midPrice.toVET.toFixed(5)

            logText(`VEX Mid Price - 1 VET = ${this._midPrice.toWOV} WOV`)
            logText(`VEX Mid Price - 1 WOV = ${this._midPrice.toVET} VET`)

        }

        this._accountInfo.wovVEXMidPrice = this._midPrice.toWOV
        this._accountInfo.wovVEXMidPriceIsValid = this._midPrice.isValid
    }

    _checkUserProfileThumb(userData) {

        if (!userData.profileImageUrl) {

            var myAssetThumbnail = userData.assets.find(el => el.size === "STATIC_COVER_256");

            if (myAssetThumbnail && myAssetThumbnail.url) {

                userData.profileImageUrl = myAssetThumbnail.url;

            } else {

                userData.profileImageUrl = "/img/Wov-Tools%20Logo%20x400.jpg";

            }

        }

    }

    _setUserAccountInfo(userTokenInfo, readFromMarketPlace = true) {

        if (readFromMarketPlace) {

            // Essentially we take what we got - which contains already alot !
            this._accountInfo = userTokenInfo

        }
        else {

            // Build from scratch
            this._accountInfo = {
                name: userTokenInfo.accountName,
                description: userTokenInfo.accountDescription,
                address: userTokenInfo.accountWalletAddress,
                profileImage: WovEnv.url_ipfs + this._ipfsQIDThumb,

            }

        }
        // Do some 'after work'

        // General

        this._checkUserProfileThumb(this._accountInfo);

        /*
        if (!this._accountInfo.profileImageUrl) {

            var myAssetThumbnail = this._accountInfo.assets.find(el => el.size === "STATIC_COVER_256");

            if (myAssetThumbnail && myAssetThumbnail.url) {

                this._accountInfo.profileImageUrl = myAssetThumbnail.url;

            } else {

                this._accountInfo.profileImageUrl = "/img/Wov-Tools%20Logo%20x400.jpg";

            }

        }
        */

        this._accountInfo.profileUrl = WovEnv.url_marketplace_profile + this._accountInfo.address
        this._accountInfo.vetBalanceStr = evmToPrintable(this._VeBalanceInfo.balance)
        this._accountInfo.wovBalanceStr = this._WovBalanceInfo ? evmToPrintable(this._WovBalanceInfo.balance) : ''
        this._accountInfo.vVETBalanceStr = this._WovBalanceInfo ? evmToPrintable(this._WovBalanceInfo.vVET) : ''
        this._accountInfo.wovStakedStr = this._WovBalanceInfo && this._WovBalanceInfo.staked ? evmToPrintable(this._WovBalanceInfo.staked) : ''
        this._accountInfo.wovStakedRewardsStr = this._WovBalanceInfo && this._WovBalanceInfo.rewards ? evmToPrintable(this._WovBalanceInfo.rewards, 2) : ''
        this._accountInfo.wovVEXMidPrice = 0

        // 
        this._accountInfo.coverImage = (this._accountInfo.bannerImageUrl ? this._accountInfo.bannerImageUrl : this._accountInfo.profileImageUrl);

        // Cover Image check
        if (this._accountInfo.coverImage) {

            if (!this._accountInfo.coverImage.startsWith('http')) {

                this._accountInfo.coverImage = 'https://' + this._accountInfo.coverImage
            }

        }

    }

    // Get Market place top collectors
    async _getMarketPlaceTopCollectors() {

        this._marketPlaceTopCollectors = null

        try {

            let myData = await this._marketplaceAPIReq.getTopCollectors()

            if (myData) {

                this._marketPlaceTopCollectors = new Array()

                myData.forEach((data) => {

                    let { createdAt, updatedAt, artist, ...myTopCollector } = data

                    myTopCollector.address = myTopCollector.address.toLowerCase()

                    this._marketPlaceTopCollectors.push(myTopCollector)
                })
            }

        } catch (error) {

            logError('Unable to retrieve market place top collectors ...')
        }


    }

    // Get Market place top artists
    async _getMarketPlaceTopArtists() {

        this._marketPlaceTopArtists = null

        try {

            let myData = await this._marketplaceAPIReq.getTopArtists()

            if (myData) {

                this._marketPlaceTopArtists = new Array()

                myData.forEach((data) => {

                    let { createdAt, updatedAt, artist, ...myTopArtist } = data

                    myTopArtist.address = myTopArtist.address.toLowerCase()

                    this._marketPlaceTopArtists.push(myTopArtist)
                })
            }

        } catch (error) {

            logError('Unable to retrieve market place top artists ...')
        }


    }


    // Initialize Account
    async initializeAccount() {

        this._accountInfo = null
        this._isWovAccount = false
        this._hasAccountInfo = false

        this._isWovAccount = await this._readAccountBlockChain()

        if (this._isWovAccount) {

            this._hasAccountInfo = await this._readAccountInfoMarketPlace()

            if (this._hasAccountInfo) {

                await this._getWOVTradingPrice()
                await this._getMarketPlaceTopCollectors()
                await this._getMarketPlaceTopArtists()

                return true

            } else {

                // TODO ?

                return false
            }

        } else {

            return false
        }
    }



    /*** 
     * 
     * COMMON
     * 
     ***/


    // Updates the user profiles data
    async _updateDataUserProfilesInfo(data) {

        if (!data) return

        let tasks = new Array()

        data.forEach((item, index) => {

            tasks.push(new Promise(async (resolve, reject) => {

                try {

                    const collectorInfo = await this._marketplaceAPIReq.getUserInfo(item.address)

                    let custom = {
                        profileUrl: WovEnv.url_marketplace_profile + collectorInfo.address
                    }

                    let myItem = {
                        ...collectorInfo,
                        ...custom,
                        ...item
                    };

                    if (myItem.name === null || myItem.name.length === 0) {
                        myItem.name = this.cUserNameUnknown
                    }

                    myItem.twitterHandle = ""

                    // Determine twitter
                    if (myItem.twitterUrl) {

                        myItem.twitterHandle = getTwitterHandle(myItem.twitterUrl)
                    }

                    this._checkUserProfileThumb(myItem);

                    data[index] = myItem

                    resolve(index)

                } catch (error) {

                    console.error('User info', error)

                    item.notExisting = true;

                    data[index] = item

                    reject(error)

                }

            }))

        })

        // Let's execute our updates
        const result = await Promise.allSettled(tasks)
    }

    // Sets top indicator flag on given data ( we assume data is sorted based upon whatever criteria )
    _setTopSorted(data, topCount = this.cTopCount) {

        // Top
        for (let i = 0; (i < data.length && i < topCount); i++) {
            data[i].isTopSorted = true;
        }

        // Non-top
        if (data.length > topCount) {

            for (let i = topCount; i < data.length; i++) {
                data[i].isTopSorted = false;
            }
        }
    }

    // Check if the given address is a top collector
    _checkIsTopCollector(address) {

        return (this._marketPlaceTopCollectors && this._marketPlaceTopCollectors.findIndex(el => el.address === address) !== -1 ? true : false)
    }

    // Check if the given address is a top artist
    _checkIsTopArtist(address) {

        return (this._marketPlaceTopArtists && this._marketPlaceTopArtists.findIndex(el => el.address === address) !== -1 ? true : false)
    }

    // Uses the given purchase data to update the collector data with sales info
    _updateDataCollectorPurchaseInfo(purchaseData, collector) {

        // Find the purchase info for that collector
        const myPurchasesInfo = purchaseData.filter(el => el.newOwner.toLowerCase() === collector.address)

        // Generate sales data
        this._sumAddSalesInfo(myPurchasesInfo, collector)

        // If we did have purchases
        if (myPurchasesInfo && myPurchasesInfo.length !== 0) {

            // First one is most recent purchase
            collector.mostRecentPurchaseDate = myPurchasesInfo[0].blockDate
            collector.mostRecentPurchaseTimestamp = myPurchasesInfo[0].blockTimestamp

        } else {

            logError(`No purchase sales history info found for the collector ${collector.address} ?`)
        }

    }


    // Augment given data object with sales details
    _sumAddSalesInfo(purchases, target) {

        if (purchases && purchases.length !== 0) {

            // Primary Sales
            target.hasPrimarySales = true
            target.salesWOVCount = purchases.filter(el => el.isSalesPrimary && !el.isSalesVET).length
            target.salesWOVValue = purchases.filter(el => el.isSalesPrimary && !el.isSalesVET).reduce((prevValue, currentValue) => prevValue + currentValue.price, 0)
            target.salesWOVVETValue = purchases.filter(el => el.isSalesPrimary && !el.isSalesVET).reduce((prevValue, currentValue) => prevValue + currentValue.priceVET, 0)

            target.salesVETCount = purchases.filter(el => el.isSalesPrimary && el.isSalesVET).length
            target.salesVETValue = purchases.filter(el => el.isSalesPrimary && el.isSalesVET).reduce((prevValue, currentValue) => prevValue + currentValue.price, 0)

            target.salesVETTotalValue = target.salesVETValue + target.salesWOVVETValue

            target.salesVETPartTotalPercentage = Math.round(target.salesVETValue / target.salesVETTotalValue * 100)
            target.salesWOVPartTotalPercentage = Math.round(target.salesWOVVETValue / target.salesVETTotalValue * 100)

        } else {

            target.hasPrimarySales = false

            target.salesWOVCount = 0
            target.salesWOVValue = 0
            target.salesWOVVETValue = 0

            target.salesVETCount = 0
            target.salesVETValue = 0

            target.salesVETTotalValue = 0

            target.salesVETPartTotalPercentage = 0
            target.salesWOVPartTotalPercentage = 0
        }

        return target

    }

    // Augment given data object with sales details
    _sumAddPurchasesInfo(purchases, target) {

        if (purchases && purchases.length !== 0) {

            target.purchasesWOVCount = purchases.filter(el => !el.isPurchaseVET).length
            target.purchasesWOVValue = purchases.filter(el => !el.isPurchaseVET).reduce((prevValue, currentValue) => prevValue + currentValue.price, 0)
            target.purchasesWOVVETValue = purchases.filter(el => !el.isPurchaseVET).reduce((prevValue, currentValue) => prevValue + currentValue.priceVET, 0)

            target.purchasesVETCount = purchases.filter(el => el.isPurchaseVET).length
            target.purchasesVETValue = purchases.filter(el => el.isPurchaseVET).reduce((prevValue, currentValue) => prevValue + currentValue.price, 0)

            target.purchasesVETTotalValue = target.purchasesVETValue + target.purchasesWOVVETValue

            target.purchasesVETPartTotalPercentage = Math.round(target.purchasesVETValue / target.purchasesVETTotalValue * 100)
            target.purchasesWOVPartTotalPercentage = Math.round(target.purchasesWOVVETValue / target.purchasesVETTotalValue * 100)

        } else {

            target.purchasesWOVCount = 0
            target.purchasesWOVValue = 0
            target.purchasesWOVVETValue = 0

            target.purchasesVETCount = 0
            target.purchasesVETValue = 0

            target.purchasesVETTotalValue = 0

            target.purchasesVETPartTotalPercentage = 0
            target.purchasesWOVPartTotalPercentage = 0
        }

        return target

    }

    // Update data with price information
    _updateDataPriceInfo(paymentType, data, isSales = true) {

        data.paymentType = (paymentType ? paymentType : '?')

        if (!data.price || data.price === 0) {

            data.price = 0
            data.priceAlt = 0
            data.priceVET = 0
            data.priceWOV = 0
            data.isSalesVET = false
            data.isSalesWOV = false
            data.priceStr = ""
            data.priceAltStr = ""


        } else {

            if (data.paymentType === WovEnv.paymentTypeWOV) {

                data.priceVET = this._convertPriceWOVToVET(data.price)
                data.priceAlt = data.priceVET
                data.priceAltStr = getFormattedLocalNumber(data.priceVET)
                data.priceWOV = data.price

                if (isSales) {

                    data.isSalesVET = false

                } else {

                    data.isPurchaseVET = false
                }


            } else {

                data.priceWOV = this._convertPriceVETToWOV(data.price)
                data.priceAlt = data.priceWOV
                data.priceAltStr = getFormattedLocalNumber(data.priceWOV)
                data.priceVET = data.price

                if (isSales) {

                    data.isSalesVET = true

                } else {

                    data.isPurchaseVET = true
                }

            }
        }

        return data
    }

    // Checks thumbnail assets
    _checkThumbnailAssets(data, useThumbSmall = true, checkVideo = true) {

        // Adjust to cater for new 'assets' container
        if (data.assets) {

            data.media = data.assets;
        }

        // Check if we got an array ( new ? )
        if (data.media && Array.isArray(data.media)) {

            // Determine thumbnail to search for
            let myThumbType = (useThumbSmall ? "STATIC_COVER_256" : "ANIMATED_INSIDE_512")

            // Try to find it
            let myThumb = data.media.find(el => el.size === myThumbType)

            // If not found
            if (!myThumb) {

                // Take first one
                myThumb = data.media[0]
            }

            data.media = myThumb
        }

        // Check for video
        if ((!data.media || !data.media.url) && checkVideo) {

            this._checkAndGenerateVideoType(data);

        }

        // Check type and necedessary data
        if ((!data.media || !data.media.url) && data.fileType && data.fileType !== "video/mp4" && data.fileUrl) {

            if (!data.media) {

                data.media = {}

            }

            data.media.type = data.fileType

            var myFileUrl = data.fileUrl;

            if (myFileUrl.includes("ipfs://")) {

                myFileUrl = myFileUrl.substring(7);
            }

            data.media.url = WovEnv.url_ipfs + myFileUrl;

        }


    }

    // Check and Generate for Video content
    _checkAndGenerateVideoType(data) {

        // Note SDS: For some reason, video content is not provided as an 'asset' so we generate that ourselves
        // TODO 19/12/2022 - data filetype is no longer provided so below code never works. Recheck video content ?

        // Check type and necedessary data
        if (data.fileType && data.fileType === "video/mp4" && data.fileUrl) {

            data.isVideo = true

            if (!data.media) {

                data.media = {}

            }

            data.media.type = data.fileType

            var myFileUrl = data.fileUrl;

            if (myFileUrl.includes("ipfs://")) {

                myFileUrl = myFileUrl.substring(7);
            }

            data.media.url = WovEnv.url_ipfs + myFileUrl;

        } else {

            data.isVideo = false
        }


    }

    _getRandomElementFromData(data) {

        return getRandomElementFromData(data)
    }

    _isGoldenSponsor(tokens) {

        if (!WovEnv.goldenSponsorsNFTList) { return false }

        let isGoldSponser = false

        WovEnv.goldenSponsorsNFTList.forEach((gsNFT) => {

            const isFound = (tokens.findIndex(el => el.tokenWOVID && el.tokenWOVID === Number.parseInt(gsNFT)) !== -1)

            if (isFound) { isGoldSponser = true }
        })

        return isGoldSponser
    }

    _getNewTokenDetailData(detailType, tokenData) {

        return {
            typeID: detailType.ID,
            type: detailType,
            owner: (tokenData && tokenData.owner ? tokenData.owner : ''),
            timestamp: (tokenData && tokenData.blockTimestamp ? tokenData.blockTimestamp : null),
            date: (tokenData && tokenData.blockDate ? tokenData.blockDate : null),
            dateStr: (tokenData && tokenData.blockDate ? getLocalFormattedDate(tokenData.blockDate) : ''),
            price: (tokenData && tokenData.price ? tokenData.price : 0),
            priceStr: (tokenData && tokenData.priceStr ? tokenData.priceStr : ''),
            paymentType: (tokenData && tokenData.paymentType ? tokenData.paymentType : ''),

        }

    }

    // Get Market Place Token Info
    async _getMarketPlaceToken(tokenID) {

        // For now we query with a fixed smart contract address ?  Not sure how or when to make this dynamic

        try {

            return this._marketplaceAPIReq.getToken(WovEnv.smartcontract_wov_communitynft_address, tokenID)

        } catch (error) {

            return null
        }

    }

    async _findCollectorInfo(address) {

        const myCollectorAddress = address.toLowerCase()

        let myCollector = this._collectorsInfo.get(myCollectorAddress)

        if (!myCollector) {

            myCollector = {
                address: myCollectorAddress
            }

            let myData = new Array()
            myData.push(myCollector)

            await this._updateDataUserProfilesInfo(myData)

            myCollector = myData[0]

            this._collectorsInfo.set(myCollectorAddress, myCollector)

        }

        return myCollector

    }

    // Look up token info
    async _lookupTokenInfo(tokenID, useThumbSmall = false, existingCreations = null) {

        // Check we have a token
        if (!tokenID) return null;

        // Check if we have already a token info
        let myTokenInfo = this._tokensInfo.get(tokenID)

        if (!myTokenInfo) {

            // Get Token WOVID
            let myTokenWOVID = this._getWoVIDFromTokenID(tokenID)

            let myCreationInfo = null

            // Check we have existing tokens
            if (existingCreations && existingCreations.length !== 0) {

                myCreationInfo = deepCopyFunction(existingCreations.find(el => el.tokenWOVID === myTokenWOVID))

            }

            if (!myCreationInfo) {


                myCreationInfo = await this._getMarketPlaceToken(myTokenWOVID)
            }

            if (!myCreationInfo) {

                return null
            }

            // Main Token Info object
            this._checkThumbnailAssets(myCreationInfo, useThumbSmall);

            myTokenInfo = myCreationInfo
            myTokenInfo.creator.address = myTokenInfo.creator.address.toLowerCase()
            myTokenInfo.tokenEditionID = tokenID
            myTokenInfo.tokenWOVID = myTokenWOVID
            myTokenInfo.edition = this._getEditionNumberFromTokenID(myTokenInfo.tokenEditionID)

        } else {


        }

        // Token Listings    
        let myTokenListingsInfo = await this._chainConnector.getMarketplaceTokenListed(WovEnv.smartcontract_wov_marketplace_address, tokenID)

        if (myTokenListingsInfo) {

            myTokenInfo.history = new Array()

            if (myTokenListingsInfo && myTokenListingsInfo.history && myTokenListingsInfo.history.length !== 0) {

                let myTokenHistories = myTokenListingsInfo.history

                let myTokenInitialList = null
                let myTokenPrevPrice = null

                for (var i = 0; i < myTokenHistories.length; i++) {

                    let myTokenHistory = myTokenHistories[i]

                    let myPrevTokenHistory = (i > 0 ? myTokenHistories[i - 1] : null)

                    let myTokenDetail = this._getNewTokenDetailData(cTokenDetailsTypes.Unknown, myTokenHistory)

                    if (myTokenHistory.isListed) {

                        if (myTokenHistory.owner === myTokenInfo.creator.address) {

                            if (i == 0) {

                                myTokenDetail.type = cTokenDetailsTypes.InitialList

                            } else {

                                myTokenDetail.type = cTokenDetailsTypes.ReListArtist

                            }

                        } else {

                            myTokenDetail.type = cTokenDetailsTypes.ReListCollector
                        }

                    } else {

                        if (myPrevTokenHistory && myPrevTokenHistory.owner === myTokenHistory.owner) {

                            if (myTokenHistory.owner === myTokenInfo.creator.address) {

                                myTokenDetail.type = cTokenDetailsTypes.CancelListArtist

                            } else {

                                // Not sure we want this cancelation if the next one is a relist ?
                                myTokenDetail.type = cTokenDetailsTypes.CancelListCollector
                            }

                        } else {

                            if (myPrevTokenHistory && myPrevTokenHistory.owner === myTokenInfo.creator.address) {

                                myTokenDetail.type = cTokenDetailsTypes.SalesPrimary

                            } else {

                                myTokenDetail.type = cTokenDetailsTypes.SalesSecondary

                            }
                        }
                    }

                    myTokenDetail.typeID = myTokenDetail.type.ID

                    // Check for collector
                    if (myTokenHistory.owner !== myTokenInfo.creator.address) {

                        myTokenDetail.collector = await this._findCollectorInfo(myTokenHistory.owner)

                    }

                    // Update price info
                    this._updateDataPriceInfo(myTokenDetail.paymentType, myTokenDetail, true)

                    // Grab our first initial listing or primary sales to reference again for artist price diff
                    if (myTokenDetail.type === cTokenDetailsTypes.InitialList || myTokenDetail.type === cTokenDetailsTypes.SalesPrimary) {

                        myTokenInitialList = myTokenDetail
                    }

                    // Determine price difference ( if needed )
                    if (myTokenDetail.priceVET !== 0) {

                        if (myTokenInitialList && myTokenInitialList.priceVET) {

                            myTokenDetail.priceDiffArtistValue = myTokenDetail.priceVET - myTokenInitialList.priceVET

                            myTokenDetail.priceDiffArtistPerc = Math.round((myTokenDetail.priceVET / myTokenInitialList.priceVET) * 100) - 100

                            myTokenDetail.priceDiffArtistPlus = (myTokenDetail.priceDiffArtistPerc > 0 ? true : false)

                        } else {

                            myTokenDetail.priceDiffArtistValue = myTokenDetail.priceDiffArtistPerc = 0
                        }

                        if (myTokenPrevPrice) {

                            myTokenDetail.priceDiffPrevValue = myTokenDetail.priceVET - myTokenPrevPrice.priceVET

                            myTokenDetail.priceDiffPrevPerc = Math.round((myTokenDetail.priceVET / myTokenPrevPrice.priceVET) * 100) - 100

                        } else {

                            myTokenDetail.priceDiffPrevValue = myTokenDetail.priceDiffPrevPerc = 0

                        }

                        myTokenDetail.priceDiffPrevPlus = (myTokenDetail.priceDiffPrevPerc > 0 ? true : false)

                    }

                    if (myTokenDetail.type === cTokenDetailsTypes.SalesSecondary) {

                        myTokenDetail.isSalesSecondary = true

                        this._determineArtistRoyalty(myTokenDetail, myTokenInfo.royalty)

                        myTokenPrevPrice = myTokenDetail

                    }

                    if (myTokenDetail.type === cTokenDetailsTypes.ReListCollector
                        || myTokenDetail.type === cTokenDetailsTypes.ReListArtist) {

                        myTokenPrevPrice = myTokenDetail

                    }

                    // Add data
                    myTokenInfo.history.push(myTokenDetail)

                }

            }

        }

        // Sort details
        if (myTokenInfo.history) {

            myTokenInfo.history.sort(this._sorterByTimestamp)

            let myMostRecentDetail = myTokenInfo.history[0]

            if (myMostRecentDetail.type === cTokenDetailsTypes.InitialList
                || myMostRecentDetail.type === cTokenDetailsTypes.ReListArtist
                || myMostRecentDetail.type === cTokenDetailsTypes.ReListCollector) {

                // Merge objects so we have price info    
                myTokenInfo = { ...myMostRecentDetail, ...myTokenInfo }

                // Is this Secondary or not ?
                if (myMostRecentDetail.type === cTokenDetailsTypes.ReListCollector) {

                    myTokenInfo.isListingSecondary = true
                    myTokenInfo.isListingPrimary = false

                } else {

                    myTokenInfo.isListingSecondary = false
                    myTokenInfo.isListingPrimary = true

                }

            } else {

                // Do selective merge
                myTokenInfo.collector = myMostRecentDetail.collector

            }

        }

        // Create or outpdate the data
        this._tokensInfo.set(tokenID, myTokenInfo)

        // Return
        return myTokenInfo
    }

    _determineArtistRoyalty(data, royaltyPercentage = 0) {

        let myRoyalty = (data.royalty ? data.royalty : royaltyPercentage)

        if (myRoyalty !== 0) {

            if (data.isSalesVET) {


                data.priceRoyaltyVET = Math.round((data.priceVET * myRoyalty) / 100)
                data.priceRoyaltyWOV = 0

            } else {

                data.priceRoyaltyWOV = Math.round((data.priceWOV * myRoyalty) / 100)
                data.priceRoyaltyVET = this._convertPriceWOVToVET(data.priceRoyaltyWOV)
            }

        } else {

            data.priceRoyaltyVET = 0
            data.priceRoyaltyWOV = 0

        }

        data.royaltyPerc = myRoyalty

        return data

    }

    _getTokenInfo(tokenID) {

        return this._tokensInfo.get(tokenID)
    }

    _getTokenEditionID(tokenID, editionCount) {

        let myEditionNumber = Number.parseInt(tokenID) + editionCount

        return new Number(myEditionNumber)
    }

    isValueValidTokenID(tokenID) {

        if (tokenID === null || tokenID.length <= 10) return false

        return !Number.isNaN(tokenID)
    }

    // Lookup Token Info
    async lookupTokenInfo(tokenID) {

        return await this._lookupTokenInfo(tokenID)
    }

    // Lookup Token All Editions
    async lookupTokenAllEditionsInfo(tokenID, editionsCount, existingCreations) {

        if (editionsCount === 0) return null

        // Generate the editions id's range
        let myInfo = new Array(editionsCount)

        for (let i = 1; i <= editionsCount; i++) {

            let myEditionID = this._getTokenEditionID(tokenID, i)

            myInfo[i - 1] = ({ tokenEditionID: myEditionID, history: null })

        }

        let tasks = new Array()

        myInfo.forEach((info, index) => {

            tasks.push(new Promise(async (resolve, reject) => {

                try {

                    let myTokenEditionInfo = await this._lookupTokenInfo(info.tokenEditionID, true)

                    info.history = myTokenEditionInfo.history

                    resolve(index)

                } catch (error) {

                }
            }))

        })

        // Let's execute our updates
        const result = await Promise.allSettled(tasks)

        return myInfo
    }


    /* NEW */

    async _getUserActivity(address, withUserInfo = false, withCollectionInfo = false) {

        try {

            return await this._marketplaceAPIReq.getUserActivity(address, withUserInfo, withCollectionInfo);

        } catch (error) {

            return null
        }
    }

    async getUserActivity(address, withUserInfo = false, withCollectionInfo = false) {

        return await this._getUserActivity(address, withUserInfo, withCollectionInfo);
    }


    async _getTokenActivity(addressSM, tokenID, withUserInfo = false, withCollectionInfo = false) {

        try {

            return await this._marketplaceAPIReq.getTokenActivity(addressSM, tokenID, withUserInfo, withCollectionInfo);

        } catch (error) {

            return null
        }
    }

    /***      
     * 
     * GENERAL GETTERS
     * 
     ***/

    isWovAccount() {

        return this._isWovAccount
    }

    isValidAccount() {

        return this.isWovAccount() && this.hasAccountInfo()
    }

    isOverruledAccount() {

        return this._walletAddressOverruled
    }

    hasAccountInfo() {

        return this._hasAccountInfo
    }

    getAccountInfo() {

        return this._accountInfo
    }

    getAccountType() {

        return this._accountType
    }

    isVerified() {

        return this._accountInfo && this._accountInfo.verified
    }

    isBlackListed() {

        return this._accountInfo && this._accountInfo.blacklisted
    }

    getTopCount() {

        return this.cTopCount
    }

    getTokenDetailedInfo(tokenID) {

        return this._getTokenInfo(tokenID)
    }

    /***      
     * 
     * COMMON ARTISTS
     * 
     ***/

    // Artist - Load Creations
    async loadArtistCreations(address = this._walletAddress, withEditions = false, limit = this.cRequestAPILimitUnlimited) {

        let targetData = null

        const createdMarketPlaceData = await this._marketplaceAPIReq.getUserCreated(address, limit, withEditions)

        if (createdMarketPlaceData) {

            targetData =
                createdMarketPlaceData.reduce((acc, item) => {

                    // Reduce
                    let { editions, ...myItem } = item

                    // Determine editions on sale
                    myItem.onSaleCount = (editions ? editions.filter(el => el.salePrice !== null).length : 0)

                    // Additional data   
                    myItem.id = myItem.tokenID = myItem.tokenId;
                    myItem.tokenWOVID = this._getWoVIDFromTokenID(myItem.tokenId)

                    myItem.tokenUrl = WovEnv.url_marketplace_token + myItem.smartContractAddress + '/' + myItem.tokenWOVID

                    // Check for thumbnail
                    this._checkThumbnailAssets(myItem);

                    // Store  
                    acc.push(myItem)

                    return acc
                }, [])

        }

        return targetData
    }

    // Artist - Load Collections
    async loadArtistCollections(address = this._walletAddress, limit = this.cRequestAPILimitUnlimited) {

        let targetData = null

        const collectionsMarketPlaceData = await this._marketplaceAPIReq.getUserCollections(address, limit)

        if (collectionsMarketPlaceData) {

            targetData =
                collectionsMarketPlaceData.reduce((acc, myItem) => {

                    // Reduce item and remove all what we don't need                    
                    //let { creator, ...myItem } = item

                    // We need to reverse a bit the ID's (old API legacy)                                       
                    myItem.collectionID = myItem.collectionId

                    // Store  
                    acc.push(myItem)

                    return acc
                }, [])

        }

        return targetData
    }

    /*** 
     * 
     * SORTERS
     * 
     ***/

    _sorterByItemsCollected2Price(a, b) {

        const cUserNameUnknown = '?'

        let diff = b.itemsCollected - a.itemsCollected

        if (diff != 0) {

            return diff

        } else {

            if (b.salesVETTotalValue && a.salesVETTotalValue) {

                return b.salesVETTotalValue - a.salesVETTotalValue

            } else if (b.salesVETTotalValue) {

                return 99
            } else return -99
        }
    }

    _sorterByEditionsCollected2Name(a, b) {

        const cUserNameUnknown = '?'

        let diffe = b.editionsCollected - a.editionsCollected
        let diffi = b.itemsCollected - a.itemsCollected

        if (diffe != 0) {

            return diffe

        } else if (diffi != 0) {

            return diffi

        } else if (a.name && a.name.localeCompare(cUserNameUnknown) === 0) {

            return 99

        } else if (b.name && b.name.localeCompare(cUserNameUnknown) === 0) {

            return -99

        } else if (a.name && b.name) {

            return a.name.localeCompare(b.name)

        }
        else {
            return 0
        }
    }

    _sorterByItemsCollected2Name(a, b) {

        const cUserNameUnknown = '?'

        let diff = b.itemsCollected - a.itemsCollected

        if (diff != 0) {

            return diff

        } else if (a.name && a.name.localeCompare(cUserNameUnknown) === 0) {

            return 99

        } else if (b.name && b.name.localeCompare(cUserNameUnknown) === 0) {

            return -99

        } else if (a.name && b.name) {

            return a.name.localeCompare(b.name)

        }
        else {
            return 0
        }
    }

    getSorterByItemsCollected() {

        return this._sorterByItemsCollected2Name
    }

    getSorterByEditionsCollected() {

        return this._sorterByEditionsCollected2Name
    }

    _sorterByTotalSalesPriceVET(a, b) {

        if (b.salesVETTotalValue && a.salesVETTotalValue) {

            return b.salesVETTotalValue - a.salesVETTotalValue

        } else if (b.salesVETTotalValue) {

            return 99
        } else return -99

    }

    getSorterByTotalSalesPriceVET() {

        return this._sorterByTotalSalesPriceVET
    }

    _sorterByTotalPurchasePriceVET(a, b) {

        if (b.purchasesVETTotalValue && a.purchasesVETTotalValue) {

            return b.purchasesVETTotalValue - a.purchasesVETTotalValue

        } else if (b.purchasesVETTotalValue) {

            return 99
        } else return -99

    }

    getSorterByTotalPurchasePriceVET() {

        return this._sorterByTotalPurchasePriceVET
    }

    _sorterByPurchaseDate(a, b) {

        return b.mostRecentPurchaseTimestamp - a.mostRecentPurchaseTimestamp
    }


    _sorterByTimestamp(a, b) {

        return b.timestamp - a.timestamp
    }

    sortByItemsCollected(data) {

        // Sort by items collected descending + alphabetical on name
        return data.sort(this._sorterByItemsCollected2Price)

    }

    _sortByTokenWOV(a, b) {

        if (a.tokenWOVID && b.tokenWOVID) {
            return a.tokenWOVID - b.tokenWOVID

        } else return 0

    }

    sortByTokenWOV(data) {

        return data.sort(this._sortByTokenWOV)
    }

    _sortByTokenEdition(a, b) {

        if (a.tokenEditionID && b.tokenEditionID) {
            return a.tokenEditionID - b.tokenEditionID

        } else return 0

    }

    sortByTokenEdition(data) {

        return data.sort(this._sortByTokenEdition)
    }
}

export {
    WovAccountBase,
    cAccountTypes
}