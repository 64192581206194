// World of Art Defaults
const WovEnv = {
    tokenWOV: '0x170F4BA8e7ACF6510f55dB26047C83D13498AF8A',
    paymentTypeWOV: 'WoV',
    url_nfttools_token_info: 'https://wovnft.tools/token/?id=',
    api_marketplace: 'https://api.marketplace.worldofv.art',
    graphql_marketplace: 'https://mainnet.api.worldofv.art/graphql',
    url_marketplace: 'https://marketplace.worldofv.art',
    url_marketplace_profile: 'https://marketplace.worldofv.art/profile/',
    url_marketplace_token: 'https://marketplace.worldofv.art/token/',
    url_marketplace_collection: 'https://marketplace.worldofv.art/collection/',
    url_ipfs: 'https://worldofv-marketplace.mypinata.cloud/ipfs/',
    smartcontract_wov_marketplace_address: '0xC7FD71b05b3060FCE96E4B6cDc6eC353FA6F838e',
    smartcontract_wov_marketplace_address2: '0xc3F851F9f78c92573620582BF9002f0C4a114B67',
    smartcontract_wov_accounts_address: '0xc7592f90A6746E5D55e4a1543b6caE6D5b11d258',
    smartcontract_wov_communitynft_address: '0x5E6265680087520DC022d75f4C45F9CCD712BA97',
    smartcontract_wov_vip180_address: '0x170F4BA8e7ACF6510f55dB26047C83D13498AF8A',
    smartcontract_wov_stake_address: '0x8394E3753F663F75c7E2c4a1AbD4BdE3F7ECAcac',
    smartcontract_vvet_vip180_address: '0x45429A2255e7248e57fce99E7239aED3f84B7a53',
    stakingAddressList: ["0x1d51d37EFb86C921b26EBf36AAf55215227CB0B6", "0x8394E3753F663F75c7E2c4a1AbD4BdE3F7ECAcac", "0x610daDbC01545875BC16176E71035bB54f1A647D", "0x8b53fEa26B11B27eDeE14A8B67E8c038e4356601", "0xF6B3e7cfde716Ff52a1bb1d140701991559Ddb97"],
    goldenSponsorsNFTList: ["28500500100000"],
    addressBurning: ""
}

export {
    WovEnv
}