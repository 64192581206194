
// Local
import {
    findElementByID, hideElement, showElement, setElementTextDirect, findSourceContainer
} from './utils/dom'
import { logError, logData } from './utils/common'
import {
    getTemplateNameTokenInfo, cTokenInfoOptions_TOKEN_INFO, renderTokenInfo
} from './common'

const cTokenContentContainer = 'tok-main'
const cTokenInfoContainer = 'token-info'
const cTokenLookupContainer = 'token-lookup'
const cTokenResultContainer = 'token-result'
const cTokenRestartContainer = 'token-restart'


const isTokenInfoPage = async () => {

    const myElement = await findElementByID(cTokenInfoContainer)

    return (myElement ? true : false)
}

const showTokenContent = async () => {

    await showElement(cTokenContentContainer)
}

const resetTokenContent = async () => {

    await hideElement(cTokenContentContainer)
}

const showTokenLookup = async () => {

    await showElement(cTokenLookupContainer)
}

const hideTokenLookup = async () => {

    await hideElement(cTokenLookupContainer)
}

const showTokenResult = async () => {

    await showElement(cTokenResultContainer)
}

const hideTokenResult = async () => {

    await hideElement(cTokenResultContainer)
}

const showTokenRestart = async () => {

    await showElement(cTokenRestartContainer)
}

const hideTokenRestart = async () => {

    await hideElement(cTokenRestartContainer)
}

const renderLookUpTokenInfo = async (tokenInfo, sourceContainer = null, resetContainer = true, isModal = false) => {

    logData('Token Info', tokenInfo)

    if (!tokenInfo) { return }

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cTokenResultContainer, resetContainer)

    if (mySourceContainer === null) return false

    const cTemplateTokenInfo = getTemplateNameTokenInfo(false)

    // Find the token template 
    const myTokenTemplate = await findElementByID(cTemplateTokenInfo)

    if (!myTokenTemplate) {

        logError('Cannot find template ', cTemplateTokenInfo)

    }

    mySourceContainer.appendChild(await renderTokenInfo(tokenInfo, myTokenTemplate, cTokenInfoOptions_TOKEN_INFO, isModal))

}

const showErrorMessage = async (errorText) => {

    await setElementTextDirect('token-error-text', errorText)

    await showElement('token-error')
}

const hideErrorMessage = async () => {

    await hideElement('token-error')
}

export {
    isTokenInfoPage,
    showTokenLookup,
    hideTokenLookup,
    showTokenResult,
    hideTokenResult,
    showTokenRestart,
    hideTokenRestart,
    renderLookUpTokenInfo,
    showErrorMessage,
    hideErrorMessage,
    showTokenContent,
    resetTokenContent
}