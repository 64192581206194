import { remove } from "js-cookie"
import { getElementData } from "./dom"

const logText = (text) => {

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

        console.log(text)

    }
}

const logError = (text) => {

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

        console.error(text)

    }
}

const logData = (dataText, data) => {

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

        console.log('[Data => ' + dataText + ' ]')
        console.table(data)

    }
}

const copyTextDataToClipboard = async (textValue) => {

    var data = [new ClipboardItem({ "text/plain": new Blob([textValue], { type: "text/plain" }) })];

    navigator.clipboard.write(data).then(async function () {

    }, function () {

        logError("Unable to write to clipboard. :-(");
    });

}

const copyDataIDToClipboard = async (element, dataID) => {

    let myDataValue = await getElementData('', dataID, element)

    await copyTextDataToClipboard(myDataValue)

}

const convertBase64ToBlob = (base64, type) => {
    var bytes = window.atob(base64);
    var ab = new ArrayBuffer(bytes.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ab], { type: type });
}


const copyImageDataToClipboard = async (imageBase64Value, imageType) => {

    let myDataParts = imageBase64Value.split(',')

    let myIndex = 1

    if (myDataParts.length < 2) myIndex = 0

    const blobInput = convertBase64ToBlob(myDataParts[myIndex], imageType)

    let data = [new ClipboardItem({ [blobInput.type]: blobInput })]

    navigator.clipboard.write(data).then(async function () {

    }, function () {

        logError("Unable to copy image to clipboard. :-(");
    });

}

// https://javascript.plainenglish.io/how-to-deep-copy-objects-and-arrays-in-javascript-7c911359b089
const deepCopyFunction = (inObject) => {
    let outObject, value, key

    if (typeof inObject !== "object" || inObject === null) {
        return inObject // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {}

    for (key in inObject) {
        value = inObject[key]

        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = deepCopyFunction(value)
    }

    return outObject
}

const doNothing = async () => {

    return true
}


const getTwitterHandle = (twitterData) => {

    /*
    account.js:752 Twitter Handle ? @  from  https://www.Twitter.com/VeCorgi/
    eval @ account.js:752
    account.js:752 Twitter Handle ? @1465550758343061506?s=21  from  https://twitter.com/sillyonion972/status/1465550758343061506?s=21

    */

    let myTwitterUrl = twitterData
    let myTwitterHandle = '@'

    if (myTwitterUrl.startsWith(myTwitterHandle)) {

        myTwitterHandle = myTwitterUrl

    } else {

        // Grab the last handle out of the twitter url 

        let myTwitterParts = myTwitterUrl.split('/')

        let myTwitterPartsLength = myTwitterParts.length

        myTwitterHandle = myTwitterHandle + myTwitterParts[myTwitterPartsLength - 1]

        if (myTwitterPartsLength !== 4) {

            console.error('Twitter Handle ?', myTwitterHandle, ' from ', myTwitterUrl)
        }

    }

    return myTwitterHandle
}

const getTokenEditionfromTokenID = (tokenID, removeLeadingZeroes = true) => {

    if (!tokenID) return ""

    let myTokenID = tokenID.toString()

    myTokenID = myTokenID.substring(myTokenID.length - 5)

    return (removeLeadingZeroes ? myTokenID.replace(/^0+/, '') : myTokenID)
}

const getFormattedLocalNumber = (number) => {

    return Number(number).toLocaleString()
}

const getLocalFormattedDate = (utcDate, useNearTiming = false) => {

    if (utcDate === null) return '?'

    try {

        if (useNearTiming) {

            const secondsPassed = ((Date.now() - utcDate.getTime()) / 1000)

            //const secondsPassed = 12050

            if (secondsPassed < 30) {

                return 'Just NOW'

            } else if (secondsPassed <= 60) {

                return 'Last minute'

            } else if (secondsPassed <= 300) {

                return 'Last 5 minutes'
            }

            else if (secondsPassed <= 3600) {

                return 'Last hour'
            }
            else if (secondsPassed <= 86400) {

                const hours = Math.round(secondsPassed / 3600)

                return `Last ${hours} hours`
            }
            else return new Intl.DateTimeFormat('default', { dateStyle: 'short', timeStyle: 'short' }).format(utcDate)

        } else return new Intl.DateTimeFormat('default', { dateStyle: 'short', timeStyle: 'short' }).format(utcDate)

    } catch (error) {

        logError('Unknown date time object ? ', utcDate)
    }
}

const getLocalFormattedDateAsMonth = (utcDate) => {

    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/formatToParts

    const formatter1 = new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'UTC'
    });

    const formatter2 = new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC'
    });

    const dateParts1 = formatter1.formatToParts(utcDate)
    const dateParts2 = formatter2.formatToParts(utcDate)

    const myYear = dateParts1.find(el => el.type === 'year').value
    const myMonthNum = dateParts1.find(el => el.type === 'month').value
    const myMonthStr = dateParts2.find(el => el.type === 'month').value

    return myYear + '/' + myMonthNum.toString().padStart(2, '0') + ' - ' + myMonthStr[0].toUpperCase() + myMonthStr.slice(1)

}

const modalizeID = (id, isModal = false) => {

    return (isModal ? "modal-" + id : id)
}

const getTimeLeftText = (secondsLeft) => {

    let timeLeft = 0
    let timeText = ''

    if (secondsLeft > 60) {

        timeLeft = Math.round(secondsLeft / 60)

        if (timeLeft > 1) {
            timeText = 'minutes'
        } else {
            timeText = 'minute'
        }

    } else {

        timeLeft = secondsLeft

        if (timeLeft > 1) {
            timeText = 'seconds'
        } else {
            timeText = 'second'
        }
    }

    return `'${timeLeft}' ${timeText}`

}

const getRandomIndexFromData = (data) => {

    if (!data || data.length === 0) return 0

    const index = Math.floor(Math.random() * data.length)

    return index
}


const getRandomElementFromData = (data) => {

    if (!data || data.length === 0) return 0

    const index = getRandomIndexFromData(data)

    return data[index]
}

export {
    logText,
    logError,
    logData,
    doNothing,
    copyTextDataToClipboard,
    copyDataIDToClipboard,
    copyImageDataToClipboard,
    deepCopyFunction,
    getTwitterHandle,
    getTokenEditionfromTokenID,
    getFormattedLocalNumber,
    getLocalFormattedDate,
    getLocalFormattedDateAsMonth,
    getTimeLeftText,
    modalizeID,
    getRandomIndexFromData,
    getRandomElementFromData
}