
// Local
import {
    findElementByID, clearElement, hideElement, showElement, setElementText, setElementTextDirect, setElementImageSource,
    getElementFromTemplate, setElementDynamic, setElementData, findSourceContainer, setElementStyle, setElementClass
} from './utils/dom'
import { WovEnv } from '../wov/env'
import { logError, getFormattedLocalNumber, getLocalFormattedDate, logText, logData } from './utils/common'
import {
    getTemplateNameTokenInfo, cTokenInfoOptions_TOKEN_INFO, cTokenInfoOptions_COLLECTOR, cTokenInfoOptions_IMAGE_ONLY,
    renderTokenInfo, renderGraphIcons, getDefaultThumbNailImage
} from './common'


const cTestContentContainer = 'test-content'

const cTokenInfoContainer = 'token-info'

const isTestPage = async () => {

    const myElement = await findElementByID(cTestContentContainer)

    return (myElement ? true : false)
}

const hideTestContent = async () => {

    await hideElement(cTestContentContainer)
}

const renderTestLookUpToken = async (tokenInfo, sourceContainer = null, resetContainer = true) => {

    logData('Token Info', tokenInfo)

    if (!tokenInfo) { return }

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cTokenInfoContainer, resetContainer)

    if (mySourceContainer === null) return false

    const cTemplateTokenInfo = getTemplateNameTokenInfo(false)

    // Find the token template 
    const myTokenTemplate = await findElementByID(cTemplateTokenInfo)

    if (!myTokenTemplate) {

        logError('Cannot find template ', cTemplateTokenInfo)

    }

    let myNewTokenElement = await renderTokenInfo(tokenInfo, myTokenTemplate, cTokenInfoOptions_TOKEN_INFO)

    mySourceContainer.appendChild(myNewTokenElement)

}

export {
    isTestPage,
    hideTestContent,
    renderTestLookUpToken
}