
// Internal
import { WovEnv } from './env'
import {
    FilterTypes as WovMarketPlaceAPIFilterTypes,
    OrderByTypes as WovMarketPlaceAPIOrderByTypes
} from './marketplace_graphql'
//import { evmToPrintable } from '../crypto/utils/bignumber'
import { logError, logText, getTwitterHandle, getFormattedLocalNumber } from '../app/utils/common'
import { WovAccountBase, cAccountTypes } from './account_base'
import { resolveConfig } from 'prettier'

class WovAccountArtist extends WovAccountBase {

    _artistCreationsData = null
    _artistCollectionsData = null
    _artistCollectorsData = null
    _artistOnSalesData = null
    _artistSalesHistory = null
    _artistMarketPlaceTokens = null
    _artistMarketPlaceCollectionsData = null
    _artistSecondarySalesData = null


    constructor(chainConnector) {

        super(chainConnector, cAccountTypes.Artist)

    }

    async readAccountArtistCreatedTotalCountMarketPlace(walletAddress = null) {

        const count = await this._marketplaceAPIReq.getUserCreatedTotalCount(this._walletAddress)

        this._accountInfo.createdCount = count
    }

    async readAccountArtistCollectionsTotalCountMarketPlace() {

        const count = await this._marketplaceAPIReq.getUserCollectionsTotalCount(this._walletAddress)

        this._accountInfo.collectionsCount = count
    }

    async readAccountArtistSalesPrimaryTotalCountMarketPlace() {

        const count = await this._marketplaceAPIReq.getUserSalesTotalCount(this._walletAddress, WovMarketPlaceAPIFilterTypes.SalesPrimary)

        this._accountInfo.salesPrimaryCount = count
    }

    async readAccountArtistSalesSecondaryTotalCountMarketPlace() {

        const count = await this._marketplaceAPIReq.getUserSalesTotalCount(this._walletAddress, WovMarketPlaceAPIFilterTypes.SalesSecondary)

        this._accountInfo.salesSecondaryCount = count
    }

    async readAccountArtistOffersTotalCountMarketPlace() {

        const count = await this._marketplaceAPIReq.getUserOffersTotalCount(this._walletAddress)

        this._accountInfo.offersCount = count
    }

    // Initialize Account further
    async initializeAccountFurther() {

        // Should be required methods before initial account data generation / display

        await this.readAccountArtistCreatedTotalCountMarketPlace()
        await this.readAccountArtistCollectionsTotalCountMarketPlace()
        await this.readAccountArtistOffersTotalCountMarketPlace()
        await this.loadArtistCollections()
        //await this.loadArtistOnSale()

    }

    // Load Artist Creations
    async loadArtistCreations() {

        this._artistCreationsData = await super.loadArtistCreations()
    }

    // Load Artist Market Place Collections
    async loadArtistCollections() {

        this._artistMarketPlaceCollectionsData = await super.loadArtistCollections()

        this._artistMarketPlaceCollectionsData.push({
            collectionId: '99999999-9999-9999-9999-999999999999',
            collectionID: '99999999-9999-9999-9999-999999999999',
            name: 'NFT without a Collection'
        })

    }

    // Artist - Load On Sale
    async loadArtistOnSale() {

        this._artistOnSaleData = null

        const onSaleMarketPlaceData = await this._marketplaceAPIReq.getUserOnSale(this._walletAddress, this.cRequestAPILimitUnlimited)

        if (onSaleMarketPlaceData) {

            this._artistOnSaleData =
                onSaleMarketPlaceDatas.reduce((acc, item) => {

                    // Reduce item and remove all what we don't need
                    let { auction, token, edition, offer } = item
                    let { assets, creator, collection, ...myItem } = token

                    // Edition
                    myItem.editionOnSale = edition.id

                    // Collection
                    //myItem.collectionID = collection.id
                    myItem.collectionID = (collection && collection.id ? collection.id : myItem.collectionId)

                    // Detetermine primary or secondary
                    myItem.isPrimary = (edition.owner.address.toLowerCase() === this._walletAddressLow)

                    // Price info
                    myItem.priceAmount = edition.price
                    myItem.priceCurrency = edition.payment

                    // Store  
                    acc.push(myItem)

                    return acc
                }, [])

        }

        return this._artistOnSaleData === null ? false : true
    }

    // Loads the artist current tokens on the market place
    async loadArtistTokensMarketPlace() {

        this._artistMarketPlaceTokens = null

        let myTokens = await this._chainConnector.getMarketplaceTokensListed(WovEnv.smartcontract_wov_marketplace_address, this._walletAddress, true, true)

        // Remove the collected tokens
        this._artistMarketPlaceTokens =
            this.sortByTokenEdition(myTokens.filter(el => (this._artistCreationsData.findIndex(creation => creation.tokenWOVID === el.tokenWOVID)) !== -1))

    }

    // Loads the artist sales history
    async loadArtistSalesHistory() {

        this._artistSalesHistory = null

        // Get sales list for the given address ( this can be prim + sec )
        let myData = await this._chainConnector.getMarketPlacePurchasesList(WovEnv.smartcontract_wov_marketplace_address, this._walletAddress, true)

        // Remove anything that is not from ourselves
        myData = myData.filter(el => (this._artistCreationsData.findIndex(creation => creation.tokenWOVID === el.tokenWOVID)) !== -1)

        // Go over the sales data
        myData.forEach((data, index) => {

            const myTokenInfo = (this._artistMarketPlaceTokens ? this._artistMarketPlaceTokens.find(el => el.tokenEditionID === data.tokenEditionID) : null)

            if (myTokenInfo) {

                data = this._updateDataPriceInfo(myTokenInfo.paymentType, data, true)

            }

            data.isSalesPrimary = true

            data.collector = this._artistCollectorsData.find(el => el.address.toLowerCase() === data.newOwner.toLowerCase())

            if (!data.collector) {

                logError(`Cannot find collector info for address ${data.newOwner.toLowerCase()} ?`)

                data.collector = {}
            }

            if (data.collector && !data.collector.name) {

                data.collector.name = '?'
            }

            data.creation = this._artistCreationsData.find(el => el.tokenWOVID === data.tokenWOVID)

            data.smartContractAddress = data.creation.smartContractAddress
            data.tokenUrl = data.creation.tokenUrl

        })

        this._artistSalesHistory = myData
    }



    // Generate an Artist Item Statistics & Data
    async generateArtistItemStats(item) {

        let myItemEditionsCollected = null

        // Get editions for the item
        const myItemEditions = await this._marketplaceAPIReq.getTokenEditions(item.smartContractAddress, item.tokenID)

        if (myItemEditions.items !== null) {

            myItemEditionsCollected = myItemEditions.items.filter(el => {

                return (el.owner.address.toLowerCase() !== this._walletAddressLow)
            })
        }

        const myItemCollectorsCount = myItemEditionsCollected ? myItemEditionsCollected.length : 0

        item.collectedCount = myItemCollectorsCount
        item.collectors = new Array()



        if (item.collectionId === null || item.collectionId === '') {

            // Unassigned
            item.collectionId = '99999999-9999-9999-9999-999999999999'
            item.collectionID = item.collectionId
        }

        // Determine collection info
        let myCollectionInfo = this._artistCollectionsData.find(el => el.collectionId === item.collectionId)

        if (myCollectionInfo) {

            myCollectionInfo.creationsCount++
            myCollectionInfo.collectedCount += item.collectedCount
            myCollectionInfo.editionsCount += item.editionsCount
            myCollectionInfo.availableCount = myCollectionInfo.editionsCount - myCollectionInfo.collectedCount
            //myCollectionInfo.availableCount += item.onSale            
            //myCollectionInfo.notOnSaleCount = myCollectionInfo.editionsCount - myCollectionInfo.collectedCount - myCollectionInfo.availableCount

        } else {

            const myMPCollectionInfo = this._artistMarketPlaceCollectionsData.find(el => el.collectionId === item.collectionId)

            myCollectionInfo = myMPCollectionInfo

            myCollectionInfo.creationsCount = 1
            myCollectionInfo.collectedCount = item.collectedCount
            myCollectionInfo.editionsCount = item.editionsCount
            myCollectionInfo.availableCount = myCollectionInfo.editionsCount - myCollectionInfo.collectedCount

            if (myCollectionInfo.thumbnailImageUrl) {

                myCollectionInfo.thumbNailUrl = myCollectionInfo.thumbnailImageUrl
                myCollectionInfo.thumbNailIsVideo = false

            } else {

                myCollectionInfo.thumbNailUrl = item.media.url
                myCollectionInfo.thumbNailIsVideo = item.isVideo

            }

            if (!item.isVideo) {

                myCollectionInfo.backgroundUrl = item.media.url

            } else {

                myCollectionInfo.backgroundUrl = myCollectionInfo.image

            }

            myCollectionInfo.collectionUrl = WovEnv.url_marketplace_collection + myCollectionInfo.id

            myCollectionInfo.collectors = new Array()

            this._artistCollectionsData.push(myCollectionInfo)

        }

        // Determine collectors info
        myItemEditionsCollected.forEach((colItem) => {

            const collectorID = colItem.owner.address.toLowerCase()

            // Collectors Stats

            let myCollector = this._artistCollectorsData.find(el => el.ID === collectorID)

            if (myCollector) {

                myCollector.itemsCollected++
                myCollector.editionsCollected.push(colItem.id)

            } else {

                // Default object
                myCollector = colItem.owner

                myCollector.ID = collectorID
                myCollector.address = collectorID
                myCollector.itemsCollected = 1
                myCollector.editionsCollected = new Array()
                myCollector.editionsCollected.push(colItem.id)

                // Check if we are a top collector
                myCollector.isTop = this._checkIsTopCollector(myCollector.address)


                this._artistCollectorsData.push(myCollector)
            }

            // Current Token Collectors 
            let myTokenCollecter = item.collectors.find(el => el.ID === collectorID)

            if (!myTokenCollecter) {

                item.collectors.push({ ID: collectorID, itemsCollected: 1 })

            } else {

                myTokenCollecter.itemsCollected++
            }

            // Collection Unique Collectors
            let myCollectionCollecter = myCollectionInfo.collectors.find(el => el.ID === collectorID)

            if (!myCollectionCollecter) {

                myCollectionInfo.collectors.push({
                    ID: collectorID,
                    address: collectorID,
                    name: (myCollector.name && myCollector.name.length !== 0 ? myCollector.name : this.cUserNameUnknown),
                    itemsCollected: 1
                })
                myCollectionInfo.collectorsCount = myCollectionInfo.collectors.length

            } else {

                myCollectionCollecter.itemsCollected++
            }

        })

        return item

    }



    // Update collectors info
    async updateArtistCollectorsInfo() {

        // Update the profiles
        await this._updateDataUserProfilesInfo(this._artistCollectorsData)

        // Remove any non-existing
        this._artistCollectorsData = this._artistCollectorsData.filter(el => !el.notExisting)

        const myPrimarySalesInfo = this.getArtistSalesPrimary()

        // Go over the collectors
        this._artistCollectorsData.forEach((collector) => {

            // Well we should have info as otherwise we don't have collectors ?
            if (myPrimarySalesInfo) {

                // Find the purchase info for that collector
                this._updateDataCollectorPurchaseInfo(myPrimarySalesInfo, collector)

            }

        })


        // Sort our data to the default
        this._artistCollectorsData = this.sortByItemsCollected(this._artistCollectorsData)

    }

    // Update collectors info
    async updateArtistCollectionsInfo() {

        // Get primary sales
        const myPrimarySalesInfo = this.getArtistSalesPrimary()

        // Go over the collections
        this._artistCollectionsData.forEach((collection) => {


            // Find the purchase info for that collection
            const myPurchasesInfo = myPrimarySalesInfo.filter(el => el.tokenWOVID.toString().substring(0, 6) === collection.collectionId.substring(0, 6))

            // Generate sales data
            this._sumAddSalesInfo(myPurchasesInfo, collection)

            if (myPurchasesInfo && myPurchasesInfo.length !== 0) {

                // Determine gifted 
                collection.giftedCount = collection.collectedCount - myPurchasesInfo.length

                // Go over the collectors of this collection
                collection.collectors.forEach((collector) => {

                    // Update the collector purchase info for the current collection
                    this._updateDataCollectorPurchaseInfo(myPurchasesInfo, collector)
                })


            } else {

                // Default gifted
                collection.giftedCount = 0

                //logError(`No primary purchase sales history info found for the collection ${collection.collectionId} ?`)
            }

            //TODO Unlisted - current logic does not seem to work
            //collection.unlistedCount = this._artistMarketPlaceTokens.filter(el => el.tokenWOVID.toString().substring(0, 6) === collection.collectionId.substring(0, 6) && !el.isListed).length

            // Sort our data to the default
            collection.collectors = this.sortByItemsCollected(collection.collectors)

        })

    }

    // Generate the artist stats and additional info
    async generateArtistStats() {

        // Reset
        this._artistCollectionsData = new Array()
        this._artistCollectorsData = new Array()

        // Build our statistics tasks
        let tasks = new Array()

        this._artistCreationsData.forEach((item) =>
            tasks.push(this.generateArtistItemStats(item))
        )

        tasks.push(this.loadArtistTokensMarketPlace())

        // Execute
        await Promise.allSettled(tasks).then(async (result) => {

            await this.loadArtistSalesHistory()

        })


        // Update account info 
        this._accountInfo.isTopArtist = this._checkIsTopArtist(this._walletAddressLow)

        this._accountInfo.editionsCount = this._artistCollectionsData.reduce((prevValue, currentValue) => prevValue + currentValue.editionsCount, 0)
        this._accountInfo.collectorsCount = this._artistCollectorsData.length

        this._accountInfo.collectorsMarketplaceTopCount = (this._artistCollectorsData ? this._artistCollectorsData.filter(el => el.isTop).length : 0)
        this._accountInfo.collectorsFirstCount = this.getArtistCollectorsNew().length

        this._accountInfo.soldTotalCount = this._artistCollectorsData.reduce((prevValue, currentValue) => prevValue + currentValue.itemsCollected, 0)
        this._accountInfo.soldPrimaryCount = this._artistSalesHistory ? this._artistSalesHistory.filter(el => el.isSalesPrimary).length : 0
        this._accountInfo.soldPrimaryPercentage = Math.round(this._accountInfo.soldPrimaryCount / this._accountInfo.editionsCount * 100)
        // ?
        this._accountInfo.soldSecondaryCount = this._artistSalesHistory ? this._artistSalesHistory.filter(el => !el.isSalesPrimary).length : 0


        // The following does not make sense yet - we need the actual 'transfers' from the smart contract
        this._accountInfo.transferredCount = 0
        //this._accountInfo.transferredCount = this._accountInfo.editionsCount - this._accountInfo.soldPrimaryCount - this._accountInfo.unlistedCount
        this._accountInfo.transferredPercentage = Math.round(this._accountInfo.transferredCount / this._accountInfo.editionsCount * 100)


        this._accountInfo.unlistedCount = 0
        //this._accountInfo.unlistedCount = this._accountInfo.editionsCount - this._artistMarketPlaceTokens.length
        this._accountInfo.unlistedPercentage = Math.round(this._accountInfo.unlistedCount / this._accountInfo.editionsCount * 100)



        //this._accountInfo.onSalePrimaryCount = this._artistOnSaleData.filter(el => el.isPrimary ).reduce((prevValue, currentValue) => prevValue + Number.parseInt(currentValue.onSale), 0 )
        //this._accountInfo.onSaleSecondaryCount = this._artistOnSaleData.filter(el => !el.isPrimary ).reduce((prevValue, currentValue) => prevValue + Number.parseInt(currentValue.onSale), 0 )
        //

        this._accountInfo.availableCount = this._accountInfo.editionsCount - this._accountInfo.soldPrimaryCount - this._accountInfo.transferredCount
        this._accountInfo.availablePercentage = Math.round(this._accountInfo.availableCount / this._accountInfo.editionsCount * 100)

        this._accountInfo.burnedCount = this._artistCreationsData.reduce((prevValue, currentValue) => prevValue + Number.parseInt(currentValue.graveyardCount), 0)

        // Total Sales details
        this._sumAddSalesInfo(this._artistSalesHistory, this._accountInfo)

        // Update the collectors info
        await this.updateArtistCollectorsInfo()

        // Update the collections info
        await this.updateArtistCollectionsInfo()

    }


    // After Initial Display Secondary Sales Data Generation
    async afterInitialDisplaySecondarySalesDataGeneration() {

        await this.generateArtistSecondarySalesInfo(this.getArtistCreations()).then((results) => {

        })

    }

    async generateArtistSecondarySalesInfo(creations) {

        if (creations === null || creations.length === 0) return

        // Let's filter out those creations that are currently on sale or where already collected - these potentially had sec sales
        let myCreations = creations.filter(el => ((el.onSale && el.onSale !== 0) || (el.collectors && el.collectors.length !== 0)))

        if (myCreations === null || myCreations.length === 0) return

        this._artistSecondarySalesData = await this.generateSecondarySalesInfo(myCreations, this.getArtistTokens())

    }

    async generateSecondarySalesInfo(data, tokensData) {

    }



    // GETTERS

    _getArtistCollectorsNew() {

        let myCollectors = this._artistCollectorsData.filter(el => el.itemsCollected === 1).sort(this._sorterByPurchaseDate)

        //myCollectors = myCollectors.sort(this._sorterByPurchaseDate)

        if (myCollectors) {

            myCollectors.forEach((collector) => {

                // Check for editions
                if (collector.editionsCollected && collector.editionsCollected.length !== 0) {

                    collector.tokenEditionID = collector.editionsCollected[0]
                }

                // Find the purchase info for that collector - as it is the first one there should only be one
                const myPurchaseInfo = this._artistSalesHistory.find(el => el.newOwner.toLowerCase() === collector.address)

                if (myPurchaseInfo) {

                    collector.creation = myPurchaseInfo.creation

                }

            })

        }

        return myCollectors
    }

    isArtist() {

        return this._accountInfo && this._accountInfo.createdCount !== 0
    }

    getArtistCreations() {

        return this._artistCreationsData
    }


    getArtistCollections() {

        return this._artistCollectionsData
    }

    getArtistCollectors(sorterFunction = this._sorterByItemsCollected2Price) {
        return this._artistCollectorsData ? this._artistCollectorsData.sort(sorterFunction) : null
    }

    getArtistCollectorsNew() {
        return this._artistCollectorsData ? this._getArtistCollectorsNew() : null
    }

    getArtistOnSale() {
        return this._artistOnSaleData
    }

    getArtistTokens() {
        return this._artistMarketPlaceTokens
    }

    getArtistSales() {
        return this._artistSalesHistory
    }

    hasSales() {

        return (this._artistSalesHistory && this._artistSalesHistory.length !== 0 ? true : false)
    }

    getArtistSalesPrimary() {
        return (this._artistSalesHistory ? this._artistSalesHistory.filter(el => el.isSalesPrimary) : null)
    }

    getArtistSalesSecondary() {
        return (this._artistSalesHistory ? this._artistSalesHistory.filter(el => !el.isSalesPrimary) : null)
    }

    getArtistSalesSecondaryData() {

        return (this._artistSecondarySalesData ? this._artistSecondarySalesData : null)
    }

    getArtistSalesPrimaryLatest(latestCount = 1) {

        return this.getArtistSalesPrimary().slice(0, latestCount)
    }

    getArtistCollectorsCount() {
        return (this._artistCollectorsData ? this._artistCollectorsData.length : 0)
    }



}

export {
    WovAccountArtist
}