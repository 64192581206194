
import { findElementByID, hideElement, showElement, swapElementVisibility } from './utils/dom'


const cWalletInfoID = "wallet-connect-info"
const cWalletErrorID = "wallet-connect-error"
const cWalletSync1ErrorID = "wallet-sync1-error"

const cWalletButtonConnect = "button-connect"
const cWalletButtonDisconnect = "button-disconnect"

const showWalletConnectNeededInfo = async () => {

    await showElement(cWalletInfoID)
    await showElement(cWalletButtonConnect)
    await hideElement(cWalletButtonDisconnect)
}


const hideWalletConnectNeededInfo = async () => {

    await hideElement(cWalletInfoID)
    await hideElement(cWalletButtonConnect)
    await showElement(cWalletButtonDisconnect)
}

const showWalletConnectErrorInfo = async () => {

    await showElement(cWalletErrorID)
    await hideElement(cWalletInfoID)
    await hideElement(cWalletButtonConnect)
    await hideElement(cWalletButtonDisconnect)

}

const showWalletSync1ErrorInfo = async () => {

    await showElement(cWalletSync1ErrorID)
    await hideElement(cWalletInfoID)
    await hideElement(cWalletButtonConnect)
    await hideElement(cWalletButtonDisconnect)

}


export {
    showWalletConnectNeededInfo,
    hideWalletConnectNeededInfo,
    showWalletConnectErrorInfo,
    showWalletSync1ErrorInfo
}