// External
import { GoogleCharts } from 'google-charts'

// Local
import {
    findElementByID, clearElement, hideElement, showElement, setElementText, setElementTextDirect, setElementImageSource,
    getElementFromTemplate, setElementDynamic, setElementData, findSourceContainer, setElementStyle, setElementClass
} from './utils/dom'

import {
    getTemplateNameTokenInfo, cTokenInfoOptions_COLLECTOR, cTokenInfoOptions_IMAGE_ONLY,
    renderTokenInfo, renderGraphIcons, getDefaultThumbNailImage
} from './common'

import { logError, deepCopyFunction, getTokenEditionfromTokenID, getLocalFormattedDate, getFormattedLocalNumber, modalizeID } from './utils/common'
import { WovEnv } from '../wov/env'


// ARTIST Page Rendering Methods & Utilities

const cTopAll = 9999

const cArtistContentContainer = 'art-main'

const cArtistNoContentContainer = 'art-no'
const cArtistNoSalesContainer = 'art-no-s'

const cArtistLoader = 'art-loading'

const cArtistButtonCollectorAll = 'col-show-all'
const cArtistButtonCollectorTop = 'col-show-top'

const cArtistTopCollectorIndicator = 'col-top-type'

const cArtistCollectionsContainer = 'art-collections'
const cArtistCollectionsEditionsGraphContainer = 'art-graph-coll-edi'
const cArtistCollectionsCollectorsGraphContainer = 'art-graph-coll-coll'

const cArtistSales1GraphContainer = 'art-graph-sales-1'
const cArtistSales2GraphContainer = 'art-graph-sales-2'
const cArtistSales3GraphContainer = 'art-graph-sales-3'
const cArtistSales4GraphContainer = 'art-graph-sales-4'

const cArtistTopMarketPlaceContainer = 'art-topart'

const cArtistTopCollectorsContainer = 'toc-data'
const cArtistTopCollectorsNoContainer = 'toc-0'
const cArtistTopCollectorsGraphContainer = 'toc-graph'

const cArtistLatestSalesContainer = 'art-latest-1'
const cArtistLatestSalesNoneContainer = 'art-latest-0'

const cArtistSalesHistoryContainer = 'shp-cont'
const cArtistSalesHistoryNoContainer = 'shp-0'
const cArtistSalesHistoryDataContainer = 'shp-data-cont'

const cArtistSalesHistoryContainerContent = 'shp-container-body'

const cArtistButtonSalesHistoryAll = 'shp-show-all'
const cArtistButtonSalesHistoryLatest = 'shp-show-latest'
const cArtistSalesHistoryTopCount = 'shp-top-total-count'
const cArtistSalesHistoryIndicator = 'shp-top-type'

const cTemplateCollectionInfo = 'template_collection_info'
const cTemplateCollectorInfo = 'template_collector_info'
const cTemplateCollectorShortInfo = 'template_collector_short_info'
const cTemplateSalesHistoryTable = 'template_sales_history_table'


const isArtistPage = async () => {

    const myElement = await findElementByID(cArtistContentContainer)

    return (myElement ? true : false)
}

const showArtistContent = async () => {

    await showElement(cArtistContentContainer)

    return true
}

const showArtistNoContent = async () => {

    showElement(cArtistNoContentContainer)

    return true
}

const showArtistNoSales = async () => {

    showElement(cArtistNoSalesContainer)

    return true
}

const resetArtistContent = async () => {

    // General
    await hideElement(cArtistContentContainer)
    await hideElement(cArtistNoContentContainer)
    await hideElement(cArtistNoSalesContainer)
    await hideArtistLoader()

    //TODO Check if we need to do this ?

    // Collections
    await setElementTextDirect('col-col-total-count', '')
    await clearElement(cArtistCollectionsContainer)

    // Top Collector
    await clearElement(cArtistTopCollectorsContainer)

    await setElementTextDirect('art-top-total-count', '')
    await hideElement('col-copy-twitter')

    return true
}

const showArtistLoader = async () => {

    await showElement(cArtistLoader)

    return true
}

const hideArtistLoader = async () => {

    await hideElement(cArtistLoader)

    return true
}

//************ DATA PANELS ************/

// Render Collections
const renderCollections = async (collections, collectorsData = null, top = cTopAll, sourceContainer = null, resetContainer = true) => {

    const cTopCollectorsCount = 3

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cArtistCollectionsContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Find the template 
    const myTemplate = await findElementByID(cTemplateCollectionInfo)

    if (!myTemplate) {

        logError('Cannot find template ', cTemplateCollectionInfo)

        return
    }

    if (collections === null || collections.length === 0) {

        logError('No collections data received')

        return
    }


    // Adjust data for requested top
    const myCollections = collections.slice(0, top)

    let myElements = new Array(myCollections.length)

    // Note SDS: we use tasks to maintain the order of collections as otherwise due to async the order changes
    let tasks = new Array()

    // Generate content
    myCollections.forEach((collection, collectionIndex) => {

        tasks.push(new Promise(async (resolve, reject) => {

            try {

                let myNewElement = myTemplate.content.firstElementChild.cloneNode(true);

                let myThumbNailUrl = collection.thumbNailUrl
                let myThumbNailIsVideo = collection.thumbNailIsVideo

                if (myThumbNailUrl === null && !myThumbNailIsVideo) {

                    myThumbNailUrl = getDefaultThumbNailImage()
                    myThumbNailIsVideo = false

                }

                let myBackgroundUrl = collection.backgroundUrl ? collection.backgroundUrl : myThumbNailUrl

                let myThumbImage = await getElementFromTemplate(myNewElement, 'img', false)

                let myThumb = await getElementFromTemplate(myNewElement, 'col-thumb-img')

                let myVideo = await getElementFromTemplate(myNewElement, 'col-thumb-vid')

                if (!myThumbNailIsVideo) {

                    await setElementImageSource('', myThumbNailUrl, myThumbImage)

                    await hideElement('', myVideo)

                } else {

                    await setElementDynamic('', 'src', myThumbNailUrl, myVideo)

                    await hideElement('', myThumb)

                }

                let myThumbUrl = await getElementFromTemplate(myNewElement, 'col-thumb-link')
                await setElementDynamic('', 'href', collection.collectionUrl, myThumbUrl)

                let myName = await getElementFromTemplate(myNewElement, 'col-name')
                await setElementText('', collection, 'name', myName)

                let myCollection = await getElementFromTemplate(myNewElement, 'col-collection')
                await setElementDynamic('', 'href', collection.collectionUrl, myCollection)

                let myCreationsCount = await getElementFromTemplate(myNewElement, 'col-creationscount')
                await setElementText('', collection, 'creationsCount', myCreationsCount)

                let myEditionsCount = await getElementFromTemplate(myNewElement, 'col-editionscount')
                await setElementText('', collection, 'editionsCount', myEditionsCount)

                let myCollectorCount = await getElementFromTemplate(myNewElement, 'col-collectorscount')
                await setElementText('', collection, 'collectorsCount', myCollectorCount)

                let mySoldCount = await getElementFromTemplate(myNewElement, 'col-soldcount')
                await setElementText('', collection, 'collectedCount', mySoldCount)

                let myAvailableCount = await getElementFromTemplate(myNewElement, 'col-availablecount')
                await setElementText('', collection, 'availableCount', myAvailableCount)

                let myNotOnSaleCount = await getElementFromTemplate(myNewElement, 'col-notonsalecount')
                await setElementText('', collection, 'notOnSaleCount', myNotOnSaleCount)

                // Render background
                let myPanelElement = await getElementFromTemplate(myNewElement, 'col-container')

                if (myPanelElement) {

                    if (myThumbNailIsVideo) {

                        myBackgroundUrl = getDefaultThumbNailImage()

                    }

                    let myStyleInfo = 'background-image: url(\'' + myBackgroundUrl + '\');'

                    await setElementStyle('', myStyleInfo, myPanelElement)

                }

                // Graphs

                let myPanelGraphEditions = await getElementFromTemplate(myNewElement, 'col-graph-coll-edi')

                if (myPanelGraphEditions) {

                    let myOnlyCollections = new Array()
                    myOnlyCollections.push(collection)

                    await GoogleCharts.load('current', { 'packages': ['corechart', 'controls'] }).then(async () => {

                        await renderCollectionSalesGraph(myOnlyCollections, myPanelGraphEditions, true, true, 'Sold vs Unsold', 220)

                    })

                }

                // Collectors
                let myPanelCollectorsData = await getElementFromTemplate(myNewElement, 'col-coll-data')
                let myPanelCollectorsNone = await getElementFromTemplate(myNewElement, 'col-coll-none')
                let myPanelSalesNone = await getElementFromTemplate(myNewElement, 'col-coll-nosale')

                if (myPanelCollectorsData && collection.collectedCount !== 0 && collectorsData) {

                    let myCollectors = new Array()

                    collection.collectors.slice(0, cTopCollectorsCount).forEach((collector) => {

                        let myCollectorData = deepCopyFunction(collectorsData.find(el => el.ID === collector.ID))

                        //myCollectorData.itemsCollected = collector.itemsCollected

                        myCollectors.push({ ...myCollectorData, ...collector })

                    })

                    await renderCollectorsCards(myCollectors, cTopCollectorsCount, myPanelCollectorsData, true)

                } else {

                    await hideElement('', myPanelCollectorsData)
                    await showElement('', myPanelCollectorsNone)
                    await hideElement('', myPanelSalesNone)

                }

                // Set our new element
                myElements[collectionIndex] = myNewElement

                resolve()

            } catch (error) {

                logError('', error)

                reject(error)

            }

        }))

    })

    // Total count
    await setElementTextDirect('col-col-total-count', '( ' + collections.length + ' )')

    // Let's execute our rendering
    const result = await Promise.all(tasks)

    // Add new content
    myElements.forEach((myNewElement) => {

        mySourceContainer.appendChild(myNewElement)

    })

}

// Collector Card Options
const _CollectorCardOptions_DEFAULT = {
    useShortInfo: false,
    showRanking: true,
    showItemsCollected: true,
    showMostRecentPurchaseDate: true,
    showPrices: true,
    showLatestPurchaseToken: false
}

const _CollectorCardOptions_LATEST = {
    useShortInfo: true,
    showRanking: false,
    showItemsCollected: false,
    showMostRecentPurchaseDate: true,
    showPrices: true,
    showLatestPurchaseToken: true
}

// Render Top Collectors Cards
const renderCollectorsCards = async (collectors, top = cTopAll, sourceContainer = null, resetContainer = true, isModal = false, options = _CollectorCardOptions_DEFAULT, wrapContent = false) => {

    if (collectors === null || collectors.length === 0) {

        logError('No collectors data received')

        return
    }

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cArtistTopCollectorsContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Find the template 
    const myTemplate = await findElementByID((options.useShortInfo ? cTemplateCollectorShortInfo : cTemplateCollectorInfo))

    if (!myTemplate) {

        logError('Cannot find template ', cTemplateCollectorInfo)

        return
    }

    const cTemplateTokenInfo = getTemplateNameTokenInfo()

    // Find the token template 
    const myTokenTemplate = await findElementByID(cTemplateTokenInfo)

    if (options.showLatestPurchaseToken && !myTokenTemplate) {

        logError('Cannot find template ', cTemplateTokenInfo)

        options.showLatestPurchaseToken = false

    }

    // Adjust data for requested top
    // Note SDS: We need to accommodate 'all' for now so no filter
    //const myTopCollectors = collectors.filter(el => el.isTop).slice(0,top)
    const myTopCollectors = collectors.slice(0, top)

    let myElements = new Array(myTopCollectors.length)

    let tasks = new Array()

    // Generate content
    myTopCollectors.forEach(async (collector, dataIndex) => {

        tasks.push(new Promise(async (resolve, reject) => {

            try {

                let myNewElement = myTemplate.content.firstElementChild.cloneNode(true)

                if (!options.useShortInfo) {

                    let myRankCounter = dataIndex + 1

                    let myRankElement = await getElementFromTemplate(myNewElement, 'col-rank')

                    if (options.showRanking) {

                        await setElementDynamic('', 'innerText', myRankCounter, myRankElement)

                    } else {

                        await hideElement('', myRankElement)
                    }

                    let myThumb = await getElementFromTemplate(myNewElement, 'img', false)
                    await setElementImageSource('', collector.profileImageUrl, myThumb)

                    let myThumbUrl = await getElementFromTemplate(myNewElement, 'col-thumb-link')
                    await setElementDynamic('', 'href', collector.profileUrl, myThumbUrl)

                }

                let myName = await getElementFromTemplate(myNewElement, 'col-name')
                await setElementText('', collector, 'name', myName)

                let myTopIndicator = await getElementFromTemplate(myNewElement, 'col-top-ind')

                if (collector.isTop) {

                    await showElement('', myTopIndicator)

                } else {

                    await hideElement('', myTopIndicator)

                }

                let myAddress = await getElementFromTemplate(myNewElement, 'col-address')
                await setElementData('', 'ID', collector.name + ' = ' + collector.address, myAddress)

                let myProfile = await getElementFromTemplate(myNewElement, 'col-profile')
                await setElementDynamic('', 'href', collector.profileUrl, myProfile)

                let myTwitter = await getElementFromTemplate(myNewElement, 'col-twitter')

                if (collector.twitterUrl) {

                    await setElementDynamic('', 'href', collector.twitterUrl, myTwitter)

                } else {

                    await setElementDynamic('', 'style', 'visibility: hidden;', myTwitter)

                }

                let myItemsCollectedElement = await getElementFromTemplate(myNewElement, 'col-colcnt')

                if (options.showItemsCollected) {

                    let myItemsCollectedValueElement = await getElementFromTemplate(myNewElement, 'col-colcnt-value')

                    await setElementText('', collector, 'itemsCollected', myItemsCollectedValueElement)
                    await showElement('', myItemsCollectedElement)

                } else {

                    await hideElement('', myItemsCollectedElement)
                }


                let myPurchasesVETElement = await getElementFromTemplate(myNewElement, 'col-purchasesVET')

                if (options.showPrices) {

                    let myPurchasesVETValueElement = await getElementFromTemplate(myNewElement, 'col-purchasesVET-value')

                    await setElementTextDirect('', getFormattedLocalNumber(collector.salesVETTotalValue), myPurchasesVETValueElement)
                    await showElement('', myPurchasesVETElement)

                } else {

                    await hideElement('', myPurchasesVETElement)
                }

                // When 
                let myDateElement = await getElementFromTemplate(myNewElement, 'col-date')

                if (options.showMostRecentPurchaseDate && collector.mostRecentPurchaseDate) {

                    let myDateValueElement = await getElementFromTemplate(myNewElement, 'col-date-value')

                    await setElementTextDirect('', getLocalFormattedDate(collector.mostRecentPurchaseDate, true), myDateValueElement)
                    await setElementDynamic('', 'title', getLocalFormattedDate(collector.mostRecentPurchaseDate, false), myDateValueElement)

                    await showElement('', myDateElement)

                } else {

                    await hideElement('', myDateElement)
                }

                if (options.showLatestPurchaseToken && collector.creation) {

                    let myNewTokenElement = await renderTokenInfo(collector, myTokenTemplate, cTokenInfoOptions_IMAGE_ONLY)

                    myNewElement.appendChild(myNewTokenElement)
                }

                // Set our new element
                myElements[dataIndex] = myNewElement

                resolve()

            } catch (error) {

                logError('', error)

                reject(error)

            }

        }))

    })

    // Let's execute our rendering
    const result = await Promise.all(tasks)

    // Wrap Content
    if (wrapContent) {

        let myContainer = document.createElement('div')
        myContainer.className = 'columns is-multiline'

        // Add new content
        myElements.forEach((myNewElement) => {

            let myColumn = document.createElement('div')
            myColumn.className = 'column'

            myColumn.appendChild(myNewElement)

            myContainer.appendChild(myColumn)


        })

        mySourceContainer.appendChild(myContainer)


    } else {

        // Add new content
        myElements.forEach((myNewElement) => {

            mySourceContainer.appendChild(myNewElement)

        })
    }

}

// Render Top Collectors
const renderTopCollectors = async (collectors, top = cTopAll, sortedByEditions = true, sourceContainer = null, resetContainer = true, isModal = false) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cArtistTopCollectorsContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Did we get data ?
    if (collectors) {

        await hideElement(cArtistTopCollectorsNoContainer)

        // Adjust data for requested top
        // Note SDS: We need to accommodate 'all' for now so no filter
        //const myTopCollectors = collectors.filter(el => el.isTop).slice(0,top)
        const myTopCollectors = collectors.slice(0, top)

        // All button
        if (myTopCollectors.length === collectors.length) {

            await hideElement(modalizeID(cArtistButtonCollectorAll, isModal))

        } else {


        }

        // Sorted by
        let mySortedText = ' - Ranked by ' + (sortedByEditions ? 'Collected' : 'Purchased VET')
        await setElementTextDirect(modalizeID('col-top-sort', isModal), mySortedText)

        if (!isModal) {

            let mySwitchButtonText = ' Rank by ' + (!sortedByEditions ? 'Collected' : 'Purchased VET')
            await setElementTextDirect('col-sort-switch', mySwitchButtonText)

        }

        // Total Count
        let myTopCountText = myTopCollectors.length.toString()

        if (collectors.length > myTopCollectors.length) {

            myTopCountText = myTopCountText + ' of ' + collectors.length.toString()

        }

        await setElementTextDirect(modalizeID('col-top-total-count', isModal), '( ' + myTopCountText + ' )')

        // Twitter Handles
        let myTwitterHandles = ""

        myTopCollectors.forEach((collector, index) => {

            // Build combined twitter handles
            if (collector.twitterHandle) {

                myTwitterHandles = myTwitterHandles + ' ' + collector.twitterHandle
            }
        })

        if (myTwitterHandles !== "") {

            await setElementTextDirect('col-top-comb-twitter', myTwitterHandles)

            await showElement('col-copy-twitter')

        } else {

            await hideElement('col-copy-twitter')
        }

        // Let's generate the actual cards
        await renderCollectorsCards(collectors, top, mySourceContainer, false, isModal)

        /*
        // If we are not modal
        if (!isModal) {

            await renderTopCollectorsGraph(collectors, top, sortedByEditions)
        }
        */

        await showElement(cArtistTopCollectorsContainer)

    } else {

        await hideElement(cArtistTopCollectorsContainer)
        await hideElement(cArtistTopCollectorsGraphContainer)
        await showElement(cArtistTopCollectorsNoContainer)
        await hideElement(cArtistButtonCollectorAll)

    }

}



// Render Latest Sales Token 
const renderLatestSalesToken = async (salesData, sourceContainer = null, resetContainer = true) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cArtistLatestSalesContainer, resetContainer)

    if (mySourceContainer === null) return false

    // If not sales data
    if (salesData === null || salesData.length === 0) {

        await showElement(cArtistLatestSalesNoneContainer)
        await hideElement(cArtistLatestSalesContainer)

    } else {

        await hideElement(cArtistLatestSalesNoneContainer)


        const cTemplateTokenInfo = getTemplateNameTokenInfo()

        // Find the token template 
        const myTokenTemplate = await findElementByID(cTemplateTokenInfo)

        if (!myTokenTemplate) {

            logError('Cannot find template ', cTemplateTokenInfo)

            return
        }

        const mySalesData = salesData

        // Generate content
        mySalesData.forEach(async (salesData, index) => {

            let myNewElement = await renderTokenInfo(salesData, myTokenTemplate, cTokenInfoOptions_COLLECTOR)

            mySourceContainer.appendChild(myNewElement)

        })

        await showElement(cArtistLatestSalesContainer)
    }

}

// Render Sales History
const renderSalesHistory = async (salesData, top = cTopAll, sourceContainer = null, resetContainer = true, isModal = false) => {

    // If we have data
    if (salesData) {

        // Find the data source container
        let mySourceContainer = await findSourceContainer(sourceContainer, cArtistSalesHistoryDataContainer, resetContainer)

        if (mySourceContainer === null) return false

        // Find the template 
        const myTemplate = await findElementByID(cTemplateSalesHistoryTable)

        if (!myTemplate) {

            logError('Cannot find template ', cTemplateSalesHistoryTable)

            return
        }

        await hideElement(cArtistSalesHistoryNoContainer)

        // Hide Buttons
        await showElement(cArtistButtonSalesHistoryAll)

        // Adjust data for requested top
        const mySalesData = salesData.slice(0, top)

        let myTopIndicatorText = ''
        let myTopCountText = mySalesData.length.toString()

        if (salesData.length > mySalesData.length) {

            myTopCountText = myTopCountText + ' of ' + salesData.length.toString()

        }

        await setElementTextDirect(modalizeID(cArtistSalesHistoryTopCount, isModal), '( ' + myTopCountText + ' )')

        if (!isModal) {

            if (salesData.length === mySalesData.length) {

                myTopIndicatorText = ''

                await showElement(cArtistButtonSalesHistoryLatest)

            } else {

                myTopIndicatorText = ''
                await showElement(cArtistButtonSalesHistoryAll)
            }

        }

        await setElementTextDirect(modalizeID(cArtistSalesHistoryIndicator, isModal), myTopIndicatorText)


        let myElements = new Array(mySalesData.length)

        // Note SDS: we use tasks to maintain the order of collections as otherwise due to async the order changes
        let tasks = new Array()

        let myTotalSalesPriceVET = 0
        let myTotalSalesPriceWOV = 0
        let myTotalSalesPriceAlt = 0

        // Generate content
        mySalesData.forEach((data, dataIndex) => {

            tasks.push(new Promise(async (resolve, reject) => {

                try {

                    let myNewElement = myTemplate.content.firstElementChild.cloneNode(true);

                    let myRankCounter = dataIndex + 1

                    let myRank = await getElementFromTemplate(myNewElement, 'rank')
                    await setElementDynamic('', 'innerText', myRankCounter, myRank)

                    let myThumbNailUrl = data.creation.media.url
                    let myThumbNailIsVideo = data.creation.isVideo

                    if (myThumbNailUrl === null && !myThumbNailIsVideo) {

                        myThumbNailUrl = getDefaultThumbNailImage()
                        myThumbNailIsVideo = false

                    }

                    let myThumbImage = await getElementFromTemplate(myNewElement, 'img', false)

                    let myThumb = await getElementFromTemplate(myNewElement, 'thumb-img')

                    let myVideo = await getElementFromTemplate(myNewElement, 'thumb-vid')

                    if (!myThumbNailIsVideo) {

                        await setElementImageSource('', myThumbNailUrl, myThumbImage)

                        await hideElement('', myVideo)

                    } else {

                        await setElementDynamic('', 'src', myThumbNailUrl, myVideo)

                        await hideElement('', myThumb)

                    }

                    //const myName = (data.creation && data.creation.name ? data.creation.name : data.tokenEditionID)

                    let myThumbUrl = await getElementFromTemplate(myNewElement, 'thumb-link')
                    await setElementData('', 'token-id', data.tokenEditionID, myThumbUrl)
                    await setElementDynamic('', 'title', 'Show Token Info', myThumbUrl)

                    myThumbUrl.addEventListener('click', function handleClick(event) {
                        window.wov_render_token_info(event.currentTarget);
                    }, false);

                    let myEdition = await getElementFromTemplate(myNewElement, 'edition')
                    await setElementTextDirect('', getTokenEditionfromTokenID(data.tokenEditionID), myEdition)

                    let myCollector = await getElementFromTemplate(myNewElement, 'collector')
                    await setElementText('', data.collector, 'name', myCollector)

                    let myPriceSales = await getElementFromTemplate(myNewElement, 'priceSales')
                    await setElementTextDirect('', data.priceStr, myPriceSales)

                    let myPriceSalesType = await getElementFromTemplate(myNewElement, 'priceSalesType')
                    await setElementTextDirect('', data.paymentType, myPriceSalesType)

                    let myPriceAlt = await getElementFromTemplate(myNewElement, 'priceAlt')
                    let myPriceVET = data.price
                    let myPriceVETStr = data.priceStr

                    if (data.isSalesVET) {

                        myTotalSalesPriceVET = myTotalSalesPriceVET + data.price

                    } else {

                        myTotalSalesPriceWOV = myTotalSalesPriceWOV + data.price

                        myPriceVET = data.priceAlt
                        myPriceVETStr = data.priceAltStr

                    }

                    await setElementTextDirect('', myPriceVETStr, myPriceAlt)

                    let myDateValueElement = await getElementFromTemplate(myNewElement, 'date')
                    await setElementTextDirect('', getLocalFormattedDate(data.blockDate, true), myDateValueElement)
                    await setElementDynamic('', 'title', getLocalFormattedDate(data.blockDate, false), myDateValueElement)

                    myTotalSalesPriceAlt = myTotalSalesPriceAlt + myPriceVET

                    // Set our new element
                    myElements[dataIndex] = myNewElement

                    resolve()

                } catch (error) {

                    logError('', error)

                    reject(error)

                }

            }))

        })

        // Let's execute our rendering
        const result = await Promise.all(tasks)

        // Add new content
        myElements.forEach((myNewElement) => {

            mySourceContainer.appendChild(myNewElement)

        })

        // Total sales prices

        let myTotalSalesPriceVETElement = await findElementByID(modalizeID('shp-total-vet-price', isModal))
        if (myTotalSalesPriceVETElement) {

            if (myTotalSalesPriceVET !== 0) {

                await setElementTextDirect('', getFormattedLocalNumber(myTotalSalesPriceVET), myTotalSalesPriceVETElement)
                await showElement('', myTotalSalesPriceVETElement)

            } else {

                await hideElement('', myTotalSalesPriceVETElement)
            }

        }

        let myTotalSalesPriceWOVElement = await findElementByID(modalizeID('shp-total-wov-price', isModal))
        if (myTotalSalesPriceWOVElement) {

            if (myTotalSalesPriceWOV !== 0) {

                await setElementTextDirect('', getFormattedLocalNumber(myTotalSalesPriceWOV), myTotalSalesPriceWOVElement)
                await showElement('', myTotalSalesPriceWOVElement)

            } else {

                await hideElement('', myTotalSalesPriceWOVElement)
            }

        }

        let myTotalSalesPriceAltElement = await findElementByID(modalizeID('shp-total-alt-price', isModal))
        if (myTotalSalesPriceAlt) {

            await setElementTextDirect('', getFormattedLocalNumber(myTotalSalesPriceAlt) + " VET", myTotalSalesPriceAltElement)

        }


        await showElement(cArtistSalesHistoryContainer)


    } else {

        await hideElement(cArtistSalesHistoryContainer)
        await showElement(cArtistSalesHistoryNoContainer)
        await hideElement(cArtistButtonSalesHistoryAll)
    }

}

// Render Total Sales Quick Info
const renderTotalSalesQuickInfo = async (data) => {

    // Top Artist Marketplace
    if (data.isTopArtist) {

        await showElement(cArtistTopMarketPlaceContainer)

    } else {

        await hideElement(cArtistTopMarketPlaceContainer)
    }

    await setElementTextDirect('tso-price-vet', getFormattedLocalNumber(data.salesVETValue))
    await setElementTextDirect('tso-price-wov', getFormattedLocalNumber(data.salesWOVValue))
    await setElementTextDirect('tso-price-wovvet', getFormattedLocalNumber(data.salesWOVVETValue))
    await setElementTextDirect('tso-price-totalvet', getFormattedLocalNumber(data.salesVETValue + data.salesWOVVETValue))
}


// Render Total Collectors Info
const renderTotalCollectorsInfo = async (data) => {

    await setElementTextDirect('tac-tot-col', getFormattedLocalNumber(data.collectorsCount))
    await setElementTextDirect('tac-top-col', getFormattedLocalNumber(data.collectorsMarketplaceTopCount))
    await setElementTextDirect('tac-first-col', getFormattedLocalNumber(data.collectorsFirstCount))
}


// Render Latest New Collectors
const renderLatestNewCollectors = async (collectors, top = cTopAll, sourceContainer = null, resetContainer = true, isModal = false) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, 'art-latest-newcol', resetContainer)

    if (mySourceContainer === null) return false

    // New collectors
    const myNewCollectors = collectors.slice(0, top)

    if (myNewCollectors) {

        await renderCollectorsCards(myNewCollectors, top, mySourceContainer, resetContainer, isModal, _CollectorCardOptions_LATEST, isModal)

    }

}


//************ GRAPHS ************/

const renderTopCollectorsGraph = async (collectors, top = cTopAll, sortedByEditions = true, addGraphIcons = true, sourceContainer = null, resetContainer = true) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cArtistTopCollectorsGraphContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Source Data
    //const sourceData = collectors.filter(el => el.isTop).slice(0, top)
    const sourceData = collectors.slice(0, top)

    // Build graph data
    let myGraphDataRows = new Array()

    if (sortedByEditions) {

        sourceData.forEach(data => {

            const { name, itemsCollected, ...rest } = data

            let myData = [name, itemsCollected]

            myGraphDataRows.push(myData)
        })

    } else {

        sourceData.forEach(data => {

            const { name, salesVETTotalValue, ...rest } = data

            let myData = [name, salesVETTotalValue]

            myGraphDataRows.push(myData)
        })

    }

    // Check we have data
    if (myGraphDataRows.length === 0) return

    // Create our data table
    var graphDataTable = new google.visualization.DataTable()
    graphDataTable.addColumn('string', 'Collector')

    if (sortedByEditions) {

        graphDataTable.addColumn('number', 'Editions')

    } else {

        graphDataTable.addColumn('number', 'Purchased VET')

    }

    graphDataTable.addRows(myGraphDataRows)

    const options = {
        title: (sortedByEditions ? 'Top Collectors by Collected Editions' : 'Top Collectors by Purchased (in VET)'),
        legend: { position: 'bottom', alignment: 'center' },
        pieHole: 0.4,
        colors: ['#ffaf4a', '#afd63b', '#00e89c', '#00e8ff', '#ab8bd7']
    };

    const pie_chart = new GoogleCharts.api.visualization.PieChart(mySourceContainer)

    if (addGraphIcons) {

        google.visualization.events.addListener(pie_chart, 'ready', async () => {

            await renderGraphIcons(mySourceContainer.id, pie_chart)

        });

    }

    // Render
    pie_chart.draw(graphDataTable, options);

    return true

}

const renderCollectionEditionsGraph = async (collections, top = cTopAll, addGraphIcons = true, sourceContainer = null, resetContainer = true) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cArtistCollectionsEditionsGraphContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Source Data
    let sourceData = collections.slice(0, top)

    // Build graph data
    let myGraphDataRows = new Array()

    sourceData.forEach(data => {

        const { name, editionsCount, ...rest } = data

        let myData = [name, editionsCount]

        myGraphDataRows.push(myData)
    })

    // Check we have data
    if (myGraphDataRows.length === 0) return

    // Create our data table
    var graphDataTable = new google.visualization.DataTable()
    graphDataTable.addColumn('string', 'Collection')
    graphDataTable.addColumn('number', 'Editions')
    graphDataTable.addRows(myGraphDataRows)

    const options = {
        title: 'Editions',
        legend: { position: 'bottom', alignment: 'center' },
        colors: ['#ffaf4a', '#afd63b', '#00e89c', '#00e8ff', '#ab8bd7']
    };

    // Render     
    const pie_chart = new GoogleCharts.api.visualization.PieChart(mySourceContainer)

    if (addGraphIcons) {

        google.visualization.events.addListener(pie_chart, 'ready', async () => {

            await renderGraphIcons(mySourceContainer.id, pie_chart)

        });

    }

    pie_chart.draw(graphDataTable, options);

    return true
}


const renderCollectionCollectorsGraph = async (collections, top = cTopAll, addGraphIcons = true, sourceContainer = null, resetContainer = true) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cArtistCollectionsCollectorsGraphContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Source Data
    let sourceData = collections.slice(0, top)

    // Build graph data
    let myGraphDataRows = new Array()

    sourceData.forEach(data => {

        const { name, collectorsCount, ...rest } = data

        let myData = [name, collectorsCount]

        myGraphDataRows.push(myData)
    })

    // Check we have data
    if (myGraphDataRows.length === 0) return

    // Create our data table
    var graphDataTable = new google.visualization.DataTable()
    graphDataTable.addColumn('string', 'Collection')
    graphDataTable.addColumn('number', 'Collectors')
    graphDataTable.addRows(myGraphDataRows)

    const options = {
        title: 'Collectors',
        legend: { position: 'bottom', alignment: 'center' },
        colors: ['#ffaf4a', '#afd63b', '#00e89c', '#00e8ff', '#ab8bd7']  // Softs
    };

    // Render     
    const pie_chart = new GoogleCharts.api.visualization.PieChart(mySourceContainer)

    if (addGraphIcons) {

        google.visualization.events.addListener(pie_chart, 'ready', async () => {

            await renderGraphIcons(mySourceContainer.id, pie_chart)

        });

    }

    pie_chart.draw(graphDataTable, options);

    return true
}

const renderEditionsSalesGlobalGraph = async (accountInfo, sourceContainer = null, addGraphIcons = true, resetContainer = true, title = 'Global Sold vs Unsold', width = null, height = null) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cArtistSales1GraphContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Source Data
    let editionsCount = accountInfo.editionsCount
    let soldCount = accountInfo.soldPrimaryCount
    let unsoldCount = accountInfo.availableCount
    let giftedCount = accountInfo.transferredCount

    // Build graph data
    let myGraphDataRows = new Array()

    myGraphDataRows.push(['Sold', soldCount])
    myGraphDataRows.push(['Unsold', unsoldCount])
    myGraphDataRows.push(['Gifted', giftedCount])

    // Check we have data
    if (myGraphDataRows.length === 0) return

    // Create our data table
    var graphDataTable = new google.visualization.DataTable()
    graphDataTable.addColumn('string', 'Type')
    graphDataTable.addColumn('number', 'Editions')
    graphDataTable.addRows(myGraphDataRows)

    const options = {
        title: title,
        width: width,
        height: height,
        legend: { position: 'bottom', alignment: 'center' },
        colors: ['#8db419', '#ffab90']  // Green, orange
    };

    // Render     
    const pie_chart = new GoogleCharts.api.visualization.PieChart(mySourceContainer)

    if (addGraphIcons) {

        google.visualization.events.addListener(pie_chart, 'ready', async () => {

            await renderGraphIcons(mySourceContainer.id, pie_chart)

        });

    }

    pie_chart.draw(graphDataTable, options);

    return true
}

const renderPrimarySalesPricesValueGraph = async (accountInfo, sourceContainer = null, addGraphIcons = true, resetContainer = true, title = 'Primary Sales Value VET vs WOV', width = null, height = null) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cArtistSales3GraphContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Source Data    
    let vetData = accountInfo.salesVETValue
    let wovData = accountInfo.salesWOVVETValue

    // Build graph data
    let myGraphDataRows = new Array()

    myGraphDataRows.push(['VET', vetData])
    myGraphDataRows.push(['WOV', wovData])

    // Check we have data
    if (myGraphDataRows.length === 0) return

    // Create our data table
    var graphDataTable = new google.visualization.DataTable()
    graphDataTable.addColumn('string', 'Payment Type')
    graphDataTable.addColumn('number', 'VET Value')
    graphDataTable.addRows(myGraphDataRows)

    const options = {
        title: title,
        width: width,
        height: height,
        legend: { position: 'bottom', alignment: 'center' },
        colors: ['#3c50c2', '#4f1fa6']  // Blues
    };

    // Render     
    const pie_chart = new GoogleCharts.api.visualization.PieChart(mySourceContainer)

    if (addGraphIcons) {

        google.visualization.events.addListener(pie_chart, 'ready', async () => {

            await renderGraphIcons(mySourceContainer.id, pie_chart)

        });

    }

    pie_chart.draw(graphDataTable, options);

    return true
}

const renderPrimarySalesPricesEditionsGraph = async (accountInfo, sourceContainer = null, addGraphIcons = true, resetContainer = true, title = 'Primary Sales Editions VET vs WOV', width = null, height = null) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cArtistSales4GraphContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Source Data    
    let vetCount = accountInfo.salesVETCount
    let wovCount = accountInfo.salesWOVCount

    // Build graph data
    let myGraphDataRows = new Array()

    myGraphDataRows.push(['VET', vetCount])
    myGraphDataRows.push(['WOV', wovCount])

    // Check we have data
    if (myGraphDataRows.length === 0) return

    // Create our data table
    var graphDataTable = new google.visualization.DataTable()
    graphDataTable.addColumn('string', 'Payment Type')
    graphDataTable.addColumn('number', 'Editions')
    graphDataTable.addRows(myGraphDataRows)

    const options = {
        title: title,
        width: width,
        height: height,
        legend: { position: 'bottom', alignment: 'center' },
        colors: ['#3c50c2', '#4f1fa6']  // Blues
    };

    // Render     
    const pie_chart = new GoogleCharts.api.visualization.PieChart(mySourceContainer)

    if (addGraphIcons) {

        google.visualization.events.addListener(pie_chart, 'ready', async () => {

            await renderGraphIcons(mySourceContainer.id, pie_chart)

        });

    }

    pie_chart.draw(graphDataTable, options);

    return true
}


const renderCollectionSalesGraph = async (collections, sourceContainer = null, addGraphIcons = true, resetContainer = true, title = 'Global Sold vs Unsold', width = null, height = null) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cArtistSales1GraphContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Source Data
    let editionsCount = collections.reduce((prevValue, currentValue) => prevValue + currentValue.editionsCount, 0)
    let soldCount = collections.reduce((prevValue, currentValue) => prevValue + currentValue.collectedCount, 0)
    let unsoldCount = collections.reduce((prevValue, currentValue) => prevValue + currentValue.availableCount, 0)
    let giftedCount = 0

    // Build graph data
    let myGraphDataRows = new Array()

    myGraphDataRows.push(['Sold', soldCount])
    myGraphDataRows.push(['Unsold', unsoldCount])
    myGraphDataRows.push(['Gifted', giftedCount])

    // Check we have data
    if (myGraphDataRows.length === 0) return

    // Create our data table
    var graphDataTable = new google.visualization.DataTable()
    graphDataTable.addColumn('string', 'Type')
    graphDataTable.addColumn('number', 'Editions')
    graphDataTable.addRows(myGraphDataRows)

    const options = {
        title: title,
        width: width,
        height: height,
        legend: { position: 'bottom', alignment: 'center' },
        colors: ['#8db419', '#ffab90']  // Green, orange
    };

    // Render     
    const pie_chart = new GoogleCharts.api.visualization.PieChart(mySourceContainer)

    if (addGraphIcons) {

        google.visualization.events.addListener(pie_chart, 'ready', async () => {

            await renderGraphIcons('', pie_chart, mySourceContainer)

        });

    }

    pie_chart.draw(graphDataTable, options);

    return true
}

const renderEditionsSalesCollectionsGraph = async (collections, sourceContainer = null, addGraphIcons = true, resetContainer = true) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cArtistSales2GraphContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Source Data
    let sourceData = collections

    // Build graph data
    let myGraphDataRows = new Array()

    sourceData.forEach(data => {

        const { name, collectedCount, availableCount, ...rest } = data

        let myData = [name, collectedCount, availableCount, '']

        myGraphDataRows.push(myData)
    })

    // Check we have data
    if (myGraphDataRows.length === 0) return

    // Create our data table
    var graphDataTable = new google.visualization.DataTable()
    graphDataTable.addColumn('string', 'Collection')
    graphDataTable.addColumn('number', 'Sold')
    graphDataTable.addColumn('number', 'Unsold')
    graphDataTable.addColumn({ role: 'annotation' })
    graphDataTable.addRows(myGraphDataRows)

    const options = {
        title: 'Collections Sold vs Unsold',
        legend: { position: 'bottom', alignment: 'center' },
        colors: ['#8db419', '#ffab90'],  // Green, orange
        bar: { groupWidth: "95%" },
        isStacked: true
    };

    // Render     
    const bar_chart = new GoogleCharts.api.visualization.BarChart(mySourceContainer)

    if (addGraphIcons) {

        google.visualization.events.addListener(bar_chart, 'ready', async () => {

            await renderGraphIcons(mySourceContainer.id, bar_chart)

        });

    }

    bar_chart.draw(graphDataTable, options);

    return true
}

const showTopCollectorsGraph = async () => {

    await showElement(cArtistTopCollectorsGraphContainer)

    return true
}

const hideTopCollectorsGraph = async () => {

    await hideElement(cArtistTopCollectorsGraphContainer)

    return true
}



export {

    isArtistPage,
    showArtistContent,
    showArtistNoContent,
    showArtistNoSales,
    resetArtistContent,
    showArtistLoader,
    hideArtistLoader,
    // Data
    renderCollections,
    renderTopCollectors,
    renderLatestSalesToken,
    renderSalesHistory,
    renderTotalSalesQuickInfo,
    renderTotalCollectorsInfo,
    renderLatestNewCollectors,
    // Graphs
    renderTopCollectorsGraph,
    renderCollectionEditionsGraph,
    renderCollectionCollectorsGraph,
    renderEditionsSalesGlobalGraph,
    renderEditionsSalesCollectionsGraph,
    showTopCollectorsGraph,
    hideTopCollectorsGraph,
    renderPrimarySalesPricesEditionsGraph,
    renderPrimarySalesPricesValueGraph
}