
import { logText, logData, logError, copyTextDataToClipboard } from './common'

// Find DOM Element
const findElementByID = (id) => {

    return new Promise((resolve, reject) => {

        let element = document.getElementById(id)

        if (element) {

            resolve(element)
        }
        else {

            logError(`Cannot find element with ID '${id}'`)

            resolve(null)
        }
    })
}

// Visibility

const cVisibility = {
    visible: "flex !important",
    hidden: "none !important"
}

const setElementStyle = async (id, styleText, element, keepExisting = true) => {

    try {

        let myElement = (element ? element : await findElementByID(id))

        if (keepExisting) {

            myElement.style.cssText = myElement.style.cssText + styleText

        } else {

            myElement.style.cssText = styleText
        }



    } catch (exception) {

    }
}

const setElementVisibility = async (id, visibility, element) => {

    try {

        let myElement = (element ? element : await findElementByID(id))

        let myNewDisplayStyle = 'display:' + visibility
        let myNewStyle = ''
        let myExistingStyle = myElement.style.cssText

        if (myExistingStyle) {

            if (myExistingStyle.includes('display')) {

                let myStyleParts = myExistingStyle.split(';')

                for (let i = 0; i < myStyleParts.length; i++) {

                    if (myStyleParts[i].includes('display')) {
                        myStyleParts[i] = myNewDisplayStyle
                    }
                }

                myNewStyle = myStyleParts.join(';')

            } else {

                myNewStyle = myExistingStyle + myNewDisplayStyle
            }


        } else {
            myNewStyle = myNewDisplayStyle
        }

        myElement.style.cssText = myNewStyle

    } catch (exception) {

    }
}

const showElement = async (id, element = null) => {

    await setElementVisibility(id, cVisibility.visible, element)
}

const hideElement = async (id, element = null) => {

    await setElementVisibility(id, cVisibility.hidden, element)
}

const swapElementVisibility = async (id, element = null) => {

    try {

        let myElement = (element ? element : await findElementByID(id))

        myElement.style.visibility = (myElement.style.visibility === cVisibility.visible ? cVisibility.hidden : cVisibility.visible)

    } catch (exception) {

    }
}

const setElementText = async (id, object, attributeName, element = null) => {

    let myElement = (element ? element : await findElementByID(id))

    if (myElement && object[attributeName]) {

        myElement.innerText = object[attributeName]
    }

}

const setElementTextDirect = async (id, value, element = null) => {

    let myElement = (element ? element : await findElementByID(id))

    if (myElement) {

        myElement.innerText = value
    }

}

const setElementImageSource = async (id, imageUrl, element = null) => {

    let myElement = (element ? element : await findElementByID(id))

    if (myElement && imageUrl) {

        myElement.src = imageUrl
    }

}

const setElementDynamic = async (id, propertyName, propertyValue, element = null) => {

    let myElement = (element ? element : await findElementByID(id))

    if (myElement && propertyName) {

        myElement[propertyName] = propertyValue
    }

}

const getElementFromTemplate = (template, id, isID = true) => {

    return new Promise((resolve, reject) => {

        let element = template.querySelector((isID ? '#' : '') + id)

        if (element) {

            resolve(element)
        }
        else {

            logError(`Cannot find template element with ID '${id}'`)

            resolve(null)
        }
    })


}

const clearElement = async (id, element = null) => {

    let myElement = (element ? element : await findElementByID(id))

    if (myElement) {

        myElement.innerHTML = ''
    }

}

const setElementData = async (id, dataName, dataValue, element = null) => {

    let myElement = (element ? element : await findElementByID(id))

    if (myElement) {

        myElement.setAttribute("data-" + dataName, dataValue)
    }

}

const getElementData = async (id, dataName, element = null) => {

    let myElement = (element ? element : await findElementByID(id))

    if (myElement) {

        return myElement.getAttribute("data-" + dataName)
    } else return null

}

const copyElementTextData = async (dataElementID, infoElementID = null) => {

    let myElement = await findElementByID(dataElementID)

    if (myElement && myElement.innerText) {

        await copyTextDataToClipboard(myElement.innerText)

        if (infoElementID) {

            await showElement(infoElementID)
        }
    }
}

const setElementClass = async (id, className, replace = false, element) => {

    let myCurrentClass = ''

    let myElement = (element ? element : await findElementByID(id))

    if (myElement) {

        myCurrentClass = myElement.className

        if (!replace) {

            if (myCurrentClass && !myCurrentClass.includes(className)) {

                myCurrentClass = myCurrentClass + ' ' + className

            }

        }
        else {

            myCurrentClass = className

        }

    }

    myElement.className = myCurrentClass

    return true

}

const removeElementClass = async (id, className, clear = false, element) => {

    let myCurrentClass = ''

    let myElement = (element ? element : await findElementByID(id))

    if (myElement) {

        myCurrentClass = myElement.className

        if (!clear) {

            if (myCurrentClass && myCurrentClass.includes(className)) {

                myCurrentClass = myCurrentClass.replace(className, '')
            }

        }
        else {

            myCurrentClass = ''

        }

    }

    myElement.className = myCurrentClass

    return true

}

const clearElementClass = async (id, className, clear = false, element) => {

    return await removeElementClass(id, null, true, element)

}

const setElementFASpinning = async (id, spinning = true, element) => {

    const cClassSpinning = 'fa-spin'

    if (spinning) {

        await setElementClass(id, cClassSpinning)

    } else {

        await removeElementClass(id, cClassSpinning)
    }

}

const setElementFAInverted = async (id, inverted = true, element) => {

    const cClassInverse = 'fa-inverse'

    if (inverted) {

        await setElementClass(id, cClassInverse)

    } else {

        await removeElementClass(id, cClassInverse)
    }

}

// Find the source container
const findSourceContainer = async (sourceContainer, defaultID, resetContainer = true) => {

    let mySourceContainer = sourceContainer

    if (!sourceContainer) {

        mySourceContainer = await findElementByID(defaultID)
    }

    if (!mySourceContainer) {

        logError('Cannot find source container ' + defaultID)

        return null
    }

    // Reset it
    if (resetContainer) {

        await clearElement('', mySourceContainer)

    }

    return mySourceContainer
}


export {
    findElementByID,
    findSourceContainer,
    showElement,
    hideElement,
    swapElementVisibility,
    setElementText,
    setElementTextDirect,
    setElementImageSource,
    getElementFromTemplate,
    setElementDynamic,
    clearElement,
    setElementStyle,
    setElementData,
    getElementData,
    copyElementTextData,
    setElementClass,
    removeElementClass,
    clearElementClass,
    setElementFASpinning,
    setElementFAInverted
}