
// Internal
import { WovAccountBase, cAccountTypes } from './account_base'

class WovAccountToken extends WovAccountBase {

    cQueryParamTokenID = 'id'
    _givenTokenID = null

    constructor(chainConnector) {

        super(chainConnector, cAccountTypes.Token)

    }

    // Initialize Account further
    async initializeAccountFurther() {

    }

    // Check for passed along token ID
    checkHasGivenTokenID() {

        const params = new URLSearchParams(window.location.search)

        if (params.has(this.cQueryParamTokenID)) {

            const myTokenID = params.get(this.cQueryParamTokenID)

            if (this.isValueValidTokenID(myTokenID)) {

                this._setGivenTokenID(myTokenID)

            } else {

                this._resetGivenTokenID()

            }
        } else {

            this._resetGivenTokenID()

        }

        return this.hasGivenTokenID()

    }

    // Has given token ID
    hasGivenTokenID() {

        return (this._givenTokenID !== null ? true : false)
    }

    // Reset given token ID
    _resetGivenTokenID() {

        this._givenTokenID = null
    }

    // Set given token ID
    _setGivenTokenID(tokenID) {

        this._givenTokenID = new Number(tokenID)
    }

    // Get given token ID
    getGivenTokenID() {

        return this._givenTokenID
    }



    setUrlTokenID(tokenID) {

        if ('URLSearchParams' in window) {

            var searchParams = new URLSearchParams(window.location.search)
            searchParams.set(this.cQueryParamTokenID, tokenID)

            var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            history.pushState(null, '', newRelativePathQuery);
        }
    }

    // Lookup Token Info
    async lookupTokenInfo(tokenID) {

        return await this._lookupTokenInfo(tokenID)
    }


}

export {
    WovAccountToken
}