import { WovEnv } from './env'

// GraphQL Lib
import { request, GraphQLClient } from 'graphql-request'

// GraphQL Queries
import { GetUserInfo } from './graphql/GetUserInfo.graphql'
import { GetTokenInfo } from './graphql/GetTokenInfo.graphql'
import { GetTokenEditions } from './graphql/GetTokenEditions.graphql'
import { GetUserCreations } from './graphql/GetUserCreations.graphql'
import { GetUserCollections } from './graphql/GetUserCollections.graphql'
import { GetUserCollected } from './graphql/GetUserCollected.graphql'
import { GetUserOnSale } from './graphql/GetUserOnSale.graphql'
//import { GetUserOffersCount } from './graphql/GetUserOffersCount.graphql'
import { GetUserActivity } from './graphql/GetUserActivity.graphql'
import { GetTokenActivity } from './graphql/GetTokenActivity.graphql'


//TODO Remove when migrated
import axios from 'axios'


const FilterTypes = Object.freeze({
    All: 'all',
    SalesPrimary: 'primary',
    SalesSecondary: 'secondary'
})

const OrderByTypes = Object.freeze({
    Newest: 'newest',
    Oldest: 'oldest'
})

class WovMarketPlaceGRAPHQLRequestor {

    _mainAPIUrl = null
    _mainGRAPHQLUrl = null
    _connectionError = null

    constructor() {

        this._connectionErrorAPI = new Error('Unable to connect to the WoV Marketplace API')

        this._connectionErrorGRAPHQL = new Error('Unable to connect to the WoV Marketplace GRAPHQL')

        // Create graphql client
        this._graphQL = new GraphQLClient(WovEnv.graphql_marketplace, { headers: {} })

    }

    _getUserQueryParams(limit = 1, filter = FilterTypes.All, orderBy = OrderByTypes.Newest, offset = 0) {

        return new URLSearchParams([
            ['limit', limit],
            ['filter', filter],
            ['offset', offset],
            ['sortBy', orderBy]
        ]);
    }

    async getUserInfo(address) {

        try {

            const response = await this._graphQL.request(GetUserInfo, { userAddress: address });

            const userInfo = response.User;

            return userInfo

        } catch (errors) {

            console.error(errors);

            throw this._connectionErrorGRAPHQL

        }
    }

    async getUserCreated(address, limit = 100, withEditions = false) {

        try {

            let data = [];
            let currentPage = 0;
            let readMore = false;

            do {

                // Get the paged data
                const response = await this._graphQL.request(GetUserCreations, { userAddress: address, withEditions: withEditions, page: ++currentPage, pageItemsCount: limit });

                // Check we actually have items
                if (response.Tokens.items && response.Tokens.items !== null) {

                    // Add our items
                    data.push(...response.Tokens.items);

                }

                // Check we have more data
                readMore = response.Tokens && response.Tokens.meta ? response.Tokens.meta.hasMore : false;

            } while (readMore);

            // Retour all our data
            return data;

        } catch (errors) {

            console.error(errors)

            throw this._connectionErrorGRAPHQL

        }
    }

    async getUserCreatedTotalCount(address) {

        try {

            const response = await this._graphQL.request(GetUserCreations, { userAddress: address, pageItemsCount: 1 });

            const count = response.Tokens && response.Tokens.meta ? response.Tokens.meta.total : 0

            return count

        } catch (errors) {

            console.error(errors);

            return 0

        }
    }

    async getUserCollections(address, limit = 100) {

        try {

            let data = [];
            let currentPage = 0;
            let readMore = false;

            do {

                // Get the paged data
                const response = await this._graphQL.request(GetUserCollections, { userAddress: address, page: ++currentPage, pageItemsCount: limit });

                // Check we actually have items
                if (response.Collections.items && response.Collections.items !== null) {

                    // Add our items
                    data.push(...response.Collections.items);

                }

                // Check we have more data
                readMore = response.Collections && response.Collections.meta ? response.Collections.meta.hasMore : false;

            } while (readMore);

            // Retour all our data
            return data;

        } catch (errors) {

            console.error(errors);

            throw this._connectionErrorGRAPHQL

        }
    }


    async getUserCollectionsTotalCount(address) {

        try {

            const response = await this._graphQL.request(GetUserCollections, { userAddress: address, pageItemsCount: 1 });

            const count = response.Collections && response.Collections.meta ? response.Collections.meta.total : 0

            return count

        } catch (errors) {

            console.error(errors);

            return 0

        }
    }

    async getToken(addressSM, tokenID) {

        try {

            const response = await this._graphQL.request(GetTokenInfo, {
                tokenID: tokenID.toString(),
                smAddress: addressSM
            });

            return response.Token;

        } catch (errors) {

            console.error(errors);

            throw this._connectionErrorGRAPHQL

        }
    }

    async getTokenEditions(addressSM, tokenID) {

        try {

            const response = await this._graphQL.request(GetTokenEditions, {
                tokenID: tokenID.toString(),
                smAddress: addressSM
            });

            return response.Editions;


        } catch (errors) {

            console.error(errors);

            throw this._connectionErrorGRAPHQL

        }
    }

    async getUserCollectedTotalCount(address) {

        try {

            const response = await this._graphQL.request(GetUserCollected, { userAddress: address, pageItemsCount: 1 });

            const count = response.Tokens && response.Tokens.meta ? response.Tokens.meta.total : 0

            return count

        } catch (errors) {

            console.error(errors);

            return 0

        }
    }

    async getUserCollected(address, limit = 100) {

        try {

            let data = [];
            let currentPage = 0;
            let readMore = false;

            do {

                // Get the paged data
                const response = await this._graphQL.request(GetUserCollected, { userAddress: address, withEditions: true, page: ++currentPage, pageItemsCount: limit });

                // Check we actually have items
                if (response.Tokens.items && response.Tokens.items !== null) {

                    // Add our items
                    data.push(...response.Tokens.items);

                }

                // Check we have more data
                readMore = response.Tokens && response.Tokens.meta ? response.Tokens.meta.hasMore : false;

            } while (readMore);

            // Retour all our data
            return data;


        } catch (errors) {

            console.error(errors);

            throw this._connectionErrorGRAPHQL

        }
    }

    async getUserOnSale(address, withEditions = false, limit = 100, filter = FilterTypes.All) {

        try {

            let data = [];
            let currentPage = 0;
            let readMore = false;

            do {

                // Get the paged data
                const response = await this._graphQL.request(GetUserOnSale, { userAddress: address, withEditions: withEditions, page: ++currentPage, pageItemsCount: limit });

                // Check we actually have items
                if (response.Tokens.items && response.Tokens.items !== null) {

                    // Add our items
                    data.push(...response.Tokens.items);

                }

                // Check we have more data
                readMore = response.Tokens && response.Tokens.meta ? response.Tokens.meta.hasMore : false;

            } while (readMore);

            // Retour all our data
            return data;

        } catch (errors) {

            console.error(errors);

            throw this._connectionErrorAPI

        }
    }

    // NOTE SDS: the returned total value is NOT the total sales but just the number of items
    //           Should be removed as useless
    async getUserOnSaleTotalCount(address, filter = FilterTypes.All) {


        try {

            const response = await this._graphQL.request(GetUserOnSale, { userAddress: address, pageItemsCount: 1 });

            const count = response.Tokens && response.Tokens.meta ? response.Tokens.meta.total : 0

            return count

        } catch (errors) {

            console.error(errors);

            return 0

        }
    }

    async getUserOffersTotalCount(address) {

        /*
        try {

            const response = await this._graphQL.request(GetUserOffersCount, { userAddress: address });

            const count = response.countOffers ? response.countOffers : 0

            return count

        } catch (errors) {

            console.error(errors);

            return 0

        }
        */
    }

    //* NEW */

    async getUserActivity(address, withUserInfo = false, withCollectionInfo = false, limit = 16, filter = FilterTypes.All) {

        try {

            let data = [];
            let currentPage = 0;
            let readMore = false;

            do {

                // Get the paged data
                const response = await this._graphQL.request(GetUserActivity, { userAddress: address, withUserInfo: withUserInfo, withCollectionInfo: withCollectionInfo, page: ++currentPage, pageItemsCount: limit });

                // Check we actually have items
                if (response.Activity.events && response.Activity.events !== null) {

                    // Add our items
                    data.push(...response.Activity.events);

                }

                // Check we have more data
                readMore = response.Activity ? response.Activity.hasMore : false;

            } while (readMore);

            // Retour all our data
            return data;

        } catch (errors) {

            console.error(errors);

            throw this._connectionErrorAPI

        }
    }

    async getTokenActivity(addressSM, tokenID, withUserInfo = false, withCollectionInfo = false, limit = 16, filter = FilterTypes.All) {

        try {

            let data = [];
            let currentPage = 0;
            let readMore = false;

            do {

                // Get the paged data
                const response = await this._graphQL.request(GetTokenActivity, { tokenID: tokenID, $smAddress: addressSM, withUserInfo: withUserInfo, withCollectionInfo: withCollectionInfo, page: ++currentPage, pageItemsCount: limit });

                // Check we actually have items
                if (response.Activity.events && response.Activity.events !== null) {

                    // Add our items
                    data.push(...response.Activity.events);

                }

                // Check we have more data
                readMore = response.Activity ? response.Activity.hasMore : false;

            } while (readMore);

            // Retour all our data
            return data;

        } catch (errors) {

            console.error(errors);

            throw this._connectionErrorAPI

        }
    }

    //* TODO */

    // Get Market Place Top Collectors
    async getTopCollectors() {

        /*
        try {

            const response = await axios.get(`${this._mainAPIUrl}/top-collector`)

            return response.data

        } catch (errors) {

            console.error(errors);

            throw this._connectionErrorAPI

        }
        */
    }

    // Get Market Place Top Artists
    async getTopArtists() {

        /*
        try {

            const response = await axios.get(`${this._mainAPIUrl}/top-artist`)

            return response.data

        } catch (errors) {

            console.error(errors);

            throw this._connectionErrorAPI

        }
        */
    }

}

export {
    WovMarketPlaceGRAPHQLRequestor,
    FilterTypes,
    OrderByTypes
}