
import './common'
import { VeChainConnector } from './vechain/chainConnector'




// Idea is here to have future support for multiple chains 
// TODO - then we need to rethink this design 

let initChains = async () => {

    let chainConnector = new VeChainConnector()

    return chainConnector

};

export { initChains }
