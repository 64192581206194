
// Internal
import { WovAccountBase, cAccountTypes } from './account_base'

class WovAccountOther extends WovAccountBase {

    constructor(chainConnector) {

        super(chainConnector, cAccountTypes.Other)

    }


    // Initialize Account further
    async initializeAccountFurther() {


    }


}

export {
    WovAccountOther
}