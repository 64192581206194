//import { find } from '../../css/imports/bulma/docs/vendor/fontawesome-free-5.15.2-web/js/v4-shims'
//import { WovAccountInfo } from '../wov/account'
import { findElementByID, hideElement, showElement, setElementText, setElementTextDirect, setElementImageSource, setElementDynamic, setElementStyle, setElementClass } from './utils/dom'
import { WovAccountArtist } from '../wov/account_artist'
import { WovAccountCollector } from '../wov/account_collector'
import { WovAccountToken } from '../wov/account_token'
import { WovAccountTest } from '../wov/account_test'
import { WovAccountOther } from '../wov/account_other'
import { cAccountTypes, cTokenDetailsTypes } from '../common/constants'


const cAccountLoader = 'acc-loading'

const cAccountNoneContainer = 'acc-no'

const cAccountInfoPanelID = 'acc-info'
const cAccountInfoID = 'acc-id'
const cAccountInfoThumbNail = 'acc-thumbnail'
const cAccountInfoName = 'acc-name'
const cAccountInfoAddress = 'acc-address'
const cAccountInfoGoldenSponsor = 'acc-gs'
const cAccountInfoCreationsCount = 'acc-creationscount'
const cAccountInfoEditionsCount = 'acc-editionscount'
const cAccountInfoCollectionsCount = 'acc-collectionscount'
const cAccountInfoCollectorsCount = 'acc-collectorscount'
const cAccountInfoOffersCount = 'acc-offerscount'
const cAccountInfoOffersCountUrlContainer = 'acc-offerscounturl'
const cAccountInfoOffersCountUrlLink = 'acc-offerscounturlurl'
const cAccountInfoAvailableCount = 'acc-availablecount'
const cAccountInfoBurnedCount = 'acc-burnedcount'
const cAccountInfoOnSalePrimaryCount = 'acc-salesprimarycount'
const cAccountInfoOnSaleSecondaryCount = 'acc-salessecondarycount'
const cAccountInfoAvailablePercentage = 'acc-availablepercentage'
const cAccountInfoSoldCount = 'acc-soldcount'
const cAccountInfoSoldPercentage = 'acc-soldpercentage'
const cAccountInfoGiftedCount = 'acc-giftedcount'
const cAccountInfoGiftedPercentage = 'acc-giftedpercentage'
const cAccountInfoBalanceContainer = 'acc-balance'
const cAccountInfoVETBalance = 'acc-vetbalance'
const cAccountInfoWOVBalance = 'acc-wovbalance'
const cAccountInfoWOVMidPrice = 'acc-wovmidprice'
const cAccountInfoWOVStakedContainer = 'acc-wovstaked'
const cAccountInfoWOVStakedBalance = 'acc-wovstakedbalance'
const cAccountInfoWOVRewardsContainer = 'acc-wovrewards'
const cAccountInfoWOVRewardsBalance = 'acc-wovstakedrewards'
const cAccountInfovVETContainer = 'acc-vvet'
const cAccountInfovVETBalance = 'acc-vvetbalance'
const cAccountInfoProfile = 'acc-profile'
const cAccountInfoTwitter = 'acc-twitter'
const cAccountInfoCollectedCount = 'acc-collectedcount'
const cAccountInfoArtistsCount = 'acc-artistscount'
const cAccountInfoSingleEditionsCount = 'acc-singleeditionscount'
const cAccountInfoArtistsVerifiedCount = 'acc-verifiedcount'
const cAccountInfoArtistsBlacklistedContainer = 'acc-blacklisted'
const cAccountInfoArtistsBlacklistedCount = 'acc-blacklistedcount'
const cAccountInfoUnlistedContainer = 'acc-unlisted-cont'
const cAccountInfoUnlistedCount = 'acc-unlistedcount'
const cAccountInfoUnlistedPercentage = 'acc-unlistedpercentage'

const cPageInfoNoMidPrices = 'page-nomidprices-info'

const cAccountInfoAutoRefreshTimerContainer = 'acc-tl-bar'
const cAccountInfoAutoRefreshTimerValue = 'acc-tl-val'

const cClassArtistVerified = 'art-ver'

const initializeAccount = async (connector, accountType = cAccountTypes.Artist) => {

    await hideElement(cAccountInfoPanelID)

    const accountWallet = connector.getWalletAddress();

    console.log(`Initializing account for address ${accountWallet.getAddress()}`)

    let accountInfoReader = null

    if (accountType === cAccountTypes.Artist) {

        accountInfoReader = new WovAccountArtist(connector)

    } else if (accountType === cAccountTypes.Collector) {

        accountInfoReader = new WovAccountCollector(connector)

    } else if (accountType === cAccountTypes.Token) {

        accountInfoReader = new WovAccountToken(connector)

    } else if (accountType === cAccountTypes.Test) {

        accountInfoReader = new WovAccountTest(connector)

    } else {

        accountInfoReader = new WovAccountOther(connector)

    }

    await accountInfoReader.initializeAccount()

    return accountInfoReader

};

// Account Info - Initial Render
const renderAccountInfo = async (accountInfo, accountType = cAccountTypes.Artist, isOverRuled = true) => {

    await setElementImageSource(cAccountInfoThumbNail, accountInfo.profileImageUrl)

    await setElementText(cAccountInfoName, accountInfo, 'name')

    //await setElementText(cAccountInfoAddress, accountInfo, 'address')

    if (accountType === cAccountTypes.Artist) {

        await hideElement(cAccountInfoUnlistedContainer)

        await hideElement(cAccountInfoOffersCountUrlContainer)

        if (accountInfo.verified) {

            await setElementClass(cAccountInfoID, cClassArtistVerified, false)

        }

    }

    // Check for overruled account - if so then we hide balance information
    if (isOverRuled) {

        await hideElement(cAccountInfoBalanceContainer)

    } else {

        await showElement(cAccountInfoBalanceContainer)

        await setElementText(cAccountInfoVETBalance, accountInfo, 'vetBalanceStr')

        await setElementText(cAccountInfoWOVBalance, accountInfo, 'wovBalanceStr')

        await setElementText(cAccountInfoWOVMidPrice, accountInfo, 'wovVEXMidPrice')

        if (accountInfo.wovStakedStr !== "") {

            await setElementText(cAccountInfoWOVStakedBalance, accountInfo, 'wovStakedStr')
            await setElementText(cAccountInfoWOVRewardsBalance, accountInfo, 'wovStakedRewardsStr')

            await showElement(cAccountInfoWOVRewardsContainer)
            await showElement(cAccountInfoWOVRewardsBalance)

        } else {

            await hideElement(cAccountInfoWOVRewardsContainer)
            await hideElement(cAccountInfoWOVRewardsBalance)
        }

        if (accountInfo.vVETBalanceStr !== "") {

            await setElementText(cAccountInfovVETBalance, accountInfo, 'vVETBalanceStr')

            await showElement(cAccountInfovVETContainer)

        } else {

            await hideElement(cAccountInfovVETContainer)

        }

    }

    // Account Icons
    if (accountInfo.profileUrl) {

        await setElementDynamic(cAccountInfoProfile, 'href', accountInfo.profileUrl)


    } else {

        await hideElement(cAccountInfoProfile)
    }

    if (accountInfo.twitterUrl) {

        await setElementDynamic(cAccountInfoTwitter, 'href', accountInfo.twitterUrl)


    } else {

        await hideElement(cAccountInfoTwitter)
    }

    await showElement(cAccountInfoPanelID)

    // Render cover image
    if (accountInfo.coverImage) {

        let myAccountInfoPanelElement = await findElementByID(cAccountInfoPanelID)

        let myStyleInfo = 'background-image: url(\'' + accountInfo.coverImage + '\');'

        await setElementStyle('', myStyleInfo, myAccountInfoPanelElement)
    }

    // Check for missing mid prices info
    if (accountInfo.wovVEXMidPriceIsValid) {

        await hideElement(cPageInfoNoMidPrices)
    } else (

        await showElement(cPageInfoNoMidPrices)
    )



}

// Account Info - Update with actuals
const updateAccountInfo = async (accountInfo, accountType = cAccountTypes.Artist) => {

    if (accountInfo.isGoldenSponsor) {

        await showElement(cAccountInfoGoldenSponsor)

    } else {

        await hideElement(cAccountInfoGoldenSponsor)
    }

    if (accountType === cAccountTypes.Artist) {

        await setElementText(cAccountInfoCreationsCount, accountInfo, 'createdCount')

        await setElementText(cAccountInfoEditionsCount, accountInfo, 'editionsCount')

        await setElementText(cAccountInfoCollectionsCount, accountInfo, 'collectionsCount')

        await setElementText(cAccountInfoCollectorsCount, accountInfo, 'collectorsCount')

        await setElementText(cAccountInfoOffersCount, accountInfo, 'offersCount')

        await setElementText(cAccountInfoBurnedCount, accountInfo, 'burnedCount')

        await setElementText(cAccountInfoSoldCount, accountInfo, 'soldPrimaryCount')

        await setElementText(cAccountInfoSoldPercentage, accountInfo, 'soldPrimaryPercentage')

        await setElementText(cAccountInfoGiftedCount, accountInfo, 'transferredCount')

        await setElementText(cAccountInfoGiftedPercentage, accountInfo, 'transferredPercentage')

        await setElementText(cAccountInfoAvailableCount, accountInfo, 'availableCount')

        await setElementText(cAccountInfoAvailablePercentage, accountInfo, 'availablePercentage')

        await setElementText(cAccountInfoOnSalePrimaryCount, accountInfo, 'onSalePrimaryCount')

        await setElementText(cAccountInfoOnSaleSecondaryCount, accountInfo, 'onSaleSecondaryCount')

        if (accountInfo.unlistedCount !== 0) {

            await setElementText(cAccountInfoUnlistedCount, accountInfo, 'unlistedCount')

            await setElementText(cAccountInfoUnlistedPercentage, accountInfo, 'unlistedPercentage')

            await showElement(cAccountInfoUnlistedContainer)

        }

        if (accountInfo.offersCount !== 0) {

            await setElementDynamic(cAccountInfoOffersCountUrlLink, 'href', accountInfo.profileUrl + '?tab=offers-received')

            await showElement(cAccountInfoOffersCountUrlContainer)

        } else {

            await hideElement(cAccountInfoOffersCountUrlContainer)
        }




    } else if (accountType === cAccountTypes.Collector) {

        await setElementText(cAccountInfoCollectedCount, accountInfo, 'collectedCount')

        await setElementText(cAccountInfoEditionsCount, accountInfo, 'editionsCount')

        await setElementText(cAccountInfoArtistsCount, accountInfo, 'artistsCount')

        await setElementText(cAccountInfoCollectionsCount, accountInfo, 'collectionsCount')

        await setElementText(cAccountInfoSingleEditionsCount, accountInfo, 'singleEditionsCount')

        await setElementText(cAccountInfoArtistsVerifiedCount, accountInfo, 'artistsVerifiedCount')

        if (accountInfo.artistsBlacklistedCount !== 0) {

            await setElementText(cAccountInfoArtistsBlacklistedCount, accountInfo, 'artistsBlacklistedCount')

            await showElement(cAccountInfoArtistsBlacklistedContainer)
        }

    }

    await setElementText(cAccountInfoVETBalance, accountInfo, 'vetBalanceStr')

    await setElementText(cAccountInfoWOVBalance, accountInfo, 'wovBalanceStr')

}

const showAccountNone = async () => {

    await showElement(cAccountNoneContainer)

    return true

}

const resetAccountInfo = async () => {

    await hideElement(cAccountInfoPanelID)
    await hideElement(cAccountNoneContainer)

    return true
}

const clearAccountInfo = async (accountType = cAccountTypes.Artist) => {

    if (accountType === cAccountTypes.Artist) {

        await setElementTextDirect(cAccountInfoCreationsCount, '0')
        await setElementTextDirect(cAccountInfoEditionsCount, '0')
        await setElementTextDirect(cAccountInfoCollectionsCount, '0')
        await setElementTextDirect(cAccountInfoCollectorsCount, '0')
        await setElementTextDirect(cAccountInfoOffersCount, '0')
        await setElementTextDirect(cAccountInfoBurnedCount, '0')
        await setElementTextDirect(cAccountInfoSoldCount, '0')
        await setElementTextDirect(cAccountInfoSoldPercentage, '0')
        await setElementTextDirect(cAccountInfoGiftedCount, '0')
        await setElementTextDirect(cAccountInfoGiftedPercentage, '0')
        await setElementTextDirect(cAccountInfoAvailableCount, '0')
        await setElementTextDirect(cAccountInfoOnSalePrimaryCount, '0')
        await setElementTextDirect(cAccountInfoOnSaleSecondaryCount, '0')
        await setElementTextDirect(cAccountInfoAvailablePercentage, '0')

        await hideElement(cAccountInfoOffersCountUrlContainer)

    } else if (accountType === cAccountTypes.Collector) {

        await setElementTextDirect(cAccountInfoCollectedCount, '0')
        await setElementTextDirect(cAccountInfoEditionsCount, '0')
        await setElementTextDirect(cAccountInfoArtistsCount, '0')
        await setElementTextDirect(cAccountInfoCollectionsCount, '0')
        await setElementTextDirect(cAccountInfoSingleEditionsCount, '0')
        await setElementTextDirect(cAccountInfoArtistsVerifiedCount, '0')
        await setElementTextDirect(cAccountInfoArtistsBlacklistedCount, '0')

    }

    await setElementTextDirect(cAccountInfoVETBalance, '0')
    await setElementTextDirect(cAccountInfoWOVBalance, '0')
    await setElementTextDirect(cAccountInfoWOVMidPrice, '0')
    await setElementTextDirect(cAccountInfoWOVStakedBalance, '0')
    await setElementTextDirect(cAccountInfoWOVRewardsBalance, '0')

}

const showAccountLoader = async () => {

    await showElement(cAccountLoader)

    return true
}

const hideAccountLoader = async () => {

    await hideElement(cAccountLoader)

    return true
}


const showAutoRefreshTimer = async () => {

    await showElement(cAccountInfoAutoRefreshTimerContainer)

    return true
}

const hideAutoRefreshTimer = async () => {

    await hideElement(cAccountInfoAutoRefreshTimerContainer)

    return true
}

const updateAutoRefreshTimerValue = async (value) => {

    await setElementTextDirect(cAccountInfoAutoRefreshTimerValue, value)

    return true
}



export {
    cAccountTypes,
    initializeAccount,
    renderAccountInfo,
    resetAccountInfo,
    clearAccountInfo,
    updateAccountInfo,
    showAccountLoader,
    hideAccountLoader,
    showAccountNone,
    showAutoRefreshTimer,
    hideAutoRefreshTimer,
    updateAutoRefreshTimerValue
}