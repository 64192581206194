
// Internal
import { WovAccountBase, cAccountTypes } from './account_base'

class WovAccountTest extends WovAccountBase {

    constructor(chainConnector) {

        super(chainConnector, cAccountTypes.Test)

        logText(`Initializing TEST account ...`)


    }


    // Initialize Account further
    async initializeAccountFurther() {

    }

    // 
    async lookupTokenInfo(tokenID) {

        return await this._lookupTokenInfo(tokenID)
    }

    // TESTER
    async getUserActivity(address, withUserInfo = false, withCollectionInfo = false) {

        return await this._getUserActivity(address, withUserInfo, withCollectionInfo);
    }

    async geTokenActivity(addressSM, tokenID, withUserInfo = false, withCollectionInfo = false) {

        return await this._getUserActivity(addressSM, tokenID, withUserInfo, withCollectionInfo);
    }

}

export {
    WovAccountTest
}