
// Internal
import { WovEnv } from './env'
import {
    FilterTypes as WovMarketPlaceAPIFilterTypes,
    OrderByTypes as WovMarketPlaceAPIOrderByTypes
} from './marketplace_graphql'
import { logError, logText, deepCopyFunction, logData, getTwitterHandle, getFormattedLocalNumber } from '../app/utils/common'
import { WovAccountBase, cAccountTypes } from './account_base'

class WovAccountCollector extends WovAccountBase {

    _collectorCollectedData = null
    _collectorEditionsData = null
    _collectorCollectionsData = null
    _collectorArtistsData = null
    _collectorPurchaseHistory = null
    _collectorMarketPlaceTokens = null


    constructor(chainConnector) {

        super(chainConnector, cAccountTypes.Collector)

    }

    // Initialize Account further
    async initializeAccountFurther() {

        await this.readAccountCollectorCollectedTotalCountMarketPlace()

    }

    // Get total collected count from the market place
    async readAccountCollectorCollectedTotalCountMarketPlace() {

        const count = await this._marketplaceAPIReq.getUserCollectedTotalCount(this._walletAddress)

        this._accountInfo.collectedCount = count
    }

    // Load the main data
    async loadMainData() {

        // First we get the purchases
        await this.loadCollectorCollected().then(async () => {

            // Then the purchase history
            await this.loadCollectorPurchaseHistory()

            // Then the tokens from the market place
            await this.loadCollectorTokensMarketPlace()

        })

    }


    // Generate collector stats
    async generateCollectorStats() {

        // Let's first get the collectors main data - we need this before we can continue
        await this.loadMainData().then(async () => {

            // Main data - editions - generation
            this.generateCollectorEditions()

        })

        // Update account info 
        this._accountInfo.editionsCount = (this._collectorEditionsData ? this._collectorEditionsData.length : 0)
        this._accountInfo.singleEditionsCount = (this._collectorEditionsData ? this._collectorEditionsData.filter(el => el.isSingleEdition).length : 0)
        this._accountInfo.collectionsCount = (this._collectorCollectedData ? this._collectorCollectionsData.length : 0)
        this._accountInfo.giftedCount = (this._collectorEditionsData ? this._collectorEditionsData.filter(el => el.isGifted).length : 0)

        this._accountInfo.purchasesPrimary = (this._collectorEditionsData ? this._collectorEditionsData.filter(el => el.isPrimaryPurchase).length : 0)
        this._accountInfo.purchasesSecondary = (this._collectorEditionsData ? this._collectorEditionsData.filter(el => el.isSecondaryPurchase).length : 0)

        this._accountInfo.artistsCount = this._collectorArtistsData.length
        this._accountInfo.artistsVerifiedCount = this._collectorArtistsData.filter(el => el.verified).length
        this._accountInfo.artistsBlacklistedCount = this._collectorArtistsData.filter(el => el.blacklisted).length

        this._accountInfo.isTopCollector = this._checkIsTopCollector(this._walletAddressLow)

        this._accountInfo.isGoldenSponsor = this._isGoldenSponsor(this._collectorEditionsData)

        // Total Purchases details
        this._sumAddPurchasesInfo(this._collectorEditionsData, this._accountInfo)

    }

    // Updates Account Info with artists data (after additional rendering of artists data)
    updateAccountInfoWithArtists() {

        // Determine missing / completed editions
        this._accountInfo.artistsCompletedCount = (this._collectorArtistsData ? this._collectorArtistsData.filter(el => el.missingCount === 0).length : 0)
        this._accountInfo.artistsMissingCount = (this._collectorArtistsData ? this._collectorArtistsData.filter(el => el.missingCount !== 0).length : 0)
        this._accountInfo.editionsTotalMissingCount = (this._collectorArtistsData ? this._collectorArtistsData.reduce((prevValue, currentValue) => prevValue + currentValue.missingCount, 0) : 0)
        this._accountInfo.artistsTopCount = (this._collectorArtistsData ? this._collectorArtistsData.filter(el => el.isTopArtist).length : 0)
    }


    // Load collected according to the market place API
    async loadCollectorCollected() {

        this._collectorCollectedData = null

        var collectedMarketPlaceData = await this._marketplaceAPIReq.getUserCollected(this._walletAddress, this.cRequestAPILimitUnlimited)

        // Remove ourselves from the GraphQL return (?)
        collectedMarketPlaceData = collectedMarketPlaceData.filter(el => el.creatorAddress.toLowerCase() !== this._walletAddressLow);

        if (collectedMarketPlaceData) {

            this._collectorCollectedData =
                collectedMarketPlaceData.reduce((acc, item) => {

                    // Reduce item and remove all what we don't need
                    //let { auction, token, edition, offer } = item
                    let { editions, ...myItem } = item

                    // Additional data                    
                    myItem.tokenWOVID = this._getWoVIDFromTokenID(myItem.tokenId)

                    if (editions) {

                        // Try to find ours
                        const myEdition = editions.find(el => el.ownerAddress.toLowerCase() === this._walletAddressLow);

                        // Did we find an edition ?
                        if (myEdition) {

                            // Get the token edition ID
                            myItem.tokenEditionID = this._getEditionIDFromTokenID(myEdition.editionId);

                            // Store  
                            acc.push(myItem)

                        }
                    }

                    return acc
                }, [])

        }

        this._collectorCollectedData.sort(this._sortByTokenWOV)

        return this._collectorCollectedData === null ? false : true

    }

    // Loads the collector tokens on the market place
    async loadCollectorTokensMarketPlace() {

        this._collectorMarketPlaceTokens = null

        let myTokens = await this._chainConnector.getMarketplaceTokensListed(WovEnv.smartcontract_wov_marketplace_address, this._walletAddress, false, true)

        // Remove the non collected tokens

        this._collectorMarketPlaceTokens =
            //this.sortByTokenEdition(myTokens.filter(el => (this._collectorPurchaseHistory.findIndex(col => col.tokenEditionID === el.tokenEditionID)) !== -1))
            myTokens.filter(el => (this._collectorCollectedData.findIndex(col => col.tokenWOVID === el.tokenWOVID)) !== -1)

        this._collectorMarketPlaceTokens.sort(this._sortByTokenEdition)

        this._accountInfo.editionsCount = (this._collectorMarketPlaceTokens ? this._collectorMarketPlaceTokens.length : 0)


    }

    // Get the collector purchase history
    async loadCollectorPurchaseHistory() {

        this._collectorPurchaseHistory = null

        // Get purchases list for the given address ( this can be prim + sec )
        let myData = await this._chainConnector.getMarketPlacePurchasesList(WovEnv.smartcontract_wov_marketplace_address, this._walletAddress, false)

        // Get sorted 
        this._collectorPurchaseHistory = myData.sort(this._sortByTokenEdition)

        // Determine count    
        this._accountInfo.purchasesCount = (this._collectorPurchaseHistory ? this._collectorPurchaseHistory.length : 0)
    }


    // Generates the actual collector editions by comparing market place collection, purchases and tokens
    generateCollectorEditions() {

        // Reset
        this._collectorEditionsData = new Array()

        // (1) Go over the tokens first - this should be the MAIN bulk of normal purchases
        this._collectorMarketPlaceTokens.forEach((token) => {

            // Grab a copy
            let myEdition = token

            // Find the linked collection
            const collection = this._collectorCollectedData.find(el => el.tokenWOVID === myEdition.tokenWOVID)

            if (collection) {

                // Merge into edition
                myEdition = { ...myEdition, ...collection }

            } else {

                logText(`Edition ${purchase.tokenEditionID} has not matching collection for ${purchase.tokenWOVID} ?`)
            }


            this._collectorEditionsData.push(myEdition)
        })

        // (2) Check for items we have in our collection but not as purchase ? These are gifted
        //     Note SDS: Issue - auctioned items are also transferred and not directly purchased so considered as a gift ?
        this._collectorCollectedData.forEach((collected) => {

            const myPurchaseInfo = this._collectorPurchaseHistory.find(el => el.tokenEditionID === collected.tokenEditionID)

            if (!myPurchaseInfo) {

                logText(`Collection Edition ${collected.tokenEditionID} is not found as a purchase ?`)

                // Get data from the collection and wrap as edition
                let { edition, owner, ...myEdition } = collected

                // Add it as well
                this._collectorEditionsData.push(myEdition)

            }

        })

        // Additional data generation
        this._collectorEditionsData.forEach((edition) => {

            // Check single editions
            edition.isSingleEdition = (edition.editionsCount > 1 ? false : true)

            // Check for thumbnail
            this._checkThumbnailAssets(edition)

            // Update price info
            this._updateDataPriceInfo(edition.paymentType, edition, false)

            // Look for purchase info
            const myPurchaseInfo = this._collectorPurchaseHistory.find(el => el.tokenEditionID === edition.tokenEditionID)

            if (myPurchaseInfo) {

                edition.isGifted = false
                edition.isPurchased = true
                edition.mostRecentPurchaseTimestamp = myPurchaseInfo.blockTimestamp
                edition.mostRecentPurchaseDate = myPurchaseInfo.blockDate

                if (myPurchaseInfo.prevOwner.toLowerCase() === edition.creatorAddress.toLowerCase()) {

                    edition.isPrimaryPurchase = true
                    edition.isSecondaryPurchase = false

                } else {

                    edition.isPrimaryPurchase = false
                    edition.isSecondaryPurchase = true

                }


            } else {

                edition.isGifted = true
                edition.isPurchased = false
                edition.isPrimaryPurchase = false
                edition.isSecondaryPurchase = false
                edition.mostRecentPurchaseTimestamp = 0
                edition.mostRecentPurchaseDate = null

                logText(`Edition ${edition.tokenEditionID} is gifted ?`)
            }


        })

        // Sort
        this._collectorEditionsData.sort(this._sortByTokenEdition)

        // Build first versions of Artists & Collections
        this.generateCollectorArtistsAndCollectionStats()

        //* Sanity checks - can be removed later on

        // Check for items we have in our collection but not as an edition ?
        this._collectorCollectedData.forEach((collected) => {


            const myEdition = this._collectorEditionsData.find(el => el.tokenWOVID === collected.tokenWOVID)

            if (!myEdition) {

                logText(`Collection ${collected.tokenWOVID} is not found as edition ?`)
            }

        })


        // Check for purchase we don't have tokens for ?
        this._collectorPurchaseHistory.forEach((purchase) => {

            const myToken = this._collectorMarketPlaceTokens.find(el => el.tokenEditionID === purchase.tokenEditionID)

            if (!myToken) {

                logText(`Edition ${purchase.tokenEditionID} is purchased but NO token listing found ?`)
            }
        })




    }

    // Uses the given purchase data to update the collector data with sales info
    _updateDataArtistPurchaseInfo(purchaseData, artist) {

        // Find the purchase info for that artist
        const myPurchasesInfo = purchaseData.filter(el => el.creator && el.creator.address.toLowerCase() === artist.address)

        // Generate purchase data
        this._sumAddPurchasesInfo(myPurchasesInfo, artist)

        // If we did have purchases
        if (myPurchasesInfo && myPurchasesInfo.length !== 0) {

            // First one is most recent purchase
            artist.mostRecentPurchaseDate = myPurchasesInfo[0].blockDate
            artist.mostRecentPurchaseTimestamp = myPurchasesInfo[0].blockTimestamp

        } else {

            logError(`No purchase sales history info found for the artist ${artist.address} ?`)
        }

    }

    // Generate Collector Artists Stats
    generateCollectorArtistsAndCollectionStats() {

        // Reset
        this._collectorArtistsData = new Array()
        this._collectorCollectionsData = new Array()

        // Start going over the editions

        this._collectorEditionsData.forEach((item) => {

            //* Artist Stats

            // Note SDS: purchases is calculated further down

            const myItem = item

            // Get artist ID
            const artistID = myItem.creatorAddress.toLowerCase()

            let myArtist = this._collectorArtistsData.find(el => el.ID === artistID)

            if (myArtist) {

                myArtist.editionsCollected++

                myArtist.tokensEditions.push(myItem.tokenEditionID)

                if (!myArtist.tokensWOV.includes(myItem.tokenWOVID)) {

                    myArtist.tokensWOV.push(myItem.tokenWOVID)

                    myArtist.itemsCollected = myArtist.tokensWOV.length

                }

            } else {

                // Artist
                myArtist = myItem.creator ? myItem.creator : { address: '999999', name: '?', verified: false }

                myArtist.ID = artistID
                myArtist.address = artistID
                myArtist.itemsCollected = 1
                myArtist.editionsCollected = 1
                myArtist.missingCount = 0
                myArtist.tokensEditions = new Array()
                myArtist.tokensEditions.push(myItem.tokenEditionID)
                myArtist.tokensWOV = new Array()
                myArtist.tokensWOV.push(myItem.tokenWOVID)

                myArtist.isDataCompleted = false

                myArtist.isTopArtist = this._checkIsTopArtist(myArtist.address)

                this._collectorArtistsData.push(myArtist)
            }

            //* Collections Stats

            if (myItem.collection) {

                let myCollectionInfo = this._collectorCollectionsData.find(el => el.collectionId === myItem.collection.collectionId)

                if (myCollectionInfo) {

                    myCollectionInfo.collectedCount++

                } else {

                    myCollectionInfo = myItem.collection

                    myCollectionInfo.artistID = artistID

                    myCollectionInfo.collectedCount = 1
                    /*
                    myCollectionInfo.collectedCount = item.collectedCount
                    myCollectionInfo.editionsCount = item.editionsCount
                    myCollectionInfo.availableCount = myCollectionInfo.editionsCount - myCollectionInfo.collectedCount
                    //myCollectionInfo.availableCount = item.onSaleCount && item.onSaleCount !== "" ? item.onSaleCount : 0           
                    //myCollectionInfo.notonSaleCountCount = myCollectionInfo.editionsCount - myCollectionInfo.collectedCount - myCollectionInfo.availableCount
                    myCollectionInfo.thumbNailUrl = myCollectionInfo.image ? myCollectionInfo.image : item.assets.uri
                    myCollectionInfo.collectionUrl = WovEnv.url_marketplace_collection + myCollectionInfo.id
                    
                    */

                    myCollectionInfo.spendVET = 0
                    myCollectionInfo.spendWOV = 0

                    this._collectorCollectionsData.push(myCollectionInfo)

                }

            } else {
                console.log('No Collection ID ? ' + myItem);
            }
        })

        // Update each artist with immediate determinable data
        this._collectorArtistsData.forEach((myArtist, index) => {

            // Purchasing information
            this._updateDataArtistPurchaseInfo(this._collectorEditionsData, myArtist)

        })

        // Default sorting of artists
        this._collectorArtistsData.sort(this._sorterByEditionsCollected2Name)

        // Update Top Artist
        this._setTopSorted(this._collectorArtistsData)

    }




    // Update artists information further
    // Note SDS: this is intensive so limit as much as possible !
    async updateCollectorArtistsInfo(artistsData = null, top = this.cTopCount) {

        // Determine data
        let myArtistsData = (artistsData ? artistsData : this._collectorArtistsData)

        // Let's only consider the top of artists that were not yet data completed
        myArtistsData = myArtistsData.slice(0, top)

        // If we don't have any they all have been processed already
        if (myArtistsData === null || myArtistsData.length === 0) {

            return
        }

        // 2nd 
        myArtistsData = myArtistsData.filter(el => !el.isDataCompleted)

        // If we don't have any they all have been processed already
        if (myArtistsData === null || myArtistsData.length === 0) {

            return
        }


        // Update the profiles ( generic )
        await this._updateDataUserProfilesInfo(myArtistsData)

        // Remove any non-existing
        myArtistsData = myArtistsData.filter(el => !el.notExisting)

        let tasks = new Array()

        // Note can this be determined by editions => collections ?

        // Additional artist info
        myArtistsData.forEach((myArtist, index) => {

            tasks.push(new Promise(async (resolve, reject) => {

                try {

                    myArtist.creations = await super.loadArtistCreations(myArtist.address, true)

                    myArtist.createdCount = (myArtist.creations ? myArtist.creations.length : 0)

                    myArtist.missingCount = myArtist.createdCount - myArtist.itemsCollected

                    myArtist.creations_missing = myArtist.creations.filter(el => this._collectorEditionsData.findIndex(dt => dt.tokenWOVID === el.tokenWOVID) === -1)

                    //myArtist.collectionsCount = await this._marketplaceAPIReq.getUserCollectionsTotalCount(myArtist.address)                    

                    myArtist.isDataCompleted = true

                    resolve(index)

                } catch (error) {

                    console.error('Artist info', error)

                    reject(error)

                }

            }))

        })

        // Let's execute our updates
        const result = await Promise.allSettled(tasks)

        // Let's update our data back into the artists data
        myArtistsData.forEach((myArtist) => {

            this._collectorArtistsData[this._collectorArtistsData.findIndex(el => el.ID === myArtist.ID)] = myArtist

        })

    }


    // Update artists editions information further by calling token info on each edition of a creation !
    // Note SDS: this is intensive so limit as much as possible !
    async updateCollectorArtistsEditionsInfo(artistsData = null, top = this.cTopCount, salesOnly = true) {

        // Determine data
        let myArtistsData = (artistsData ? artistsData : this._collectorArtistsData)

        // Let's only consider the top of artists that were not yet data completed
        myArtistsData = myArtistsData.slice(0, top)

        // If we don't have any they all have been processed already
        if (myArtistsData === null || myArtistsData.length === 0) {

            return
        }


        let tasks = new Array()

        // Note can this be determined by editions => collections ?

        // Additional artist info
        myArtistsData.forEach((myArtist, index) => {

            tasks.push(new Promise(async (resolve, reject) => {

                try {

                    if (myArtist.creations_missing && myArtist.creations_missing.length !== 0) {

                        myArtist.creations_missing.forEach(async (creation, index) => {

                            if ((salesOnly && creation.onSaleCount !== 0) || (!salesOnly)) {

                                creation.historyEditions = await this.lookupTokenAllEditionsInfo(creation.tokenWOVID, creation.editionsCount, myArtist.creations_missing)

                                if (creation.historyEditions) {


                                }
                            }

                        })
                    }

                    resolve(index)

                } catch (error) {

                    console.error('Artist info', error)

                    reject(error)

                }

            }))

        })

        // Let's execute our updates
        const result = await Promise.allSettled(tasks)

        // Let's update our data back into the artists data
        myArtistsData.forEach((myArtist) => {

            this._collectorArtistsData[this._collectorArtistsData.findIndex(el => el.ID === myArtist.ID)] = myArtist

        })

    }

    // Perform any heavy duty data generation after initial display
    async afterInitialDisplayArtistsDataGeneration() {

        // Complete all artists data
        await this.completeCollectorArtists(this.getCollectorArtists(), this.cAllCount).then((result) => {

            // Update account info
            this.updateAccountInfoWithArtists()

        })
    }


    // Completes artists data further - used to add data intensive calculations
    async completeCollectorArtists(artistsData, top = this.cAllCount) {

        // Update Artists Info
        await this.updateCollectorArtistsInfo(artistsData, top)

    }

    // Get random missing artist
    async getRandomMissingArtists(count = 1, withSalesOnly = true, lastonSale = false, singleEditionsOnly = false) {

        let myArtistsWithMissing = (this._collectorArtistsData ? deepCopyFunction(this._collectorArtistsData.filter(el => el.missingCount !== 0)) : null)

        if (singleEditionsOnly) {

            // Go through artist creations and remove anything which is not single editions
            myArtistsWithMissing.forEach((myArtist) => {

                myArtist.creations_missing = myArtist.creations_missing.filter(mi => Number.parseInt(mi.editionsCount) === 1)
            })

            // Now filter and keep only those
            myArtistsWithMissing = myArtistsWithMissing.filter(el => el.creations_missing.length !== 0)

        }

        if (withSalesOnly) {

            // If only last on sale
            if (lastonSale) {

                // Go through artist creations and remove anything with more then 1 sales
                myArtistsWithMissing.forEach((myArtist) => {

                    myArtist.creations_missing = myArtist.creations_missing.filter(mi => Number.parseInt(mi.onSaleCount) === 1)

                    if (!singleEditionsOnly) {

                        myArtist.creations_missing = myArtist.creations_missing.filter(mi => Number.parseInt(mi.editionsCount) !== 1)
                    }

                })

                // Now filter and keep only those
                myArtistsWithMissing = myArtistsWithMissing.filter(el => el.creations_missing.length !== 0)

            } else {

                myArtistsWithMissing = myArtistsWithMissing.filter(el => el.creations_missing.findIndex(mi => Number.parseInt(mi.onSaleCount) !== 0) !== -1)
            }
        }


        if (!myArtistsWithMissing || myArtistsWithMissing.length === 0) return null

        let myRandomCount = (myArtistsWithMissing.length < count ? myArtistsWithMissing.length : count)
        let i = 0
        let myRandomArtists = new Array()

        do {

            let myArtist = this._getRandomElementFromData(myArtistsWithMissing)

            if (myRandomArtists.findIndex(el => el.ID === myArtist.ID) === -1) {

                myRandomArtists.push(myArtist)
            }

            i++

        } while (myRandomArtists.length < myRandomCount && i < (myRandomCount * 5))

        if (!myRandomArtists || myRandomArtists.length === 0) return null

        await this.updateCollectorArtistsInfo(myRandomArtists, myRandomArtists.length)

        //await this.updateCollectorArtistsEditionsInfo(myRandomArtists, myRandomArtists.length, withSalesOnly)

        return myRandomArtists

    }

    /*
    async generateCollectorCollections() {

        this._collectorCollectionsData.forEach((collection) => {


        })

    }

    */



    isCollector() {

        return this._accountInfo && this._accountInfo.collectedCount !== 0
    }


    getCollectorCollected() {
        return this._collectorCollectedData
    }

    getCollectorEditions() {
        return this._collectorEditionsData
    }

    getCollectorArtists(sorterFunction = this._sorterByItemsEditions2Name) {
        return this._collectorArtistsData ? this._collectorArtistsData.sort(sorterFunction) : null
    }

    getCollectorCollections() {
        return this._collectorCollectionsData
    }

    getCollectorPurchases() {
        return (this._collectorEditionsData ? this._collectorEditionsData.filter(el => !el.isGifted).sort(this._sorterByPurchaseDate) : null)
    }

    /*

    getCollectorPurchasesPrimary() {
        return (this._collectorPurchaseHistory ? this._collectorPurchaseHistory.filter(el => el.isPurchasePrimary) : null)
    }

    getCollectorPurchasesSecondary() {
        return (this._collectorPurchaseHistory ? this._collectorPurchaseHistory.filter(el => !el.isPurchasePrimary) : null)
    }
    */

    getCollectorPurchasesLatest(latestCount = 1) {

        const myPurchases = this.getCollectorPurchases()

        return (myPurchases ? myPurchases.slice(0, latestCount) : null)
    }

    getCollectorGifted() {
        return (this._collectorEditionsData ? this._collectorEditionsData.filter(el => el.isGifted) : null)
    }

    getCollectorTokens() {
        return this._collectorMarketPlaceTokens
    }

    getArtistsMissing() {

        const myArtistsWithMissing = (this._collectorArtistsData ? this._collectorArtistsData.filter(el => el.missingCount !== 0) : null)

        return myArtistsWithMissing
    }

    async getRandomMissingArtist() {

        const myArtistsMissing = await this.getRandomMissingArtists()

        return (myArtistsMissing ? myArtistsMissing[0] : null)
    }


}

export {
    WovAccountCollector
}