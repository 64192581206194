
// External
import { Connex } from '@vechain/connex'
import { ChainId, Token, WVET, Fetcher, Route } from 'vexchange-sdk'


// Internal
import { WalletAddress } from '../common'
import { humanToEVM, evmToPrintable, evmToNumber, evmToHuman, makeBN, printBN } from '../utils/bignumber'
import { WovEnv } from '../../wov/env'
import { logError, deepCopyFunction } from '../../app/utils/common'

//import './functions'

// VeChain Connex Library
/*
const connex = new Connex({
    node: 'https://testnet.veblocks.net/',
    network: 'test'
})
*/






class VeChainConnector {

  cChainIdentifier = 'VEC'
  cWalletAddressSedas = '0x5B9cBee7C97017a2FB98f5d40153605A9825A487'
  cSignInfoText = 'Please sign the certificate to start using the tools. We only need your wallet addres to continue.'
  cMaxTokensCount = 100000
  cSortOrderAscending = 'asc'
  cSortOrderDescending = 'desc'
  _netNodeUrl = 'https://mainnet.veblocks.net'
  _netIdentifier = ''
  _netNodeBestUrl = '/blocks/best'
  _netNodeLogsEventUrl = '/logs/event'
  _connectorVendor = null
  _connectorThor = null
  _walletAddress = null
  initChainException = new Error('VeChain connection not properly initialized ... cannot use wallet.')

  constructor() {

    //TODO Switch net "main" vs "test" by env ?
    this._netIdentifier = 'main'

    // Connex Thor setup
    this._connex = new Connex({
      node: this._netNodeUrl,
      network: this._netIdentifier
    })

    this._netNodeBestUrl = this._netNodeUrl + this._netNodeBestUrl
    this._netNodeLogsEventUrl = this._netNodeUrl + this._netNodeLogsEventUrl

    this._connectorThor = this._connex.thor

    // Get Connex Vendor API
    this._connectorVendor = new Connex.Vendor(this._netIdentifier)


    //TODO error handling if empty

    this._walletAddress = new WalletAddress(this.cChainIdentifier)
  }

  _tokenIDToHex(tokenId) {

    const tokenIdToHex = tokenId.toString(16)

    return "0x0000000000000000000000000000000000000000000000000000000000000000".slice(0, -tokenIdToHex.length) + tokenIdToHex

  }

  _addressToHex(address) {

    const myAddress = address.slice(2)

    return "0x0000000000000000000000000000000000000000000000000000000000000000".slice(0, -myAddress.length) + myAddress

  }

  _hex_to_ascii(value_hex) {

    const hex = value_hex.toString()
    let str = ''
    for (let n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16))
    }
    return str
  }

  _getWoVIDFromTokenID(tokenID) {

    return Math.floor(tokenID / 100000) * 100000;
  }

  // Login via Wallet
  async logIn() {

    if (!this._walletAddress.hasValidAddress()) {

      let signResult =
        await this._connectorVendor
          .sign("cert", {
            purpose: "identification",
            payload: {
              type: "text",
              content: this.cSignInfoText
            }
          })
          .request()

      this._walletAddress.setAddressSigned(signResult)

    }

    return this._walletAddress.getAddress()
  }

  // Has Wallet Address ?
  hasWalletAddress() {

    return this._walletAddress.hasValidAddress()
  }

  // Get Walled Address
  getWalletAddress() {

    return this._walletAddress
  }

  // Log out
  async logOut() {

    if (this._walletAddress && this._walletAddress.hasValidAddress()) {

      this._walletAddress.resetAddress()
    }

    return true
  }

  // Get WOV Account Info (via smart contract)
  async getWoVAccountInfo(addressContract) {

    const ABI = {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        }
      ],
      "name": "getAccountPropertiesByAddress",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        },
        {
          "internalType": "uint8",
          "name": "",
          "type": "uint8"
        },
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        },
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        },
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        },
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        },
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    };

    // eslint-disable-next-line        
    const method = this._connectorThor.account(addressContract).method(ABI)

    const transfer = await method.call(this._walletAddress.getAddress())

    return transfer.decoded
  }

  // Get WOV Balanve Value
  async getWoVBalance(addressContract) {

    const ABI = {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'balanceOf',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    }

    // eslint-disable-next-line      
    const method = this._connectorThor.account(addressContract).method(ABI)

    const transfer = await method.call(this._walletAddress.getAddress())

    return parseInt(transfer.decoded[0])
  }

  // Get Staked WOV Balance Value
  async getWoVStakedBalance(addressesContract) {

    const ABI = {
      inputs: [{
        internalType: "address",
        name: "account",
        type: "address"
      }],
      name: "balanceOf",
      outputs: [{
        internalType: "uint256",
        name: "",
        type: "uint256"
      }],
      stateMutability: "view",
      type: "function"
    }

    let myBalance = 0
    let tasks = new Array()

    addressesContract.forEach((addressContract) => {

      tasks.push(new Promise(async (resolve, reject) => {

        // eslint-disable-next-line      
        const method = this._connectorThor.account(addressContract).method(ABI)

        const transfer = await method.call(this._walletAddress.getAddress())

        const balance = parseInt(transfer.decoded[0])

        myBalance = myBalance + balance

        resolve(balance)

      }))

    })

    const myResults = await Promise.all(tasks)

    return myBalance
  }

  // Get Staked WOV Rewards Value
  async getWoVStakedRewards(addressesContract) {

    const ABI = {
      inputs: [{
        internalType: "address",
        name: "account",
        type: "address"
      }],
      name: "earned",
      outputs: [{
        internalType: "uint256",
        name: "",
        type: "uint256"
      }],
      stateMutability: "view",
      type: "function"
    }

    let myRewards = 0
    let tasks = new Array()

    addressesContract.forEach((addressContract) => {

      tasks.push(new Promise(async (resolve, reject) => {

        // eslint-disable-next-line      
        const method = this._connectorThor.account(addressContract).method(ABI)

        const transfer = await method.call(this._walletAddress.getAddress())

        const reward = parseInt(transfer.decoded[0])

        myRewards = myRewards + reward

        resolve(reward)

      }))

    })

    const myResults = await Promise.all(tasks)

    return myRewards
  }

  // Get Vechain Account Info
  async getVeChainAccountInfo() {

    const acc = this._connectorThor.account(this._walletAddress.getAddress())

    const accInfo = await acc.get()

    return accInfo
  }

  // Get the Vechain best block info
  async getBestBlockInfo() {

    // Get block info
    const blockInfo = await window.fetch(this._netNodeBestUrl).then(response => response.json());

    return (blockInfo && blockInfo.number ? blockInfo.number : 0)
  }

  // Event search payload generator
  _getSearchEventLogPayloadRange(blockNumberTo = null) {

    let data =
    {
      unit: 'block',
      from: 10459363
    }

    if (blockNumberTo !== null) {

      data.to = blockNumberTo
    }

    return data

  }

  _getSearchEventLogPayloadOptions(limit = 1, offset = 0) {

    return {
      offset: offset,
      limit: limit
    }
  }

  _getSearchEventLogPayloadOrder(order = this.cSortOrderDescending) {

    return order
  }

  async _getDefaultSearchEventLogPayload(limit = this.cMaxTokensCount, offset = 0, order = this.cSortOrderDescending) {

    let payload = {}

    const toBlockNumer = await this.getBestBlockInfo()

    payload.range = this._getSearchEventLogPayloadRange(toBlockNumer)
    //payload.range = this._getSearchEventLogPayloadRange()
    payload.options = this._getSearchEventLogPayloadOptions(limit, offset)
    payload.order = this._getSearchEventLogPayloadOrder(order)

    return payload
  }

  _setEventLogPayloadMainCriteriaSet(payload, contractAddress, methodName) {

    payload.criteriaSet =
      [
        {
          address: contractAddress,
          topic0: methodName
        }
      ]

    return payload
  }

  _setEventLogPayloadMainCriteriaSetTopic(payload, topicValue, topicIndex = 1, criteriaIndex = 1) {

    const topicField = 'topic' + topicIndex.toString()

    payload.criteriaSet[criteriaIndex - 1][topicField] = topicValue

    return payload
  }

  _getEventLogsParams(payload) {

    return {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }

  }

  // Get token events from the marketplace 
  // Note SDS: Gently borrowed from WoV marketplace
  async _getMarketplaceTokensListed(payload, sortRecentFirst, includeHistory) {


    let tokenIdListStatus = [];
    let keepReading = false;

    do {

      const eventLogsResponse = await window.fetch(this._netNodeLogsEventUrl, this._getEventLogsParams(payload)).then(response => response.json())

      const mapTokenIdToLastEventLog = eventLogsResponse
        .reduce((acc, log) => {
          const matched = log.data.slice(2).match(/.{1,64}/g)

          if (matched) {

            const myTokenID = parseInt(log.topics[2], 16)
            const myPriceStr = parseInt(matched[1], 16).toString()

            const obj = {
              // SDS - Keep lower case or not ?
              owner: `0x${log.topics[1].slice(26)}`.toLowerCase(),
              tokenWOVID: this._getWoVIDFromTokenID(myTokenID),
              tokenEditionID: myTokenID,
              //tokenUrl: WovEnv.url_marketplace_token + myTokenID,
              isListed: parseInt(matched[0], 16) !== 0,
              price: evmToNumber(myPriceStr),
              priceStr: evmToPrintable(myPriceStr),
              paymentType: this._hex_to_ascii(matched[5]).slice(0, 3),
              blockTimestamp: log.meta.blockTimestamp,
              blockDate: new Date(log.meta.blockTimestamp * 1000),
              blockNumber: log.meta.blockNumber
            }

            /*
            if (myTokenID === 6600200200007) {
  
              console.log('huray')
            }*/

            if (acc[myTokenID]) {

              if (includeHistory) {

                acc[myTokenID].history.push(obj)

              }

            } else {

              //acc[myTokenID] = deepCopyFunction(obj)
              acc[myTokenID] = obj

              if (includeHistory) {

                acc[myTokenID].history = new Array()

                acc[myTokenID].history.push(obj)

              }

            }
          }

          return acc;
        }, {});

      const tokenReadIdListStatus = Object.values(mapTokenIdToLastEventLog)

      // Did we find data ?  
      if (tokenReadIdListStatus && tokenReadIdListStatus.length !== 0) {

        // Add it to our final return
        tokenIdListStatus.push(...tokenReadIdListStatus);

        // Get the most recent read block ( last entry !)
        let lastReadBlockID = tokenReadIdListStatus[tokenReadIdListStatus.length - 1].blockNumber;

        // If we have a number
        if (lastReadBlockID) {

          // Let's start reading from the next one
          payload.range.from = ++lastReadBlockID;

          keepReading = true;

        }

      } else {

        keepReading = false;

      }



    } while (keepReading);

    return tokenIdListStatus;

  }


  async _getMarketplaceTokensListedNEW(payload, sortRecentFirst, includeHistory) {


    let tokenIdListStatus = [];
    let keepReading = false;

    do {

      const eventLogsResponse = await window.fetch(this._netNodeLogsEventUrl, this._getEventLogsParams(payload)).then(response => response.json())

      const mapTokenIdToLastEventLog = eventLogsResponse
        .reduce((acc, log) => {
          const matched = log.data.slice(2).match(/.{1,64}/g)

          if (matched) {

            const myTokenID = parseInt(log.topics[3], 16)
            const myPriceStr = parseInt(matched[1], 16).toString()

            const obj = {
              // SDS - Keep lower case or not ?
              owner: `0x${matched[0].slice(26)}`.toLowerCase(),
              tokenWOVID: this._getWoVIDFromTokenID(myTokenID),
              tokenEditionID: myTokenID,
              //tokenUrl: WovEnv.url_marketplace_token + myTokenID,
              //isListed: parseInt(matched[0], 16) !== 0,
              price: evmToNumber(myPriceStr),
              priceStr: evmToPrintable(myPriceStr),
              //paymentType: this._hex_to_ascii(matched[5]).slice(0, 3),
              blockTimestamp: log.meta.blockTimestamp,
              blockDate: new Date(log.meta.blockTimestamp * 1000),
              blockNumber: log.meta.blockNumber
            }

            /*
            if (myTokenID === 6600200200007) {
  
              console.log('huray')
            }*/

            if (acc[myTokenID]) {

              if (includeHistory) {

                acc[myTokenID].history.push(obj)

              }

            } else {

              //acc[myTokenID] = deepCopyFunction(obj)
              acc[myTokenID] = obj

              if (includeHistory) {

                acc[myTokenID].history = new Array()

                acc[myTokenID].history.push(obj)

              }

            }
          }

          return acc;
        }, {});

      const tokenReadIdListStatus = Object.values(mapTokenIdToLastEventLog)

      // Did we find data ?  
      if (tokenReadIdListStatus && tokenReadIdListStatus.length !== 0) {

        // Add it to our final return
        tokenIdListStatus.push(...tokenReadIdListStatus);

        // Get the most recent read block ( last entry !)
        let lastReadBlockID = tokenReadIdListStatus[tokenReadIdListStatus.length - 1].blockNumber;

        // If we have a number
        if (lastReadBlockID) {

          // Let's start reading from the next one
          payload.range.from = ++lastReadBlockID;

          keepReading = true;

        }

      } else {

        keepReading = false;

      }



    } while (keepReading);

    return tokenIdListStatus;

  }


  // Get Market place token listed from the marketplace 
  async getMarketplaceTokensListed(addressContract, address = null, sortRecentFirst = true, includeHistory = false) {

    let payload = await this._getDefaultSearchEventLogPayload(this.cMaxTokensCount, 0, (sortRecentFirst ? this.cSortOrderDescending : this.cSortOrderAscending))

    this._setEventLogPayloadMainCriteriaSet(payload, addressContract, '0x595f6e75f501d14c3540f4e0e5216800899bf63674cdd62ef4c6e92326a5b450')
    //this._setEventLogPayloadMainCriteriaSet(payload, addressContract, '0x2f68ebad7f3a3ea711d9b120acf07d745158b6253d70ce38d9f35724f4b75cc6')
    this._setEventLogPayloadMainCriteriaSetTopic(payload, (address ? this._addressToHex(address) : this._addressToHex(this._walletAddress.getAddress())))

    return this._getMarketplaceTokensListed(payload, sortRecentFirst, includeHistory)
  }

  // Get Market place token listed from the marketplace 
  async getMarketplaceTokenListed(addressContract, tokenID, sortRecentFirst = false, includeHistory = true) {

    let payload = await this._getDefaultSearchEventLogPayload(this.cMaxTokensCount, 0, (sortRecentFirst ? this.cSortOrderDescending : this.cSortOrderAscending))
    let myTokensList = [];

    // TEST for dual contract handling (pffffff)
    if (addressContract === WovEnv.smartcontract_wov_marketplace_address) {

      this._setEventLogPayloadMainCriteriaSet(payload, addressContract, '0x595f6e75f501d14c3540f4e0e5216800899bf63674cdd62ef4c6e92326a5b450')
      this._setEventLogPayloadMainCriteriaSetTopic(payload, this._tokenIDToHex(tokenID), 2)
      myTokensList = await this._getMarketplaceTokensListed(payload, sortRecentFirst, includeHistory)

    } else {

      this._setEventLogPayloadMainCriteriaSet(payload, addressContract, '0x4d0b0c9dba6cc79527b52313869b6e43dcd323e7c1291fae24187c72dff27db0')
      this._setEventLogPayloadMainCriteriaSetTopic(payload, this._tokenIDToHex(tokenID), 3)
      myTokensList = await this._getMarketplaceTokensListedNEW(payload, sortRecentFirst, includeHistory)

    }


    //




    return (myTokensList && myTokensList.length !== 0 ? myTokensList[0] : null)

  }

  // Get Tokens Events Purchasing Events for given owner 
  async getMarketPlacePurchasesList(addressContract, ownerAddress = null, searchAsSales = true) {

    let payload = await this._getDefaultSearchEventLogPayload()

    this._setEventLogPayloadMainCriteriaSet(payload, addressContract, '0xef258f47a33a1cba99d81ea828f234ff5d6cb31034c0f79ecb5198f8c6d118f6') // Purchase
    //this._setEventLogPayloadMainCriteriaSet(payload, addressContract, '0x2f68ebad7f3a3ea711d9b120acf07d745158b6253d70ce38d9f35724f4b75cc6') // Purchase NEW

    if (searchAsSales) {

      this._setEventLogPayloadMainCriteriaSetTopic(payload, (ownerAddress ? this._addressToHex(ownerAddress) : this._addressToHex(this._walletAddress.getAddress())))

    } else {

      this._setEventLogPayloadMainCriteriaSetTopic(payload, (ownerAddress ? this._addressToHex(ownerAddress) : this._addressToHex(this._walletAddress.getAddress())), 2)


    }

    let tokenIDS = [];
    let keepReading = false;

    do {

      const eventLogsResponse = await window.fetch(this._netNodeLogsEventUrl, this._getEventLogsParams(payload)).then(response => response.json())

      const tokenReadIDS = eventLogsResponse
        .reduce((acc, log) => {

          const matched = log.data.slice(2).match(/.{1,64}/g)

          if (matched) {

            const myPriceStr = parseInt(matched[0], 16).toString()
            const myTokenID = parseInt(matched[1], 16)

            const obj = {
              prevOwner: `0x${log.topics[1].slice(26)}`,
              newOwner: `0x${log.topics[2].slice(26)}`,
              tokenWOVID: this._getWoVIDFromTokenID(myTokenID),
              tokenEditionID: myTokenID,
              //tokenUrl: WovEnv.url_marketplace_token + myTokenID,
              price: evmToNumber(myPriceStr),
              priceStr: evmToPrintable(myPriceStr),
              blockTimestamp: log.meta.blockTimestamp,
              blockDate: new Date(log.meta.blockTimestamp * 1000),
              blockNumber: log.meta.blockNumber,
              txID: log.meta.txID
            }

            acc.push(obj)
          }

          return acc
        }, []);

      // Did we find data ?  
      if (tokenReadIDS && tokenReadIDS.length !== 0) {

        // Add it to our final return
        tokenIDS.push(...tokenReadIDS);

        // Get the most recent read block ( first entry !)
        let lastReadBlockID = tokenReadIDS[0].blockNumber;

        // If we have a number
        if (lastReadBlockID) {

          // Let's start reading from the next one
          payload.range.from = ++lastReadBlockID;

          keepReading = true;

        }

      } else {

        keepReading = false;

      }



    } while (keepReading);

    return tokenIDS;

  }


  // Send VET Amount via wallet
  async sendVETAmount(amountVET, comment = 'Donation') {

    if (amountVET === null || amountVET === 0) return

    let myComment = (comment ? comment : `Donation of '${amountVET}' VET to support WoV Tools`)

    await this._connectorVendor
      .sign("tx", [{
        to: this.cWalletAddressSedas,
        value: amountVET * 1e18,
        data: "0x"
      }])
      .comment(myComment)
      .request()
  }

  // Send WOV Amount via wallet
  async sendWOVAmount(amountWOV, comment = 'Donation') {

    if (amountWOV === null || amountWOV === 0) return

    let myComment = (comment ? comment : `Donation of '${amountWOV}' WOV to support WoV Tools`)

    const ABI = {
      constant: false,
      inputs: [
        { name: "_to", type: "address" },
        { name: "_amount", type: "uint256" }
      ],
      name: "transfer",
      outputs: [{ name: "success", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function"
    }

    // eslint-disable-next-line      
    const method = this._connectorThor
      .account(WovEnv.smartcontract_wov_vip180_address)
      .method(ABI)

    const evmWovAmount = humanToEVM(amountWOV)

    const clause = method.asClause(this.cWalletAddressSedas, (evmWovAmount).toString(10))

    await this._connectorVendor
      .sign("tx", [clause])
      .comment(myComment)
      .request()
  }

  // Get VEX Token Price Info
  async getVEXTokenTradingPrice(tokenAddress = null, dp = 6) {

    try {

      const token = new Token(ChainId.MAINNET, tokenAddress, 18)

      const pair = await Fetcher.fetchPairData(token, WVET[token.chainId], this._connex)

      const route = new Route([pair], WVET[token.chainId])

      const midPrice = route.midPrice.toSignificant(dp)

      return midPrice

    } catch (error) {

      logError('Cannot retrieve VEX mid price', error)

      return 0
    }


  }

}

export { VeChainConnector }
