// External
import { GoogleCharts } from 'google-charts'

// Local
import {
    findElementByID, clearElement, hideElement, showElement, setElementText, setElementTextDirect, setElementImageSource,
    getElementFromTemplate, setElementDynamic, setElementData, findSourceContainer, setElementStyle
} from './utils/dom'

import {
    getTemplateNameTokenInfo, cTokenInfoOptions_ARTIST, cTokenInfoOptions_IMAGE_ONLY, renderTokenInfo,
    getTemplateNameArtistInfo, renderArtistInfo, getDefaultThumbNailImage,
    renderGraphIcons, wrapContentAsColumn
} from './common'

import {
    logError, getTokenEditionfromTokenID, getLocalFormattedDate, getLocalFormattedDateAsMonth,
    getFormattedLocalNumber, modalizeID, getRandomElementFromData
}
    from './utils/common'
import { WovEnv } from '../wov/env'



// COLLECTORS Page Rendering Methods & Utilities

const cTopAll = 9999

const cCollectorContentContainer = 'col-main'
const cCollectorNoContentContainer = 'col-no'

const cCollectorLoader = 'col-loading'
const cCollectorCollectionLoader = 'col-col-loading'
const cCollectorArtTopLoader = 'art-top-loading'

const cCollectorCollectionsContainer = 'col-collections'

const cCollectorTopArtistsContainer = 'art-top-artists'

const cCollectorArtistsGraphContainer = 'col-graph-all-artists'
const cCollectorTopArtistsGraphContainer = 'col-graph-top-artists'
const cCollectorPurchasesAllCountGraphContainer = 'col-graph-all-purchases-count'
const cCollectorPurchasesAllVETGraphContainer = 'col-graph-all-purchases-vet'
const cCollectorTopMarketPlaceContainer = 'col-topcol'

const cCollectorCollectionsCompletionsGraphContainer = 'col-graph-col-compl'
const cCollectorGraphContainer = 'art-graph-sales-2'

const cCollectorCollectionTypesGraphContainer = 'col-graph-col-types'
const cCollectorCollectionPurchasesValuesGraphContainer = 'col-graph-col-purv'

const cCollectorButtonCollectorAll = 'art-show-all'

const cCollectorLatestPurchasesContainer = 'col-latest-1'
const cCollectorLatestPurchasesNoneContainer = 'col-latest-0'

const cCollectorPurchasesHistoryContainer = 'php-cont'
const cCollectorPurchasesHistoryDataContainer = 'php-data-cont'
const cCollectorButtonPurchasesHistoryAll = 'php-show-all'
const cCollectorPurchasesHistoryTopCount = 'php-top-total-count'

const cCollectorArtistMissing1Container = 'col-art-mis1'
const cCollectorArtistMissingLast1Container = 'col-art-misl1'
const cCollectorArtistMissingSingle1Container = 'col-art-miss1'
const cCollectorArtistMissingSingle1ContentContainer = 'col-art-miss1-cont'

const cTemplateCollectionInfo = 'template_collection_info'
const cTemplatePurchasesHistoryTable = 'template_purchases_history_table'




const isCollectorPage = async () => {

    const myElement = await findElementByID(cCollectorContentContainer)

    return (myElement ? true : false)
}

const showCollectorContent = async () => {

    await showElement(cCollectorContentContainer)



    return true
}

const resetCollectorContent = async () => {

    // General

    await hideElement(cCollectorContentContainer)
    await hideCollectorLoader()

    // Stats
    await resetMissingArtistsLastOnSale()
    await resetMissingArtistsSingleOnSale()
    await resetMissingArtists()


    // Collections
    await setElementTextDirect('col-col-total-count', '')
    await clearElement(cCollectorCollectionsContainer)

    // Top Artists
    await clearElement(cCollectorTopArtistsContainer)
    await setElementTextDirect('art-top-ind', '')
    await setElementTextDirect('art-top-total-count', '')
    await hideElement('art-copy-twitter')

    return true
}

const showCollectorNoContent = async () => {

    await showElement(cCollectorNoContentContainer)

    return true
}

const showCollectorLoader = async () => {

    await showElement(cCollectorLoader)

    return true
}

const hideCollectorLoader = async () => {

    await hideElement(cCollectorLoader)

    return true
}



const showCollectorArtTopLoader = async () => {

    await showElement(cCollectorArtTopLoader)

    return true
}

const hideCollectorArtTopLoader = async () => {

    await hideElement(cCollectorArtTopLoader)

    return true
}

const showCollectorCollectionLoader = async () => {

    await showElement(cCollectorCollectionLoader)

    return true
}

const hideCollectorCollectionLoader = async () => {

    await hideElement(cCollectorCollectionLoader)

    return true
}


const renderCollections = async (collections, top = cTopAll, sourceContainer = null, resetContainer = true) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cCollectorCollectionsContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Find the template 
    const myTemplate = await findElementByID(cTemplateCollectionInfo)

    if (!myTemplate) {

        logError('Cannot find template ' + cTemplateCollectionInfo)

        return
    }

    if (artists === null || artists.length === 0) {

        logError('No artists data received')

        return
    }


    // Adjust data for requested top
    const myCollections = artists.slice(0, top)

    // Generate content
    myCollections.forEach(async (collection) => {

        let myNewElement = myTemplate.content.firstElementChild.cloneNode(true);

        let myThumb = await getElementFromTemplate(myNewElement, 'img', false)
        await setElementImageSource('', collection.thumbNailUrl, myThumb)

        let myThumbUrl = await getElementFromTemplate(myNewElement, 'col-thumb-link')
        await setElementDynamic('', 'href', collection.collectionUrl, myThumbUrl)

        let myName = await getElementFromTemplate(myNewElement, 'col-name')
        await setElementText('', collection, 'name', myName)

        let myCollectedCount = await getElementFromTemplate(myNewElement, 'col-collectedcount')
        await setElementText('', collection, 'collectedCount', myCollectedCount)

        /*
        let myCreationsCount = await getElementFromTemplate(myNewElement, 'art-creationscount')
        await setElementText('', collection, 'creationsCount', myCreationsCount)

        let myEditionsCount = await getElementFromTemplate(myNewElement, 'art-editionscount')
        await setElementText('', collection, 'editionsCount', myEditionsCount)

        let myCollectorCount = await getElementFromTemplate(myNewElement, 'art-collectorscount')
        await setElementText('', collection, 'collectorsCount', myCollectorCount)

        

        let myAvailableCount = await getElementFromTemplate(myNewElement, 'art-availablecount')
        await setElementText('', collection, 'availableCount', myAvailableCount)

        let myNotOnSaleCount = await getElementFromTemplate(myNewElement, 'art-notonsalecount')
        await setElementText('', collection, 'notOnSaleCount', myNotOnSaleCount)

        */

        mySourceContainer.appendChild(myNewElement)

    })


    await setElementTextDirect('col-col-total-count', '( ' + collections.length + ' )')


}

const renderTopArtists = async (artists, top = cTopAll, sortedByEditions = true, sourceContainer = null, resetContainer = true, isModal = false) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cCollectorTopArtistsContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Find the template 
    const cTemplateArtistInfo = getTemplateNameArtistInfo()

    const myTemplate = await findElementByID(cTemplateArtistInfo)

    if (!myTemplate) {

        logError('Cannot find template ', cTemplateArtistInfo)

        return
    }

    if (artists === null || artists.length === 0) {

        logError('No artists data received')

        return
    }

    // Adjust data for requested top
    const myTopArtists = artists.slice(0, top)

    // Sorted by
    let mySortedText = ' - Ranked by ' + (sortedByEditions ? 'Editions' : 'Purchased VET')
    await setElementTextDirect(modalizeID('art-top-sort', isModal), mySortedText)

    if (!isModal) {

        let mySwitchButtonText = ' Rank by ' + (!sortedByEditions ? 'Editions' : 'Purchased VET')
        await setElementTextDirect(modalizeID('art-sort-switch', isModal), mySwitchButtonText)

    }

    // All button
    if (artists.length === myTopArtists.length) {

        await hideElement(modalizeID(cCollectorButtonCollectorAll, isModal))

    } else {


    }

    // Total Count
    let myTopCountText = myTopArtists.length.toString()

    if (artists.length > myTopArtists.length) {

        myTopCountText = myTopCountText + ' of ' + artists.length.toString()

    }

    await setElementTextDirect(modalizeID('art-top-total-count', isModal), '( ' + myTopCountText + ' )')


    // Twitter
    let myTwitterHandles = ""

    myTopArtists.forEach((artist, index) => {

        // Build combined twitter handles
        if (artist.twitterHandle) {

            myTwitterHandles = myTwitterHandles + ' ' + artist.twitterHandle
        }
    })

    if (myTwitterHandles !== "") {

        await setElementTextDirect('art-top-comb-twitter', myTwitterHandles)

        await showElement('art-copy-twitter')

    } else {

        await hideElement('art-copy-twitter')
    }


    // Generate content
    let myElements = new Array(myTopArtists.length)

    let tasks = new Array()

    myTopArtists.forEach(async (artist, dataIndex) => {

        tasks.push(new Promise(async (resolve, reject) => {

            try {

                artist.rankCounter = dataIndex + 1

                let myNewElement = await renderArtistInfo(artist, myTemplate)

                myElements[dataIndex] = myNewElement

                resolve()

            } catch (error) {

                logError('', error)

                reject(error)

            }

        }))

    })

    // Let's execute our rendering
    const result = await Promise.all(tasks)

    // Add new content
    myElements.forEach((myNewElement) => {

        mySourceContainer.appendChild(myNewElement)

    })

}


// Render Latest Purchase Token 
const renderLatestPurchaseToken = async (purchasesData, sourceContainer = null, resetContainer = true) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cCollectorLatestPurchasesContainer, resetContainer)

    if (mySourceContainer === null) return false

    // If not purchases data ( ? )
    if (purchasesData === null || purchasesData.length === 0) {

        await showElement(cCollectorLatestPurchasesNoneContainer)
        await hideElement(cCollectorLatestPurchasesContainer)

    } else {

        await hideElement(cCollectorLatestPurchasesNoneContainer)

        const cTemplateTokenInfo = getTemplateNameTokenInfo()

        // Find the token template 
        const myTokenTemplate = await findElementByID(cTemplateTokenInfo)

        if (!myTokenTemplate) {

            logError('Cannot find template ', cTemplateTokenInfo)

            return
        }

        const myPurchasesData = purchasesData

        // Generate content
        myPurchasesData.forEach(async (purchaseData, index) => {

            let myNewElement = await renderTokenInfo(purchaseData, myTokenTemplate, cTokenInfoOptions_ARTIST)

            mySourceContainer.appendChild(myNewElement)

        })

        await showElement(cCollectorLatestPurchasesContainer)
    }

}

// Render Purchases History
const renderPurchasesHistory = async (purchasesData, top = cTopAll, sourceContainer = null, resetContainer = true, isModal = false) => {


    // If we have data
    if (purchasesData) {

        // Find the data source container
        let mySourceContainer = await findSourceContainer(sourceContainer, cCollectorPurchasesHistoryDataContainer, resetContainer)

        if (mySourceContainer === null) return false

        // Find the template 
        const myTemplate = await findElementByID(cTemplatePurchasesHistoryTable)

        if (!myTemplate) {

            logError('Cannot find template ', cTemplatePurchasesHistoryTable)

            return
        }

        // Adjust data for requested top
        const myPurchasesData = purchasesData.slice(0, top)

        //let myTopIndicatorText = ''
        let myTopCountText = myPurchasesData.length.toString()

        if (purchasesData.length > myPurchasesData.length) {

            myTopCountText = myTopCountText + ' of ' + purchasesData.length.toString()

        }

        await setElementTextDirect(modalizeID(cCollectorPurchasesHistoryTopCount, isModal), '( ' + myTopCountText + ' )')

        if (!isModal) {

            if (purchasesData.length === myPurchasesData.length) {

                await hideElement(cCollectorButtonPurchasesHistoryAll)

            } else {

                await showElement(cCollectorButtonPurchasesHistoryAll)
            }

        } else {

            await hideElement(modalizeID(cCollectorButtonPurchasesHistoryAll, isModal))
        }


        let myElements = new Array(myPurchasesData.length)

        // Note SDS: we use tasks to maintain the order of collections as otherwise due to async the order changes
        let tasks = new Array()

        let myTotalPurchasesPriceVET = 0
        let myTotalPurchasesPriceWOV = 0
        let myTotalPurchasesPriceAlt = 0

        // Generate content
        myPurchasesData.forEach((data, dataIndex) => {

            tasks.push(new Promise(async (resolve, reject) => {

                try {

                    let myNewElement = myTemplate.content.firstElementChild.cloneNode(true);


                    let myRankCounter = dataIndex + 1

                    let myRank = await getElementFromTemplate(myNewElement, 'rank')
                    await setElementDynamic('', 'innerText', myRankCounter, myRank)


                    let myThumbNailUrl = data.media ? data.media.url : null
                    let myThumbNailIsVideo = data.isVideo

                    if (myThumbNailUrl === null && !myThumbNailIsVideo) {

                        myThumbNailUrl = getDefaultThumbNailImage()
                        myThumbNailIsVideo = false

                    }

                    let myThumbImage = await getElementFromTemplate(myNewElement, 'img', false)

                    let myThumb = await getElementFromTemplate(myNewElement, 'thumb-img')

                    let myVideo = await getElementFromTemplate(myNewElement, 'thumb-vid')

                    if (!myThumbNailIsVideo) {

                        await setElementImageSource('', myThumbNailUrl, myThumbImage)

                        await hideElement('', myVideo)

                    } else {

                        await setElementDynamic('', 'src', myThumbNailUrl, myVideo)

                        await hideElement('', myThumb)

                    }


                    const myName = (data.name ? data.name : data.tokenEditionID)

                    // Token Url
                    let myTokenUrl = data.tokenUrl

                    if (!myTokenUrl && data.tokenWOVID) {

                        myTokenUrl = WovEnv.url_marketplace_token + data.smartContractAddress + '/' + data.tokenWOVID
                    }


                    //await setElementDynamic('', 'href', myTokenUrl, myThumbUrl)
                    //await setElementDynamic('', 'title', myName, myThumbUrl)

                    let myThumbUrl = await getElementFromTemplate(myNewElement, 'thumb-link')
                    await setElementData('', 'token-id', data.tokenEditionID, myThumbUrl)
                    await setElementDynamic('', 'title', 'Show Token Info', myThumbUrl)

                    myThumbUrl.addEventListener('click', function handleClick(event) {
                        window.wov_render_token_info(event.currentTarget);
                    }, false);


                    let myEdition = await getElementFromTemplate(myNewElement, 'edition')
                    await setElementTextDirect('', getTokenEditionfromTokenID(data.tokenEditionID), myEdition)

                    let myArtist = await getElementFromTemplate(myNewElement, 'artist')
                    await setElementText('', data.creator, 'name', myArtist)

                    let myPricePurchases = await getElementFromTemplate(myNewElement, 'price')
                    await setElementTextDirect('', data.priceStr, myPricePurchases)

                    let myPricePurchasesType = await getElementFromTemplate(myNewElement, 'priceType')
                    await setElementTextDirect('', data.paymentType, myPricePurchasesType)

                    let myPriceAlt = await getElementFromTemplate(myNewElement, 'priceAlt')
                    let myPriceVET = data.price
                    let myPriceVETStr = data.priceStr

                    if (data.isPurchaseVET) {

                        myTotalPurchasesPriceVET = myTotalPurchasesPriceVET + data.price

                    } else {

                        myTotalPurchasesPriceWOV = myTotalPurchasesPriceWOV + data.price

                        myPriceVET = data.priceAlt
                        myPriceVETStr = data.priceAltStr

                    }

                    await setElementTextDirect('', myPriceVETStr, myPriceAlt)

                    let myDateValueElement = await getElementFromTemplate(myNewElement, 'date')
                    await setElementTextDirect('', getLocalFormattedDate(data.blockDate, true), myDateValueElement)
                    await setElementDynamic('', 'title', getLocalFormattedDate(data.blockDate, false), myDateValueElement)

                    myTotalPurchasesPriceAlt = myTotalPurchasesPriceAlt + myPriceVET

                    // Set our new element
                    myElements[dataIndex] = myNewElement

                    resolve()

                } catch (error) {

                    logError('', error)

                    reject(error)

                }

            }))

        })

        // Let's execute our rendering
        const result = await Promise.all(tasks)

        // Add new content
        myElements.forEach((myNewElement) => {

            mySourceContainer.appendChild(myNewElement)

        })

        // Total sales prices

        let myTotalPurchasesPriceVETElement = await findElementByID(modalizeID('php-total-vet-price', isModal))
        if (myTotalPurchasesPriceVETElement) {

            if (myTotalPurchasesPriceVET !== 0) {

                await setElementTextDirect('', getFormattedLocalNumber(myTotalPurchasesPriceVET), myTotalPurchasesPriceVETElement)
                await showElement('', myTotalPurchasesPriceVETElement)

            } else {

                await hideElement('', myTotalPurchasesPriceVETElement)
            }

        }

        let myTotalPurchasesPriceWOVElement = await findElementByID(modalizeID('php-total-wov-price', isModal))
        if (myTotalPurchasesPriceWOVElement) {

            if (myTotalPurchasesPriceWOV !== 0) {

                await setElementTextDirect('', getFormattedLocalNumber(myTotalPurchasesPriceWOV), myTotalPurchasesPriceWOVElement)
                await showElement('', myTotalPurchasesPriceWOVElement)

            } else {

                await hideElement('', myTotalPurchasesPriceWOVElement)
            }

        }

        let myTotalPurchasesPriceAltElement = await findElementByID(modalizeID('php-total-alt-price', isModal))
        if (myTotalPurchasesPriceAlt) {

            await setElementTextDirect('', getFormattedLocalNumber(myTotalPurchasesPriceAlt) + " VET", myTotalPurchasesPriceAltElement)

        }


        await showElement(cCollectorPurchasesHistoryContainer)


    } else {

        await hideElement(cCollectorPurchasesHistoryContainer)
        await hideElement(cCollectorButtonPurchasesHistoryAll)
    }

}

// Render Total Purchases Quick Info
const renderTotalPurchasesQuickInfo = async (data) => {

    // Top Collector Marketplace
    if (data.isTopCollector) {

        await showElement(cCollectorTopMarketPlaceContainer)

    } else {

        await hideElement(cCollectorTopMarketPlaceContainer)
    }

    // Purchases
    await setElementTextDirect('tpo-price-vet', getFormattedLocalNumber(data.purchasesVETValue))
    await setElementTextDirect('tpo-price-wov', getFormattedLocalNumber(data.purchasesWOVValue))
    await setElementTextDirect('tpo-price-wovvet', getFormattedLocalNumber(data.purchasesWOVVETValue))
    await setElementTextDirect('tpo-price-totalvet', getFormattedLocalNumber(data.purchasesVETValue + data.purchasesWOVVETValue))
}



// Render Total Purchases Quick Info
const renderCollectorSummaryQuickInfo = async (data) => {

    // Editions
    await setElementTextDirect('tcs-edi-all', getFormattedLocalNumber(data.editionsCount))
    await setElementTextDirect('tcs-edi-single', getFormattedLocalNumber(data.singleEditionsCount))
    await setElementTextDirect('tcs-edi-missing', getFormattedLocalNumber(data.editionsTotalMissingCount))
    await setElementTextDirect('tcs-edi-gifted', getFormattedLocalNumber(data.giftedCount))


    // Artists
    await setElementTextDirect('tcs-art-all', getFormattedLocalNumber(data.artistsCount))
    await setElementTextDirect('tcs-art-compl', getFormattedLocalNumber(data.artistsCompletedCount))
    await setElementTextDirect('tcs-art-missing', getFormattedLocalNumber(data.artistsMissingCount))
    await setElementTextDirect('tcs-art-top', getFormattedLocalNumber(data.artistsTopCount))
}

// Render Missing Artists
const renderMissingArtists = async (artists, count = 1, sourceContainer = null, resetContainer = true, isModal = false) => {

    // Make sure we have data
    if (!artists) { return }

    // Find the data source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cCollectorArtistMissing1Container, resetContainer)

    if (mySourceContainer === null) return false

    const cTemplateTokenInfo = getTemplateNameTokenInfo()

    // Find the token template 
    const myTokenTemplate = await findElementByID(cTemplateTokenInfo)

    if (!myTokenTemplate) {

        logError('Cannot find template ', cTemplateTokenInfo)

        return
    }

    const myArtistsData = artists

    // Artist tokens
    let myArtistsTokens = new Array()

    // Generate content
    myArtistsData.forEach((artist) => {

        let isAdded = false
        let myCountDown = artist.creations_missing.length

        do {

            --myCountDown

            let myToken = getRandomElementFromData(artist.creations_missing.filter(el => Number.parseInt(el.onSale) !== 0))

            if (myArtistsTokens.findIndex(el => el.tokenWOVID === myToken.tokenWOVID) === -1) {

                myArtistsTokens.push(myToken)

                isAdded = true
            }

        } while (!isAdded && myCountDown > 0)

    })

    // Render the tokens
    myArtistsTokens.forEach(async (data) => {

        let myNewElement = await renderTokenInfo(data, myTokenTemplate, cTokenInfoOptions_IMAGE_ONLY)

        mySourceContainer.appendChild(wrapContentAsColumn(myNewElement))
    })

}

const resetMissingArtists = async (sourceContainer = null) => {

    // Find the data source container
    await findSourceContainer(sourceContainer, cCollectorArtistMissing1Container, true)

}

// Render Missing Artists - Last on Sale
const renderMissingArtistsLastOnSale = async (artists, count = 1, sourceContainer = null, resetContainer = true, isModal = false) => {

    // Find the data source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cCollectorArtistMissingLast1Container, resetContainer)

    if (mySourceContainer === null) return false

    await renderMissingArtists(artists, count, mySourceContainer, resetContainer, isModal)

}

const resetMissingArtistsLastOnSale = async (sourceContainer = null) => {

    // Find the data source container
    await findSourceContainer(sourceContainer, cCollectorArtistMissingLast1Container, true)

}

// Render Missing Artists - Single on Sale
const renderMissingArtistsSingleOnSale = async (artists, count = 1, sourceContainer = null, resetContainer = true, isModal = false) => {

    // Make sure we have data
    if (artists !== null && artists.length !== 0) {

        //await showElement(cCollectorArtistMissingSingle1Container)

        // Find the data source container
        let mySourceContainer = await findSourceContainer(sourceContainer, cCollectorArtistMissingSingle1ContentContainer, resetContainer)

        if (mySourceContainer === null) return false

        await renderMissingArtists(artists, count, mySourceContainer, resetContainer, isModal)

    } else {

        await hideElement(cCollectorArtistMissingSingle1Container)
    }



}

const resetMissingArtistsSingleOnSale = async (sourceContainer = null) => {

    // Find the data source container
    await findSourceContainer(sourceContainer, cCollectorArtistMissingSingle1ContentContainer, true)

}

//* GRAPHS

const renderTopArtistsGraph = async (artists, top = cTopAll, sortedByEditions = true, addGraphIcons = true, sourceContainer = null, resetContainer = true) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cCollectorTopArtistsGraphContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Source Data
    const sourceData = artists.slice(0, top)

    // Build graph data
    let myGraphDataRows = new Array()

    if (sortedByEditions) {

        sourceData.forEach(data => {

            const { name, editionsCollected, ...rest } = data

            let myData = [name, editionsCollected]

            myGraphDataRows.push(myData)
        })
    }

    else {

        sourceData.forEach(data => {

            const { name, purchasesVETTotalValue, ...rest } = data

            let myData = [name, purchasesVETTotalValue]

            myGraphDataRows.push(myData)
        })

    }

    // Check we have data
    if (myGraphDataRows.length === 0) return

    // Create our data table
    var graphDataTable = new google.visualization.DataTable()
    graphDataTable.addColumn('string', 'Artist')

    if (sortedByEditions) {

        graphDataTable.addColumn('number', 'Editions')

    } else {

        graphDataTable.addColumn('number', 'Purchased VET')

    }

    graphDataTable.addRows(myGraphDataRows)

    const options = {
        title: 'Top Artists by ' + (sortedByEditions ? 'Collected Editions' : 'Purchased (in VET)'),
        legend: { position: 'bottom', alignment: 'center' },
        pieHole: 0.4,
        colors: ['#ffaf4a', '#afd63b', '#00e89c', '#00e8ff', '#ab8bd7']  // Softs
    };

    const pie_chart = new GoogleCharts.api.visualization.PieChart(mySourceContainer)

    if (addGraphIcons) {

        google.visualization.events.addListener(pie_chart, 'ready', async () => {

            await renderGraphIcons(mySourceContainer.id, pie_chart)

        });

    }

    // Render
    pie_chart.draw(graphDataTable, options);

    return true

}

const showTopArtistsGraph = async () => {

    await showElement(cCollectorTopArtistsGraphContainer)

    return true
}

const hideTopArtistsGraph = async () => {

    await hideElement(cCollectorTopArtistsGraphContainer)

    return true
}

const renderArtistsAllGraph = async (artists, top = cTopAll, addGraphIcons = true, sourceContainer = null) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cCollectorArtistsGraphContainer, false)

    if (mySourceContainer === null) return false

    // Source Data
    const sourceData = artists.slice(0, top)

    // Build new data
    let myDashboardArtistsAllData = new Array()

    sourceData.forEach(data => {

        const { name, editionsCollected, ...rest } = data

        let myData = [name, editionsCollected]

        myDashboardArtistsAllData.push(myData)
    })

    // Create our data table.
    var dataArtistsAll = new google.visualization.DataTable();
    dataArtistsAll.addColumn('string', 'Artist');
    dataArtistsAll.addColumn('number', 'Editions');
    dataArtistsAll.addRows(myDashboardArtistsAllData)

    // Create a dashboard.
    var dashboardArtistsAll = new google.visualization.Dashboard(mySourceContainer);

    // Create a range slider, passing some options
    var donutRangeSlider = new google.visualization.ControlWrapper({
        'controlType': 'NumberRangeFilter',
        'containerId': 'filter_div',
        'options': {
            'filterColumnLabel': 'Editions'
        }
    })

    // Create a pie chart, passing some options
    var pie_chartWrapper = new google.visualization.ChartWrapper({
        'chartType': 'PieChart',
        'containerId': 'chart_div',
        'options': {
            'pieSliceText': 'value',
            'legend': 'right'
        }
    })

    // Establish dependencies, declaring that 'filter' drives 'pieChart',
    // so that the pie chart will only display entries that are let through
    // given the chosen slider range.
    dashboardArtistsAll.bind(donutRangeSlider, pie_chartWrapper)

    if (addGraphIcons) {

        //TODO how to get chart from wrapper ?
        google.visualization.events.addListener(pie_chartWrapper, 'ready', async () => {

            await renderGraphIcons(mySourceContainer.id, pie_chartWrapper.getChart())

        });

    }

    // Draw the dashboard.
    dashboardArtistsAll.draw(dataArtistsAll)

    return true

}

const renderCollectionsCompletionGraph = async (collections, addGraphIcons = true, sourceContainer = null, resetContainer = true) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cCollectorCollectionsCompletionsGraphContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Source Data
    let sourceData = collections

    // Build graph data
    let myGraphDataRows = new Array()

    sourceData.forEach(data => {

        const { name, collectedCount, missingCount, ...rest } = data

        let myData = [name, collectedCount, missingCount, '']

        myGraphDataRows.push(myData)
    })

    // Check we have data
    if (myGraphDataRows.length === 0) return

    // Create our data table
    var graphDataTable = new google.visualization.DataTable()
    graphDataTable.addColumn('string', 'Collection')
    graphDataTable.addColumn('number', 'Collected')
    graphDataTable.addColumn('number', 'Missing')
    graphDataTable.addColumn({ role: 'annotation' })
    graphDataTable.addRows(myGraphDataRows)

    const options = {
        title: 'Collections Completion',
        legend: { position: 'bottom', alignment: 'center' },
        bar: { groupWidth: "95%" },
        isStacked: true
    };

    // Render     
    const bar_chart = new GoogleCharts.api.visualization.BarChart(mySourceContainer)

    if (addGraphIcons) {

        google.visualization.events.addListener(bar_chart, 'ready', async () => {

            await renderGraphIcons(mySourceContainer.id, bar_chart)

        });

    }

    bar_chart.draw(graphDataTable, options);

    return true
}

// Renders purchases graph by date grouping - currently default by month
const renderPurchasesByDateGraph = async (purchases, dataByCount = true, displayMonths = 3, sourceContainer = null, addGraphIcons = true, resetContainer = true) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, (dataByCount ? cCollectorPurchasesAllCountGraphContainer : cCollectorPurchasesAllVETGraphContainer), resetContainer)

    if (mySourceContainer === null) return false

    // Source Data
    let sourceData = purchases

    // Build graph data
    let myGraphDataRows = new Array()

    if (dataByCount) {

        sourceData.forEach(data => {

            const { mostRecentPurchaseDate, name, ...rest } = data

            let myData = [mostRecentPurchaseDate, name]

            myGraphDataRows.push(myData)
        })

    } else {

        sourceData.forEach(data => {

            const { mostRecentPurchaseDate, priceVET, ...rest } = data

            let myData = [mostRecentPurchaseDate, priceVET]

            myGraphDataRows.push(myData)
        })

    }



    // Check we have data
    if (myGraphDataRows.length === 0) return



    // Create our data table
    var graphDataTable = new google.visualization.DataTable()
    graphDataTable.addColumn('date', 'Month')

    if (dataByCount) {

        graphDataTable.addColumn('string', 'Purchases')

    } else {

        graphDataTable.addColumn('number', 'Spend VET')
    }

    graphDataTable.addRows(myGraphDataRows)

    let myAggregatorFunction = null

    if (dataByCount) {

        myAggregatorFunction = google.visualization.data.count

    } else {

        myAggregatorFunction = google.visualization.data.sum

    }

    // Data View
    var graphDataView = new google.visualization.DataView(graphDataTable);

    var datePattern = 'yyyy-MM';
    var formatDate = new google.visualization.DateFormat({ pattern: datePattern });

    graphDataView.setColumns([

        // date column
        {
            calc: function (dt, row) {
                var dateValue = new Date(dt.getValue(row, 0));
                return {
                    v: dateValue,
                    f: formatDate.formatValue(dateValue)
                }
            },
            type: 'date',
            label: graphDataTable.getColumnLabel(0)
        },

        // 
        1
    ]);

    // Check we show last X months only

    // Determine months we have
    let myWorkDates = [];

    myGraphDataRows.forEach(dataRow => {

        const myDataDate = dataRow[0];
        const myDataDateID = myDataDate.getFullYear().toString() + '-' + myDataDate.getMonth().toString();

        if (!myWorkDates.find(el => el.dateID === myDataDateID)) {

            myWorkDates.push({ dateID: myDataDateID });
        }
    });

    // Get the last wanted month period

    var lastDateIndex = myWorkDates.length > displayMonths ? displayMonths : myWorkDates.length;

    const lastDateID = myWorkDates[lastDateIndex - 1].dateID;

    // Determine our last display month
    var lastDisplayDate = new Date(lastDateID.substring(0, 4), lastDateID.substring(5), 1);

    if (!lastDisplayDate) {
        today = Date.now();
        lastDisplayDate = new Date(today.getFullYear(), today.getMonth() - displayMonths, 1);
    }

    graphDataView.setRows(
        graphDataView.getFilteredRows([{
            column: 0,
            minValue: lastDisplayDate
        }]));

    // Create our group
    var groupedGraphDataTable =
        google.visualization.data.group(graphDataView,
            [{ column: 0, modifier: getLocalFormattedDateAsMonth, type: 'string', label: 'Month' }],
            [{ column: 1, aggregation: myAggregatorFunction, type: 'number', label: (dataByCount ? 'Editions' : 'Spend VET') }])

    groupedGraphDataTable.sort([0])

    const options = {
        title: 'Purchases - ' + (dataByCount ? 'Editions' : 'Spend VET') + ' - by Month',
        legend: { position: 'bottom', alignment: 'center' },
        colors: (dataByCount ? ['#8db419'] : ['#3c50c2']),  // Green, orange
        bar: { groupWidth: "95%" }
    };

    // Render     
    const bar_chart = new GoogleCharts.api.visualization.ColumnChart(mySourceContainer)

    if (addGraphIcons) {

        google.visualization.events.addListener(bar_chart, 'ready', async () => {

            await renderGraphIcons(mySourceContainer.id, bar_chart)

        });

    }

    bar_chart.draw(groupedGraphDataTable, options);

    return true
}

const renderCollectionEditionsTypesGraph = async (data, sourceContainer = null, addGraphIcons = true, resetContainer = true, title = 'Editions by Type', width = null, height = null) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cCollectorCollectionTypesGraphContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Build graph data
    let myGraphDataRows = new Array()

    let myNormalCount = data.editionsCount - data.singleEditionsCount - data.giftedCount

    if (myNormalCount < 0) {

        myNormalCount = 0
    }

    myGraphDataRows.push(['Normal', myNormalCount])
    myGraphDataRows.push(['Single', data.singleEditionsCount])
    myGraphDataRows.push(['Gifted', data.giftedCount])


    // Create our data table
    var graphDataTable = new google.visualization.DataTable()
    graphDataTable.addColumn('string', 'Type')
    graphDataTable.addColumn('number', 'Items')
    graphDataTable.addRows(myGraphDataRows)

    const options = {
        title: title,
        width: width,
        height: height,
        legend: { position: 'bottom', alignment: 'center' },
        colors: ['#ffaf4a', '#afd63b', '#00e89c', '#00e8ff', '#ab8bd7']  // Softs
    };

    // Render     
    const pie_chart = new GoogleCharts.api.visualization.PieChart(mySourceContainer)

    if (addGraphIcons) {

        google.visualization.events.addListener(pie_chart, 'ready', async () => {

            await renderGraphIcons(mySourceContainer.id, pie_chart)

        });

    }

    pie_chart.draw(graphDataTable, options);

    return true
}

const renderPurchasesPricesValueGraph = async (data, sourceContainer = null, addGraphIcons = true, resetContainer = true, title = 'Purchases Value VET vs WOV', width = null, height = null) => {

    // Find the source container
    let mySourceContainer = await findSourceContainer(sourceContainer, cCollectorCollectionPurchasesValuesGraphContainer, resetContainer)

    if (mySourceContainer === null) return false

    // Source Data    
    let vetData = data.purchasesVETValue
    let wovData = data.purchasesWOVVETValue

    // Build graph data
    let myGraphDataRows = new Array()

    myGraphDataRows.push(['VET', vetData])
    myGraphDataRows.push(['WOV', wovData])

    // Check we have data
    if (myGraphDataRows.length === 0) return

    // Create our data table
    var graphDataTable = new google.visualization.DataTable()
    graphDataTable.addColumn('string', 'Payment Type')
    graphDataTable.addColumn('number', 'VET Value')
    graphDataTable.addRows(myGraphDataRows)

    const options = {
        title: title,
        width: width,
        height: height,
        legend: { position: 'bottom', alignment: 'center' },
        colors: ['#3c50c2', '#4f1fa6']  // Blues
    };

    // Render     
    const pie_chart = new GoogleCharts.api.visualization.PieChart(mySourceContainer)

    if (addGraphIcons) {

        google.visualization.events.addListener(pie_chart, 'ready', async () => {

            await renderGraphIcons(mySourceContainer.id, pie_chart)

        });

    }

    pie_chart.draw(graphDataTable, options);

    return true
}

export {

    isCollectorPage,
    showCollectorContent,
    showCollectorNoContent,
    resetCollectorContent,
    showCollectorLoader,
    showCollectorArtTopLoader,
    showCollectorCollectionLoader,
    hideCollectorLoader,
    hideCollectorArtTopLoader,
    hideCollectorCollectionLoader,
    renderCollections,
    renderTopArtists,
    renderLatestPurchaseToken,
    renderPurchasesHistory,
    renderTotalPurchasesQuickInfo,
    renderCollectorSummaryQuickInfo,
    renderMissingArtists,
    resetMissingArtists,
    renderMissingArtistsLastOnSale,
    resetMissingArtistsLastOnSale,
    renderMissingArtistsSingleOnSale,
    resetMissingArtistsSingleOnSale,
    // Graphs
    renderTopArtistsGraph,
    showTopArtistsGraph,
    hideTopArtistsGraph,
    renderCollectionsCompletionGraph,
    renderArtistsAllGraph,
    renderPurchasesByDateGraph,
    renderCollectionEditionsTypesGraph,
    renderPurchasesPricesValueGraph
}