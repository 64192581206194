const cAccountTypes = Object.freeze({
    Artist: Symbol('A'),
    Collector: Symbol('C'),
    Token: Symbol('TI'),
    Other: Symbol('Z'),
    Test: Symbol('T'),
    _Base: Symbol('_')
})

const cTokenDetailsTypes = Object.freeze({
    InitialMint: { ID: 'IM', description: 'Initial Minting by Artist', icon: 'fa-circle-plus', iconColor: 'is-primary' },
    InitialList: { ID: 'IL', description: 'Initial Listing by Artist', icon: 'fa-palette', iconColor: 'is-link' },
    ReListArtist: { ID: 'RLA', description: 'Relisting by Artist', icon: 'fa-palette', iconColor: 'is-link' },
    ReListCollector: { ID: 'RLC', description: 'Relisting by Collector', icon: 'fa-user-astronaut', iconColor: 'is-link' },
    SalesPrimary: { ID: 'SP', description: 'Primary Sales', icon: 'fa-piggy-bank', iconColor: 'is-success' },
    SalesSecondary: { ID: 'SS', description: 'Secondary Sales', icon: 'fa-piggy-bank', iconColor: 'is-success' },
    CancelListArtist: { ID: 'CLA', description: 'Cancel Listing by Artist', icon: 'fa-ban', iconColor: 'is-danger' },
    CancelListCollector: { ID: 'CLC', description: 'Cancel Listing by Collector', icon: 'fa-ban', iconColor: 'is-danger' },
    Transfer: { ID: 'TR', description: 'Transfer', icon: 'fa-arrow-right', iconColor: 'is-primary' },
    BurnArtist: { ID: 'BUA', description: 'Burned by Artist', icon: 'fa-fire', iconColor: 'is-danger' },
    BurnCollector: { ID: 'BUC', description: 'Burned by Collector', icon: 'fa-fire', iconColor: 'is-danger' },
    Unknown: { ID: '__', description: 'Unknown', icon: 'fa-cog' }
})

export {
    cAccountTypes,
    cTokenDetailsTypes,
}