
// Local
import {
    findElementByID, clearElement, hideElement, showElement, setElementText, setElementTextDirect, setElementImageSource,
    getElementFromTemplate, setElementDynamic, setElementData, findSourceContainer, setElementStyle, setElementClass
} from './utils/dom'
import { WovEnv } from '../wov/env'
import { logError, getFormattedLocalNumber, getLocalFormattedDate } from './utils/common'
import { cAccountTypes, cTokenDetailsTypes } from '../common/constants'

const cTemplateTokenInfoCard = 'template_token_info_card'
const cTemplateTokenInfoFull = 'template_token_info_full'
const cTemplateArtistInfo = 'template_artist_info'
const cTemplateGraphIcons = 'template_graph_icons'

const cElementIDWorkerText = 'acc-dm-info'
const cElementIDWorkerBox = 'acc-dm-bar'

const cClassPriceVET = 'is-vet'
const cClassPriceWOV = 'is-wov'

const cDefaultThumbNailImage = '/img/Wov-Tools Logo x400.jpg'

// Token Info Options
const cTokenInfoOptions_DEFAULT = {
    showCollector: true,
    showArtist: true,
    showBlockDate: true,
    showPrices: true
}

const cTokenInfoOptions_COLLECTOR = {
    showCollector: true,
    showArtist: false,
    showBlockDate: true,
    showPrices: true
}

const cTokenInfoOptions_ARTIST = {
    showCollector: false,
    showArtist: true,
    showBlockDate: true,
    showPrices: true
}

const cTokenInfoOptions_IMAGE_ONLY = {
    showCollector: false,
    showArtist: false,
    showBlockDate: false,
    showPrices: false
}

const cTokenInfoOptions_TOKEN_INFO = {
    showTokenInfo: true,
    showCollector: true,
    showArtist: true,
    showBlockDate: true,
    showPrices: true,
    showDescription: true,
    showCollection: true,
    showHistory: true,
    showSalesStatus: true,
    useThumbURLExternal: true
}

// Get Template Name Token Info
const getTemplateNameTokenInfo = (isCard = true) => {

    return (isCard ? cTemplateTokenInfoCard : cTemplateTokenInfoFull)
}

// Get Template Name Artist Info
const getTemplateNameArtistInfo = () => {

    return cTemplateArtistInfo
}

const getDefaultThumbNailImage = () => {

    return cDefaultThumbNailImage

}

const wrapContentAsColumn = (contentData, additionalClasses = "") => {

    let myColumn = document.createElement('div')
    myColumn.className = 'column' + ' ' + additionalClasses

    myColumn.appendChild(contentData)

    return myColumn
}

const getPriceChangeColorCss = (percentage) => {

    let myPriceChangeColorCss = 'is-primary'

    if (percentage > 50) {

        myPriceChangeColorCss = 'is-danger'

    } else if (percentage > 20) {

        myPriceChangeColorCss = 'is-warning'

    } else if (percentage < 0) {

        myPriceChangeColorCss = 'is-success'
    }

    return myPriceChangeColorCss
}

// Render Token Info
const renderTokenInfo = async (data, templateInstance, options = cTokenInfoOptions_DEFAULT, isModal = false) => {

    // Merge sep creation data into main
    if (data.creation) {

        data = { ...data, ...data.creation }
    }

    // Duplicate template node
    let myNewElement = templateInstance.content.firstElementChild.cloneNode(true)

    // Token Url
    let myTokenUrl = data.tokenUrl
    let myTokenID = (data.tokenEditionID ? data.tokenEditionID : (data.tokenWOWID ? data.tokenWOWID : ""))

    if (!myTokenUrl && myTokenID !== '') {

        myTokenUrl = WovEnv.url_marketplace_token + data.smartContractAddress + '/' + myTokenID
    }

    // Marketplace URL
    let myIconLinkMarketplaceUrl = await getElementFromTemplate(myNewElement, 'tok-icon-malink')
    await setElementDynamic('', 'href', myTokenUrl, myIconLinkMarketplaceUrl)
    await setElementDynamic('', 'title', 'Visit Marketplace', myIconLinkMarketplaceUrl)

    // Thumbnail URL
    let myIconLinkTokenInfo = await getElementFromTemplate(myNewElement, 'tok-icon-tilink')
    let myThumbUrl = await getElementFromTemplate(myNewElement, 'tok-thumb-link')

    if (options.useThumbURLExternal || myTokenID === '') {

        await setElementDynamic('', 'href', myTokenUrl, myThumbUrl)
        await setElementDynamic('', 'target', '_new', myThumbUrl)
        await setElementDynamic('', 'title', 'Visit Marketplace', myThumbUrl)

        await hideElement('', myIconLinkTokenInfo)

    } else {

        await setElementData('', 'token-id', myTokenID, myThumbUrl)
        await setElementDynamic('', 'title', 'Show Token Info', myThumbUrl)

        myThumbUrl.addEventListener('click', function handleClick(event) {
            window.wov_render_token_info(event.currentTarget);
        }, false);

        if (myIconLinkTokenInfo) {

            await setElementData('', 'token-id', myTokenID, myIconLinkTokenInfo)
            await setElementDynamic('', 'title', 'Show Token Info', myIconLinkTokenInfo)

            myIconLinkTokenInfo.addEventListener('click', function handleClick(event) {
                window.wov_render_token_info(event.currentTarget);
            }, false);

        }

    }

    // Thumbnail
    let myThumbImage = await getElementFromTemplate(myNewElement, 'img', false)

    let myThumb = await getElementFromTemplate(myNewElement, 'tok-thumb-img')

    let myVideo = await getElementFromTemplate(myNewElement, 'tok-thumb-vid')

    let myThumbNailUrl = data.media ? data.media.url : null
    let myThumbNailIsVideo = data.isVideo

    if (myThumbNailUrl === null && !myThumbNailIsVideo) {

        myThumbNailUrl = getDefaultThumbNailImage()
        myThumbNailIsVideo = false

    }

    if (!myThumbNailIsVideo) {

        await setElementImageSource('', myThumbNailUrl, myThumbImage)

        await hideElement('', myVideo)

    } else {

        await setElementDynamic('', 'src', myThumbNailUrl, myVideo)

        await hideElement('', myThumb)

    }

    // Item name
    let myNameElement = await getElementFromTemplate(myNewElement, 'tok-name')
    let myNameValue = data.name

    if (options.showTokenInfo) {

        myNameValue = myNameValue + ' - ' + (data.edition ? 'Edition' : 'Token') + (data.edition ? ' ' + data.edition.toString() : '')

    }

    await setElementTextDirect('', myNameValue, myNameElement)

    // Artist
    let myArtistElement = await getElementFromTemplate(myNewElement, 'tok-artist')

    if (options.showArtist && data.creator) {

        if (data.creator.name) {

            let myArtistName = await getElementFromTemplate(myNewElement, 'tok-artist-name')
            await setElementText('', data.creator, 'name', myArtistName)

        }

        await showElement('', myArtistElement)

    } else {

        await hideElement('', myArtistElement)
    }

    // Collector
    let myCollectorElement = await getElementFromTemplate(myNewElement, 'tok-collector')

    if (options.showCollector && data.collector) {

        if (data.collector.name) {

            let myCollectorNameElement = await getElementFromTemplate(myNewElement, 'tok-collector-name')
            await setElementText('', data.collector, 'name', myCollectorNameElement)

        }

        await showElement('', myCollectorElement)

    } else {

        await hideElement('', myCollectorElement)
    }

    // When 
    let myDateElement = await getElementFromTemplate(myNewElement, 'tok-date')

    //let myBlockDate = (data.blockDate ? data.blockDate : (data.createdAtBlockTimestamp ? data.createdAtBlockTimestamp : null))
    let myBlockDate = (data.blockDate ? data.blockDate : null)

    if (options.showBlockDate && myBlockDate) {

        let myDateValueElement = await getElementFromTemplate(myNewElement, 'tok-date-value')
        await setElementTextDirect('', getLocalFormattedDate(myBlockDate, true), myDateValueElement)
        await setElementDynamic('', 'title', getLocalFormattedDate(myBlockDate, false), myDateValueElement)

        await showElement('', myDateElement)

    } else {

        await hideElement('', myDateElement)
    }

    // Description
    let myDescrElement = await getElementFromTemplate(myNewElement, 'tok-description')

    if (options.showDescription && data.description) {

        await setElementTextDirect('', data.description, myDescrElement)

    } else {

        await hideElement('', myDescrElement)
    }

    // Collection
    let myCollectionElement = await getElementFromTemplate(myNewElement, 'tok-collection')

    if (options.showCollection && data.collection) {

        let myCollectionNameElement = await getElementFromTemplate(myNewElement, 'tok-collection-name')
        await setElementTextDirect('', data.collection.name, myCollectionNameElement)

    } else {

        await hideElement('', myCollectionElement)
    }

    // Edition
    let myEditionElement = await getElementFromTemplate(myNewElement, 'tok-edition')

    if (options.showTokenInfo && data.edition) {

        let myEditionIDElement = await getElementFromTemplate(myNewElement, 'tok-edition-id')
        await setElementTextDirect('', data.edition, myEditionIDElement)

        let myEditionMaxElement = await getElementFromTemplate(myNewElement, 'tok-edition-max')
        await setElementTextDirect('', data.editionsCount, myEditionMaxElement)

    } else {

        await hideElement('', myEditionElement)
    }

    // Token
    let myTokenElement = await getElementFromTemplate(myNewElement, 'tok-token')

    if (options.showTokenInfo && data.tokenWOVID) {

        let myTokenIDElement = await getElementFromTemplate(myNewElement, 'tok-token-id')
        await setElementTextDirect('', data.tokenWOVID, myTokenIDElement)

    } else {

        await hideElement('', myTokenElement)
    }

    // Sales Status
    let mySalesStatusElement = await getElementFromTemplate(myNewElement, 'tok-sales-type')

    if (options.showSalesStatus) {

        let mySalesStatusTypeElement = await getElementFromTemplate(myNewElement, 'tok-sales-type-value')

        if (data.isListingPrimary || data.isListingSecondary) {

            const mySalesType = (data.isListingPrimary ? 'On Primary' : 'On Secondary')
            await setElementTextDirect('', mySalesType, mySalesStatusTypeElement)

        } else {

            await setElementTextDirect('', 'No', mySalesStatusTypeElement)

        }

    } else {

        await hideElement('', mySalesStatusElement)
    }

    // Price
    let myPriceElement = await getElementFromTemplate(myNewElement, 'tok-price')
    let myPriceChangePrevElement = await getElementFromTemplate(myNewElement, 'tok-price-change-prev')
    let myPriceChangeArtElement = await getElementFromTemplate(myNewElement, 'tok-price-change-art')

    if (myPriceElement) {

        if (options.showPrices && data.priceStr) {

            let myPriceValueElement = await getElementFromTemplate(myNewElement, 'tok-price-value')

            if (myPriceValueElement) {

                const myPriceValue = data.priceStr + " " + (data.paymentType ? data.paymentType : '?')

                await setElementTextDirect('', myPriceValue, myPriceValueElement)

                if (data.isSalesVET || data.isPurchaseVET) {

                    await setElementClass('', cClassPriceVET, false, myPriceValueElement)

                } else {

                    await setElementClass('', cClassPriceWOV, false, myPriceValueElement)

                }

                await showElement('', myPriceElement)

                let myPriceMarketPlaceUrl = await getElementFromTemplate(myNewElement, 'tok-price-malink')
                await setElementDynamic('', 'href', myTokenUrl, myPriceMarketPlaceUrl)
                await setElementDynamic('', 'title', 'Visit Marketplace', myPriceMarketPlaceUrl)

            } else {

                await hideElement('', myPriceElement)

            }

            if (data.priceDiffPrevPerc || data.priceDiffArtistPerc) {

                let myPriceChangePreviousElement = await getElementFromTemplate(myNewElement, 'tok-change-prev-value')

                await setElementTextDirect('', `${(data.priceDiffPrevPerc > 0 ? '+' : '')} ${data.priceDiffPrevPerc} % `, myPriceChangePreviousElement)

                await setElementClass('', getPriceChangeColorCss(data.priceDiffPrevPerc), false, myPriceChangePreviousElement)

                let myPriceChangeArtistElement = await getElementFromTemplate(myNewElement, 'tok-change-art-value')

                await setElementTextDirect('', `${(data.priceDiffArtistPerc > 0 ? '+' : '')} ${data.priceDiffArtistPerc} % `, myPriceChangeArtistElement)

                await setElementClass('', getPriceChangeColorCss(data.priceDiffArtistPerc), false, myPriceChangeArtistElement)

                await showElement('', myPriceChangePrevElement)
                await showElement('', myPriceChangeArtElement)

            } else {

                await hideElement('', myPriceChangePrevElement)
                await hideElement('', myPriceChangeArtElement)
            }

        } else {

            await hideElement('', myPriceElement)
            await hideElement('', myPriceChangePrevElement)
            await hideElement('', myPriceChangeArtElement)

        }

    }

    // Token Info Url Copy
    let myCopyTokenInfoUrlElement = await getElementFromTemplate(myNewElement, 'tok-copy-tiurl')

    if (options.showTokenInfo && myCopyTokenInfoUrlElement && data.tokenEditionID) {

        let myTIUrl = WovEnv.url_nfttools_token_info + data.tokenEditionID

        await setElementData('', 'token-id-url', myTIUrl, myCopyTokenInfoUrlElement)

    } else {

        await hideElement('', myCopyTokenInfoUrlElement)
    }

    // Next / Prev Editions
    let myPrevEditionElement = await getElementFromTemplate(myNewElement, 'tok-icon-prevlink')
    let myNextEditionElement = await getElementFromTemplate(myNewElement, 'tok-icon-nextlink')

    if (!isModal && options.showTokenInfo) {

        let myTokenWOVID = Number.parseInt(data.tokenWOVID)
        let myPrevEdition = data.edition - 1
        let myNextEdition = data.edition + 1

        if (myPrevEdition >= 1) {

            let myPrevEditionID = myTokenWOVID + myPrevEdition



            //await setElementDynamic('', 'href', '/token/?id=' + myPrevEditionID.toString(), myPrevEditionElement)

            myPrevEditionElement.addEventListener('click', function handleClick(event) {
                window.token_info_lookup(myPrevEditionID);
            }, false);


        } else {

            await hideElement('', myPrevEditionElement)
        }

        if (myNextEdition <= data.editionsCount) {

            let myNextEditionID = myTokenWOVID + myNextEdition

            //await setElementDynamic('', 'href', WovEnv.url_nfttools_token_info + myNextEditionID.toString(), myNextEditionElement)
            myNextEditionElement.addEventListener('click', function handleClick(event) {
                window.token_info_lookup(myNextEditionID);
            }, false);



        } else {

            await hideElement('', myNextEditionElement)
        }

    } else {

        await hideElement('', myPrevEditionElement)
        await hideElement('', myNextEditionElement)

    }


    // Tabs

    let myTabsElement = await getElementFromTemplate(myNewElement, 'tok-tabs')
    let myHistoryTabElement = await getElementFromTemplate(myNewElement, 'ti-history')

    // Show History Details
    if (options.showHistory && data.history) {

        let myTokenHistoryElement = await getElementFromTemplate(myNewElement, 'ti-history-items')

        let myTokenHistoryItemTemplateInstance = await findElementByID("token-info-timeline-item")

        if (myTokenHistoryElement && myTokenHistoryItemTemplateInstance) {

            let newDetailsElements = new Array(data.history.length)
            let tasks = new Array()


            data.history.forEach(async (detail, detailIndex) => {

                tasks.push(new Promise(async (resolve, reject) => {

                    try {

                        let myTokenHistoryItemNewElement = myTokenHistoryItemTemplateInstance.content.firstElementChild.cloneNode(true)

                        let myTypeIconElement = await getElementFromTemplate(myTokenHistoryItemNewElement, 'tl-m-icon')
                        let myTypeIconValueElement = await getElementFromTemplate(myTokenHistoryItemNewElement, 'tl-m-icon-value')

                        await setElementClass('', detail.type.icon, false, myTypeIconValueElement)

                        if (detail.type.iconColor) {

                            await setElementClass('', detail.type.iconColor, false, myTypeIconElement)
                        }

                        let myTypeElement = await getElementFromTemplate(myTokenHistoryItemNewElement, 'tl-c-type')

                        await setElementTextDirect('', detail.type.description, myTypeElement)

                        let myDateElement = await getElementFromTemplate(myTokenHistoryItemNewElement, 'tl-c-date')

                        await setElementTextDirect('', `${detail.dateStr}`, myDateElement)

                        let showData = false

                        let myDataElement = await getElementFromTemplate(myTokenHistoryItemNewElement, 'tl-c-data')

                        if (myDataElement) {

                            if (detail.type !== cTokenDetailsTypes.CancelListArtist
                                && detail.type !== cTokenDetailsTypes.CancelListCollector) {

                                showData = true

                                // Collector
                                let myCollectorElement = await getElementFromTemplate(myTokenHistoryItemNewElement, 'tl-col')

                                if (detail.type === cTokenDetailsTypes.ReListCollector
                                    || detail.type === cTokenDetailsTypes.SalesPrimary
                                    || detail.type === cTokenDetailsTypes.SalesSecondary) {

                                    let myCollectorNameElement = await getElementFromTemplate(myTokenHistoryItemNewElement, 'tl-col-name')

                                    await setElementTextDirect('', detail.collector.name, myCollectorNameElement)

                                    await showElement('', myCollectorElement)

                                } else {

                                    await hideElement('', myCollectorElement)
                                }

                                // Price & Changes
                                if (detail.price && detail.price != 0) {

                                    let myPriceTagElement = await getElementFromTemplate(myTokenHistoryItemNewElement, 'tl-price-tag')

                                    let myPriceTagValue = ''

                                    if (detail.type === cTokenDetailsTypes.SalesPrimary
                                        || detail.type === cTokenDetailsTypes.SalesSecondary) {

                                        myPriceTagValue = 'Sales'

                                    } else if (detail.type === cTokenDetailsTypes.InitialList) {

                                        myPriceTagValue = 'Initial'

                                    } else if (detail.type === cTokenDetailsTypes.ReListCollector) {

                                        myPriceTagValue = 'New'
                                    }

                                    await setElementTextDirect('', myPriceTagValue, myPriceTagElement)

                                    let myPriceValueElement = await getElementFromTemplate(myTokenHistoryItemNewElement, 'tl-price-value')
                                    let myPriceChangePrevElement = await getElementFromTemplate(myTokenHistoryItemNewElement, 'tl-change-prev')
                                    let myPriceChangeArtElement = await getElementFromTemplate(myTokenHistoryItemNewElement, 'tl-change-art')

                                    if (myPriceValueElement) {

                                        const myPriceValue = detail.priceStr + " " + (detail.paymentType ? detail.paymentType : '?')

                                        await setElementTextDirect('', myPriceValue, myPriceValueElement)

                                        if (detail.isSalesVET || detail.isPurchaseVET) {

                                            await setElementClass('', cClassPriceVET, false, myPriceValueElement)

                                        } else {

                                            await setElementClass('', cClassPriceWOV, false, myPriceValueElement)

                                        }

                                        if (detail.type === cTokenDetailsTypes.ReListCollector
                                            || detail.type === cTokenDetailsTypes.ReListArtist) {

                                            let myPriceChangePreviousElement = await getElementFromTemplate(myTokenHistoryItemNewElement, 'tl-change-prev-value')

                                            await setElementTextDirect('', `${(detail.priceDiffPrevPerc > 0 ? '+' : '')} ${detail.priceDiffPrevPerc} % `, myPriceChangePreviousElement)

                                            await setElementClass('', getPriceChangeColorCss(detail.priceDiffPrevPerc), false, myPriceChangePreviousElement)

                                            let myPriceChangeArtistElement = await getElementFromTemplate(myTokenHistoryItemNewElement, 'tl-change-art-value')

                                            await setElementTextDirect('', `${(detail.priceDiffArtistPerc > 0 ? '+' : '')} ${detail.priceDiffArtistPerc} % `, myPriceChangeArtistElement)

                                            await setElementClass('', getPriceChangeColorCss(detail.priceDiffArtistPerc), false, myPriceChangeArtistElement)

                                            await showElement('', myPriceChangePrevElement)
                                            await showElement('', myPriceChangeArtElement)

                                        } else {

                                            await hideElement('', myPriceChangePrevElement)
                                            await hideElement('', myPriceChangeArtElement)
                                        }

                                    } else {

                                        await hideElement('', myPriceValueElement)
                                        await hideElement('', myPriceChangePrevElement)
                                        await hideElement('', myPriceChangeArtElement)
                                    }

                                } else {


                                }

                            }

                        }

                        if (showData) {

                            await showElement('', myDataElement)

                        } else {

                            await hideElement('', myDataElement)
                        }

                        // Store element
                        newDetailsElements[detailIndex] = myTokenHistoryItemNewElement

                        resolve()

                    } catch (error) {

                        logError('', error)

                        reject(error)

                    }

                }))

            })

            // Let's execute our rendering
            const result = await Promise.all(tasks)

            // Add the new elements
            newDetailsElements.forEach((element) => {
                myTokenHistoryElement.append(element)
            })

        }

    } else {

        await hideElement('', myHistoryTabElement)

        // For now we hide the tab as history is only tab
        await hideElement('', myTabsElement)
    }

    return myNewElement

}


// Artist Info Options
const cArtistInfoOptions_DEFAULT = {
    useShortInfo: false,
    showRanking: true,
    showItemsCollected: true,
    showMissingItems: true,
    showMostRecentPurchaseDate: true,
    showPrices: true,
    showLatestPurchaseToken: false
}

// Render Artist Info
const renderArtistInfo = async (artist, templateInstance, options = cArtistInfoOptions_DEFAULT) => {

    // Duplicate template node
    let myNewElement = templateInstance.content.firstElementChild.cloneNode(true)

    let myRank = await getElementFromTemplate(myNewElement, 'art-rank')

    if (options.showRanking) {

        await setElementText('', artist, 'rankCounter', myRank)

    } else {

        await hideElement('', myRank)
    }

    let myThumb = await getElementFromTemplate(myNewElement, 'img', false)
    await setElementImageSource('', artist.profileImageUrl, myThumb)

    let myThumbUrl = await getElementFromTemplate(myNewElement, 'art-thumb-link')
    await setElementDynamic('', 'href', artist.profileUrl, myThumbUrl)

    let myName = await getElementFromTemplate(myNewElement, 'art-name')
    await setElementText('', artist, 'name', myName)

    let myAddress = await getElementFromTemplate(myNewElement, 'art-address')
    await setElementData('', 'ID', artist.name + ' = ' + artist.address, myAddress)

    let myProfile = await getElementFromTemplate(myNewElement, 'art-profile')
    await setElementDynamic('', 'href', artist.profileUrl, myProfile)

    let myTopIndicator = await getElementFromTemplate(myNewElement, 'art-top-ind')

    if (artist.isTopArtist) {

        await showElement('', myTopIndicator)

    } else {

        await hideElement('', myTopIndicator)

    }

    let myTwitter = await getElementFromTemplate(myNewElement, 'art-twitter')

    if (artist.twitterUrl) {

        await setElementDynamic('', 'href', artist.twitterUrl, myTwitter)

    } else {

        await setElementDynamic('', 'style', 'visibility: hidden;', myTwitter)

    }

    let myItemsCollected = await getElementFromTemplate(myNewElement, 'art-collectedcount')

    let myItemsEditions = await getElementFromTemplate(myNewElement, 'art-editionscount')

    if (options.showItemsCollected) {

        await setElementText('', artist, 'itemsCollected', myItemsCollected)

        await setElementText('', artist, 'editionsCollected', myItemsEditions)

    } else {

        await hideElement('', myItemsCollected)
        await hideElement('', myItemsEditions)
    }

    let myMissingElement = await getElementFromTemplate(myNewElement, 'art-missing')

    if (myMissingElement && options.showMissingItems) {

        let myMissingValue = await getElementFromTemplate(myNewElement, 'art-missingcount')
        await setElementText('', artist, 'missingCount', myMissingValue)

        if (artist.missingCount !== 0) {

            await setElementClass('', 'is-warning', false, myMissingValue)

        } else {

            await setElementClass('', 'is-success', false, myMissingValue)

        }

        await showElement('', myMissingElement)

    } else {

        await hideElement('', myMissingElement)

    }


    let myPurchasesVETElement = await getElementFromTemplate(myNewElement, 'col-purchasesVET')

    if (options.showPrices) {

        let myPurchasesVETValueElement = await getElementFromTemplate(myNewElement, 'col-purchasesVET-value')

        await setElementTextDirect('', getFormattedLocalNumber(artist.purchasesVETTotalValue), myPurchasesVETValueElement)
        await showElement('', myPurchasesVETElement)

    } else {

        await hideElement('', myPurchasesVETElement)
    }

    return myNewElement

}


// Render icons to graph
const renderGraphIcons = async (containerID, graph, containerElement = null, resetContainer = false) => {

    // Find the main container
    let myContainerElement = (containerElement ? containerElement : await findElementByID(containerID))

    if (!myContainerElement) return

    let myCopyElement = await getElementFromTemplate(myContainerElement, 'copy_div', true)

    if (myCopyElement) {

        await clearElement('', myCopyElement)

        myContainerElement = myCopyElement
    }

    // Find the template 
    const myTemplate = await findElementByID(cTemplateGraphIcons)

    if (!myTemplate) {

        logError('Cannot find template ', cTemplateGraphIcons)

        return
    }

    let myNewGraphIconElement = myTemplate.content.firstElementChild.cloneNode(true)

    let myDownloadElement = await getElementFromTemplate(myNewGraphIconElement, 'download')

    if (myDownloadElement) {

        myDownloadElement.outerHTML = '<a data-graph="' + graph.getImageURI() + '" onClick="javascript:wov_download_graph(this)" class="gi" title="Copy as image"><i class="fas fa-images"></i></a>';

    }

    myContainerElement.appendChild(myNewGraphIconElement)
}



const showWorker = async (text = "") => {

    await setElementTextDirect(cElementIDWorkerText, text)

    await showElement(cElementIDWorkerBox)
}


const hideWorker = async () => {

    await hideElement(cElementIDWorkerBox)
}

const showAllWorkerIcons = async (stepIndex = 0) => {

    (document.querySelectorAll('#com-working' + (stepIndex !== 0 ? '-' + stepIndex.toString() : '')) || []).forEach(async ($el) => {
        await showElement('', $el)
    })

}

const hideAllWorkerIcons = async (stepIndex = 0) => {

    (document.querySelectorAll('#com-working' + (stepIndex !== 0 ? '-' + stepIndex.toString() : '')) || []).forEach(async ($el) => {
        await hideElement('', $el)
    })

}



export {
    cTokenInfoOptions_DEFAULT,
    cTokenInfoOptions_ARTIST,
    cTokenInfoOptions_COLLECTOR,
    cTokenInfoOptions_IMAGE_ONLY,
    cTokenInfoOptions_TOKEN_INFO,
    wrapContentAsColumn,
    getTemplateNameTokenInfo,
    getTemplateNameArtistInfo,
    getDefaultThumbNailImage,
    renderTokenInfo,
    renderArtistInfo,
    renderGraphIcons,
    showWorker,
    hideWorker,
    showAllWorkerIcons,
    hideAllWorkerIcons
}