// Common Crypto Functionality

import md5 from 'blueimp-md5'
import Cookies from 'js-cookie'
import { logError } from '../app/utils/common'

// Wallet Address Holder Wrapper
class WalletAddress {


    cIDAddress = 'adr'
    cIDSignature = 'sig'
    cIDVerifier = 'vef'
    cCookieExpiresDays = 90
    _IDAddress = ""
    _IDSignature = ""
    _IDVerifier = ""
    _address = null
    _signature = null
    _verifier = null

    constructor(chainIdentifier = "DEF") {

        this._IDAddress = chainIdentifier + '_' + this.cIDAddress
        this._IDSignature = chainIdentifier + '_' + this.cIDSignature
        this._IDVerifier = chainIdentifier + '_' + this.cIDVerifier

        this._address = this._getStoredAddress()
        this._signature = this._getStoredSignature()
        this._verifier = this._getStoredVerifier()

        if (!this._verifyAddressSignature) {

            this.resetAddress()
        }

    }

    _getStoredAddress() {

        return Cookies.get(this._IDAddress)
    }

    _getStoredSignature() {

        return Cookies.get(this._IDSignature)
    }

    _getStoredVerifier() {

        return Cookies.get(this._IDVerifier)
    }

    _getVerifierValue() {
        return md5(this._address, this._signature)
    }

    _verifyAddressSignature() {

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

            if (!this._address || this._address.length === 0 || !/^0x[0-9a-f]+$/i.test(this._address) || this._address.length % 2 !== 0) {

                logError('Invalid stored Address')

                return false
            }

            return true

        } else {

            if (this._address && this._address.length !== 0 && this._signature && this._signature.length !== 0) {

                if (!/^0x[0-9a-f]+$/i.test(this._address) || this._address.length % 2 !== 0) {

                    logError('Invalid stored Address')

                    return false
                }

                if (!/^0x[0-9a-f]+$/i.test(this._signature) || this._signature.length % 2 !== 0) {

                    logError('Invalid stored Signature')

                    return false
                }

                const myVerifier = this._getVerifierValue()

                if (this._verifier !== myVerifier) {

                    logError('Invalid verifier')

                    return false
                }

                return true

            } else return false

        }

    }

    isValueValidAddress(value) {

        if (!/^0x[0-9a-f]+$/i.test(value) || value.length % 2 !== 0) {

            return false
        } else return true

    }

    hasValidAddress() {

        return this._verifyAddressSignature()
    }

    getAddress() {

        return this._address
    }

    setAddressSigned(signResult) {

        const address = signResult.annex.signer
        const signature = signResult.signature

        this.setAddress(address)
        this.setSignature(signature)

    }

    setAddress(address) {

        this._address = address

        Cookies.set(this._IDAddress, address, { expires: this.cCookieExpiresDays })
    }

    setSignature(signature) {

        this._signature = signature
        this._verifier = this._getVerifierValue()

        Cookies.set(this._IDSignature, signature, { expires: this.cCookieExpiresDays })
        Cookies.set(this._IDVerifier, this._verifier, { expires: this.cCookieExpiresDays })

    }

    resetAddress() {

        this._address = null
        this._signature = null
        this._verifier = null

        Cookies.remove(this._IDAddress)
        Cookies.remove(this._IDSignature)
        Cookies.remove(this._IDVerifier)

    }
}

export { WalletAddress }