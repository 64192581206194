
// Local
import 'whatwg-fetch'
import './app/start'
import {
    findElementByID, hideElement, showElement, getElementData, copyElementTextData,
    setElementFASpinning, setElementFAInverted
} from './app/utils/dom'
import { initChains } from './crypto/init-chains'
import { logText, logData, copyTextDataToClipboard, copyImageDataToClipboard, copyDataIDToClipboard, getTimeLeftText } from './app/utils/common'
import { showPageInfo, hidePageInfo, showApplicationErrorInfo, hideApplicationErrorInfo } from './app/general'
import { showWalletConnectErrorInfo, showWalletConnectNeededInfo, hideWalletConnectNeededInfo, showWalletSync1ErrorInfo } from './app/wallet'
import { cAccountTypes, cTokenDetailsTypes } from './common/constants'
import {
    initializeAccount, renderAccountInfo, updateAccountInfo, clearAccountInfo, resetAccountInfo, showAccountLoader,
    hideAccountLoader, showAccountNone, updateAutoRefreshTimerValue, showAutoRefreshTimer, hideAutoRefreshTimer
} from './app/account'
import {
    isArtistPage, resetArtistContent, renderCollections as renderArtistCollections,
    renderLatestSalesToken as renderArtistLatestSalesToken,
    renderLatestNewCollectors as renderArtistLatestNewCollectors,
    renderTotalSalesQuickInfo as renderArtistTotalSalesQuickInfo,
    renderTotalCollectorsInfo as renderArtistTotalCollectorsInfo,
    renderSalesHistory as renderArtistSalesHistory,
    renderCollectionEditionsGraph as renderArtistCollectionEditionsGraph,
    renderCollectionCollectorsGraph as renderArtistCollectionCollectorsGraph,
    renderEditionsSalesGlobalGraph as renderArtistEditionsSalesGlobalGraph,
    renderEditionsSalesCollectionsGraph as renderArtistEditionsSalesCollectionsGraph,
    renderTopCollectors as renderArtistTopCollectors,
    renderTopCollectorsGraph as renderArtistTopCollectorsGraph,
    showArtistContent, showArtistNoContent, showArtistLoader, hideArtistLoader, showArtistNoSales,
    showTopCollectorsGraph as showArtistTopCollectorsGraph,
    hideTopCollectorsGraph as hideArtistTopCollectorsGraph,
    renderPrimarySalesPricesEditionsGraph as renderArtistPrimarySalesPricesEditionsGraph,
    renderPrimarySalesPricesValueGraph as renderArtistPrimarySalesPricesValueGraph
} from './app/artists'
import {
    isCollectorPage, resetCollectorContent, showCollectorContent, showCollectorNoContent, showCollectorLoader,
    hideCollectorLoader, showCollectorCollectionLoader, showCollectorArtTopLoader, hideCollectorCollectionLoader,
    hideCollectorArtTopLoader,
    renderCollections as renderCollectorCollections,
    renderTopArtists as renderCollectorTopArtists,
    renderTotalPurchasesQuickInfo as renderCollectorTotalPurchasesQuickInfo,
    renderLatestPurchaseToken as renderCollectorLatestPurchaseToken,
    renderCollectorSummaryQuickInfo,
    renderPurchasesHistory as renderCollectorPurchasesHistory,
    renderTopArtistsGraph as renderCollectorTopArtistsGraph,
    showTopArtistsGraph as showCollectorTopArtistsGraph,
    hideTopArtistsGraph as hideCollectorTopArtistsGraph,
    renderArtistsAllGraph as renderCollectorArtistsAllGraph,
    renderPurchasesByDateGraph as renderCollectorPurchasesByDateGraph,
    renderCollectionEditionsTypesGraph as renderCollectorCollectionEditionsTypesGraph,
    renderPurchasesPricesValueGraph as renderCollectorPurchasesPricesValueGraph,
    renderMissingArtists as renderCollectorMissingArtists,
    renderMissingArtistsLastOnSale as renderCollectorMissingArtistsLastOnSale,
    renderMissingArtistsSingleOnSale as renderCollectorMissingArtistsSingleOnSale
} from './app/collectors'
import {
    isTokenInfoPage, showTokenContent, resetTokenContent, showTokenLookup, hideTokenLookup, showTokenResult, hideTokenResult,
    renderLookUpTokenInfo as renderTokenLookupInfo,
    hideTokenRestart, showTokenRestart,
    hideErrorMessage as hideTokenErrorMessage, showErrorMessage as showTokenErrorMessage
} from './app/token'
import {
    isTestPage, hideTestContent,
    renderTestLookUpToken
} from './app/test'

import { showWorker, hideWorker, showAllWorkerIcons, hideAllWorkerIcons, cTokenInfoOptions_COLLECTOR } from './app/common'

// External
import { GoogleCharts } from 'google-charts'


// Constants
const cTopCollectorsCount = 5
const cTopArtistsCount = 5
const cTopLatestCount = 10
const cTopAll = 9999
const cMissingArtistsCount = 4

const cButtonFARefresh = 'button-fa-refresh'
const cButtonFAAutoRefresh = 'button-fa-auto-refresh'

const cIntervalRefreshSeconds = 300

window.wov_IsRefreshing = false
window.wovRefreshTimer = null

// Main Chain 
let chainConnector = null;


// Main Async Wrapper
(async () => {
    try {

        window.chainConnector = await initChains()

        await _resetApp()

        const requiresWallet = await _pageRequiresWallet()

        if (requiresWallet) {


            // If we have our sync 2 
            if (window.chainConnector) {

                logText("Chains initialized ... !")

                if (window.chainConnector.hasWalletAddress()) {

                    // Start preloading google charts
                    await GoogleCharts.load('current', { 'packages': ['corechart', 'controls'] });

                    // Render page with wallet
                    await _renderPageWithWallet()

                } else {

                    await resetAccountInfo()

                    await showWalletConnectNeededInfo()

                }

            } else {

                // Deal with the fact the chain failed
                logText('Unable to obtain chain connector')

                await showWalletConnectErrorInfo()
            }

        } else {

        }

    } catch (error) {

        // Deal with the fact the chain failed
        console.error('Unable to start main due to unforeseen execption')
        console.log(error)

        await resetArtistContent()
        await resetCollectorContent()
        await resetTokenContent()

        await hideAccountLoader()

        await showApplicationErrorInfo()
    }
})();

// Resets the app
const _resetApp = async () => {

    await showPageInfo()
    await resetAccountInfo()
    await resetArtistContent()
    await resetCollectorContent()
    await resetTokenContent()
}

// Checks if a page requires a wallet
const _pageRequiresWallet = async () => {

    let artistPage = await isArtistPage()
    let collectorPage = await isCollectorPage()
    let tokenPage = await isTokenInfoPage()
    let testPage = await isTestPage()

    return (artistPage || collectorPage || tokenPage || testPage)
}

// Render Page with Wallet
const _renderPageWithWallet = async () => {

    // If we are on artist page
    if (await isArtistPage()) {

        await _showAccountInfo(cAccountTypes.Artist)

        await _showArtistInfo()

    } else if (await isCollectorPage()) {

        await _showAccountInfo(cAccountTypes.Collector)

        await _showCollectorInfo()

    } else if (await isTokenInfoPage()) {

        await _showAccountInfo(cAccountTypes.Token)

        await showTokenContent()

        if (window.WovAccountReader.checkHasGivenTokenID()) {

            await window.token_info_lookup(window.WovAccountReader.getGivenTokenID())

        } else {

            await showTokenLookup()
            await hideTokenResult()

        }

    } else if (await isTestPage()) {

        // Only if we are in dev mode
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

            window.WovAccountReader = await initializeAccount(window.chainConnector, cAccountTypes.Test)

        } else {


            await hideTestContent()
        }

    }
}


// Shows the account info panel
const _showAccountInfo = async (accountType = cAccountTypes.Artist) => {

    await hideWalletConnectNeededInfo()

    await hidePageInfo()

    await showAccountLoader()

    window.WovAccountReader = await initializeAccount(window.chainConnector, accountType)

    if (window.WovAccountReader.isValidAccount()) {

        await window.WovAccountReader.initializeAccountFurther()

        window.WovAccountInfo = window.WovAccountReader.getAccountInfo()

        await renderAccountInfo(window.WovAccountInfo, accountType, window.WovAccountReader.isOverruledAccount())

        await hideAccountLoader()

    } else {

        await hideAccountLoader()

        window.WovAccountInfo = null

        if (!window.WovAccountReader.isWovAccount()) {

            await showAccountNone()

        } else {

            // Hmm some temp issue with the market place ?
        }

    }

}

const _showArtistInfo = async () => {

    if (!window.WovAccountReader.isWovAccount()) {

        return

    }

    if (window.WovAccountReader.isArtist()) {

        await showArtistLoader()

        const isLoaded = await window.WovAccountReader.loadArtistCreations()

        await window.WovAccountReader.generateArtistStats()

        window.WovAccountInfo = window.WovAccountReader.getAccountInfo()

        await updateAccountInfo(window.WovAccountInfo, cAccountTypes.Artist)

        if (window.WovAccountReader.hasSales()) {

            await renderArtistLatestSalesToken(window.WovAccountReader.getArtistSalesPrimaryLatest())

            await renderArtistLatestNewCollectors(window.WovAccountReader.getArtistCollectorsNew(), 1)

            await renderArtistTotalSalesQuickInfo(window.WovAccountInfo)

            await renderArtistTotalCollectorsInfo(window.WovAccountInfo)

            await renderArtistCollections(window.WovAccountReader.getArtistCollections(), window.WovAccountReader.getArtistCollectors())

            await switchArtistTopCollector(false)

            await renderArtistSalesHistory(window.WovAccountReader.getArtistSalesPrimary(), cTopLatestCount)

            await GoogleCharts.load(_drawArtistCharts, { 'packages': ['corechart', 'controls'] });

            await showArtistContent()

            await hideArtistLoader()

            await afterInitialDisplayArtistDataGeneration()

        } else {

            await showArtistNoSales()

            await hideArtistLoader()


        }

        // Debug Info
        logText('== ARTIST ==')
        logData('Account', window.WovAccountInfo)
        logData('Creations', window.WovAccountReader.getArtistCreations())
        logData('Tokens', window.WovAccountReader.getArtistTokens())
        logData('Collections', window.WovAccountReader.getArtistCollections())
        logData('Collectors', window.WovAccountReader.getArtistCollectors())
        logData('On Sale', window.WovAccountReader.getArtistOnSale())
        logData('Sales History', window.WovAccountReader.getArtistSales())
        logData('NEW Collectors', window.WovAccountReader.getArtistCollectorsNew())

    } else {

        await clearAccountInfo(cAccountTypes.Artist)

        await showArtistNoContent()

    }

}


const _showCollectorInfo = async () => {

    if (!window.WovAccountReader.isWovAccount()) {

        return

    }

    await showCollectorLoader()

    //await window.WovAccountReader.loadCollectorCollected()

    if (window.WovAccountReader.isCollector()) {

        // Start Collector

        logText('== COLLECTOR ==')

        await window.WovAccountReader.generateCollectorStats()

        window.WovAccountInfo = window.WovAccountReader.getAccountInfo()

        logData('Account', window.WovAccountInfo)

        await updateAccountInfo(window.WovAccountInfo, cAccountTypes.Collector)

        await renderCollectorLatestPurchaseToken(window.WovAccountReader.getCollectorPurchasesLatest(1))
        await renderCollectorTotalPurchasesQuickInfo(window.WovAccountInfo)
        await renderCollectorPurchasesHistory(window.WovAccountReader.getCollectorPurchases(), cTopLatestCount)

        logData('Collected', window.WovAccountReader.getCollectorCollected())
        logData('Purchased', window.WovAccountReader.getCollectorPurchases())
        logData('Tokens', window.WovAccountReader.getCollectorTokens())
        logData('Editions', window.WovAccountReader.getCollectorEditions())

        await showCollectorContent()

        await hideCollectorLoader()

        // Show loaders
        await showCollectorCollectionLoader()

        // Collections       

        logData('Collections', window.WovAccountReader.getCollectorCollections())

        //await window.WovAccountReader.generateCollectorCollections()

        //await renderCollectorCollections(window.WovAccountReader.getCollectorCollections())

        await hideCollectorCollectionLoader()

        // Artists       

        await switchCollectorTopArtists()

        await GoogleCharts.load(_drawCollectorCharts, { 'packages': ['corechart', 'controls'] });

        logData('Artists', window.WovAccountReader.getCollectorArtists())

        // Continue loading of additional data while screen is visible        
        await afterInitialDisplayCollectorDataGeneration()
    }
    else {

        await hideCollectorLoader()

        await clearAccountInfo(cAccountTypes.Collector)

        await showCollectorNoContent()
    }

}



// Draw Artist Charts
const _drawArtistCharts = async () => {

    await renderArtistCollectionEditionsGraph(window.WovAccountReader.getArtistCollections(), cTopCollectorsCount)
    await renderArtistCollectionCollectorsGraph(window.WovAccountReader.getArtistCollections(), cTopCollectorsCount)
    await renderArtistEditionsSalesGlobalGraph(window.WovAccountInfo)
    await renderArtistEditionsSalesCollectionsGraph(window.WovAccountReader.getArtistCollections())
    await renderArtistPrimarySalesPricesEditionsGraph(window.WovAccountInfo)
    await renderArtistPrimarySalesPricesValueGraph(window.WovAccountInfo)
    await renderArtistTopCollectorsGraph(window.WovAccountReader.getArtistCollectors(window.wov_topcollector_switcher_sortFunction), cTopCollectorsCount, window.wov_topcollector_switcher_byEditions)

}


// Draw Collector Charts
const _drawCollectorCharts = async () => {

    await renderCollectorTopArtistsGraph(window.WovAccountReader.getCollectorArtists(), cTopCollectorsCount)
    await renderCollectorArtistsAllGraph(window.WovAccountReader.getCollectorArtists())
    await renderCollectorCollectionEditionsTypesGraph(window.WovAccountInfo)
    await renderCollectorPurchasesPricesValueGraph(window.WovAccountInfo)
    await renderCollectorPurchasesByDateGraph(window.WovAccountReader.getCollectorPurchases(), true)
    await renderCollectorPurchasesByDateGraph(window.WovAccountReader.getCollectorPurchases(), false)
}

// Generate additional data for Artists after display
const afterInitialDisplayArtistDataGeneration = async () => {


    await showAllWorkerIcons()

    // Secondary Sales
    await showWorker('Determining Secondary Sales')

    await window.WovAccountReader.afterInitialDisplaySecondarySalesDataGeneration().then(async (result) => {

        //await renderCollectorSummaryQuickInfo(window.WovAccountInfo)     

        logData('Secondary Sales Data', window.WovAccountReader.getArtistSalesSecondaryData())

    })

    // Remove worker
    await hideWorker()

    // Hide all worker icods
    await hideAllWorkerIcons()
}


// Generate additional data for Collector after display
const afterInitialDisplayCollectorDataGeneration = async () => {


    await showAllWorkerIcons()

    // Artists
    await showWorker('Generating Additional Artists Data')

    await window.WovAccountReader.afterInitialDisplayArtistsDataGeneration().then(async (result) => {

        await renderCollectorSummaryQuickInfo(window.WovAccountInfo)

        await renderCollectorMissingArtistsLastOnSale(await window.WovAccountReader.getRandomMissingArtists(1, true, true), 1)
        await renderCollectorMissingArtistsSingleOnSale(await window.WovAccountReader.getRandomMissingArtists(1, true, false, true), 1)

        await renderCollectorMissingArtists(await window.WovAccountReader.getRandomMissingArtists(cMissingArtistsCount), cMissingArtistsCount)


        logData('Artists Updated', window.WovAccountReader.getCollectorArtists())
    })

    // Remove worker
    await hideWorker()

    // Hide all worker icods
    await hideAllWorkerIcons()
}

// Token Info Lookup
window.token_info_lookup = async (tokenID = null, sourceContainer = null) => {

    await hideTokenErrorMessage()

    let myTokenID = null

    if (tokenID) {

        myTokenID = tokenID

        await hideTokenLookup()

    } else {

        const myEditionIDInputElement = await findElementByID('edition_id')
        const myTokenIDInputElement = await findElementByID('token_id')
        const myEditionNumberInputElement = await findElementByID('edition_number')

        if (myEditionIDInputElement.value !== ''
            || (myTokenIDInputElement.value !== '' && myEditionNumberInputElement.value !== '')) {

            if (myEditionIDInputElement.value !== '') {

                myTokenID = myEditionIDInputElement.value

            } else {

                let myTokenIDAsNumber = Number.parseInt(myTokenIDInputElement.value)
                let myEditionNumber = Number.parseInt(myEditionNumberInputElement.value)

                myTokenID = new Number(myTokenIDAsNumber + myEditionNumber).toString()
            }

        } else {

            await showTokenErrorMessage(`Please provide an input value - either an edition ID or a token ID / edition number.`)
            return

        }

    }

    if (window.WovAccountReader.isValueValidTokenID(myTokenID)) {

        const myTokenInfo = await window.WovAccountReader.lookupTokenInfo(new Number(myTokenID))

        if (myTokenInfo) {

            await hideTokenLookup()
            await renderTokenLookupInfo(myTokenInfo, sourceContainer)
            await showTokenResult()
            await showTokenRestart()

            if (window.WovAccountReader.setUrlTokenID) {

                window.WovAccountReader.setUrlTokenID(myTokenID)
            }

        } else {

            await showTokenErrorMessage(`Cannot find info for given token ID '${myTokenID}'. Are you sure this is an exisitng World of V token ?`)
        }

    } else {

        await showTokenErrorMessage(`Given value '${myTokenID}' is not a valid token ID.`)

    }

}

// Token Info Reset
window.token_info_reset = async () => {

    await hideTokenErrorMessage()

    const myEditionIDInputElement = await findElementByID('edition_id')
    const myTokenIDInputElement = await findElementByID('token_id')
    const myEditionNumberInputElement = await findElementByID('edition_number')

    if (myEditionIDInputElement) { myEditionIDInputElement.value = '' }
    if (myTokenIDInputElement) { myTokenIDInputElement.value = '' }
    if (myEditionNumberInputElement) { myEditionNumberInputElement.value = '1' }

    myEditionIDInputElement.focus()
}

// Token Info Reset
window.token_info_restart = async () => {

    await hideTokenResult()
    await hideTokenRestart()
    await showTokenLookup()
    await window.token_info_reset()
}


// TEST

window.test_token_lookup = async () => {

    const myTokenIDInputElement = await findElementByID('token_info_id')

    const myTokenID = new Number(myTokenIDInputElement.value)

    const myTokenInfo = await window.WovAccountReader.lookupTokenInfo(myTokenID)

    if (myTokenInfo) {

        await renderTestLookUpToken(myTokenInfo)

        //const myTokenActivityInfo = await window.WovAccountReader.getUserActivity(myTokenInfo.smartContractAddress ,myTokenID)

        //logData('[NEW] Token Activity', myTokenActivityInfo)

    }
}

window.test_user_activity_lookup = async () => {

    const myUserAddress = await findElementByID('user_info_id')

    const myUserActivityInfo = await window.WovAccountReader.getUserActivity(myUserAddress.value)

    logData('[NEW] User Activity', myUserActivityInfo)

}


//* Event Handlers *//


// Registger button handlers ( note needs to be 'global' but webpack does not do so by default so hence they are created on the window object )
window.wov_login = async () => {

    await showAccountLoader()

    const walletAddress = await window.chainConnector.logIn();

    if (walletAddress) {

        return await _renderPageWithWallet()

    } else {

        // Deal with the fact the chain failed
        logText('Unable to obtain chain connector')

        await showWalletConnectErrorInfo()

        return false

    }
}

// Log out Button Handler
window.wov_logout = async () => {

    await _resetApp()

    await window.chainConnector.logOut()

    await showWalletConnectNeededInfo()

    return true

}

// Switch Artist Top Collectors View
window.switchArtistTopCollector = async (drawGraphs = true) => {

    let mySortFunction = null

    if (window.wov_topcollector_switcher_byEditions) {

        mySortFunction = window.WovAccountReader.getSorterByTotalSalesPriceVET()

        window.wov_topcollector_switcher_byEditions = false

    } else {

        mySortFunction = window.WovAccountReader.getSorterByItemsCollected()

        window.wov_topcollector_switcher_byEditions = true
    }

    window.wov_topcollector_switcher_sortFunction = mySortFunction

    const myCollectors = window.WovAccountReader.getArtistCollectors(mySortFunction)

    await renderArtistTopCollectors(myCollectors, cTopCollectorsCount, window.wov_topcollector_switcher_byEditions)

    if (drawGraphs) {

        await renderArtistTopCollectorsGraph(myCollectors, cTopCollectorsCount, window.wov_topcollector_switcher_byEditions)

    }

}

// Switch Collector Top Artists View
window.switchCollectorTopArtists = async (drawGraphs = true) => {

    await showCollectorArtTopLoader()

    let mySortFunction = null

    if (window.wov_topartist_switcher_byEditions) {

        mySortFunction = window.WovAccountReader.getSorterByTotalPurchasePriceVET()

        window.wov_topartist_switcher_byEditions = false

    } else {

        mySortFunction = window.WovAccountReader.getSorterByEditionsCollected()

        window.wov_topartist_switcher_byEditions = true
    }

    window.wov_topartist_switcher_sortFunction = mySortFunction

    const myArtists = window.WovAccountReader.getCollectorArtists(mySortFunction)

    await window.WovAccountReader.completeCollectorArtists(myArtists, cTopArtistsCount)

    await renderCollectorTopArtists(myArtists, cTopArtistsCount, window.wov_topartist_switcher_byEditions)

    if (drawGraphs) {

        await renderCollectorTopArtistsGraph(myArtists, cTopArtistsCount, window.wov_topartist_switcher_byEditions)

    }

    await hideCollectorArtTopLoader()

}

// Modals - Artist Collectors
window.wov_render_artist_collectors_all = async (targetContainer) => {

    // Modal
    const myModalDataElement = await findElementByID('modal-collectors')

    // Get current sort
    let mySortFunction = null

    if (window.wov_topcollector_switcher_byEditions) {

        mySortFunction = window.WovAccountReader.getSorterByItemsCollected()

    } else {

        mySortFunction = window.WovAccountReader.getSorterByTotalSalesPriceVET()
    }

    // Render all collectors with current active sort
    await renderArtistTopCollectors(window.WovAccountReader.getArtistCollectors(mySortFunction), cTopAll, window.wov_topcollector_switcher_byEditions, myModalDataElement, true, true)

}

// Modals - Artist Sales History
window.wov_render_artist_sales_history_all = async (targetContainer) => {

    const myModalDataElement = await findElementByID('modal-sales-history')
    await renderArtistSalesHistory(window.WovAccountReader.getArtistSalesPrimary(), cTopAll, myModalDataElement, true, true)
}

// Modals - Artist New Collectors
window.wov_render_artist_new_collectors_all = async (targetContainer) => {

    const myModalDataElement = await findElementByID('modal-first-collectors')
    await renderArtistLatestNewCollectors(window.WovAccountReader.getArtistCollectorsNew(), 20, myModalDataElement, true, true)
}


// Modals - Collector Artists All
window.wov_render_collector_artists_all = async (targetContainer) => {

    // Modal
    const myModalDataElement = await findElementByID('modal-artists')

    // Get current sort
    let mySortFunction = null

    if (window.wov_topartist_switcher_byEditions) {

        mySortFunction = window.WovAccountReader.getSorterByEditionsCollected()

    } else {

        mySortFunction = window.WovAccountReader.getSorterByTotalPurchasePriceVET()
    }

    let myArtists = window.WovAccountReader.getCollectorArtists(mySortFunction)

    await window.WovAccountReader.completeCollectorArtists(myArtists, cTopAll)

    // Render all artists with current active sort
    await renderCollectorTopArtists(myArtists, cTopAll, window.wov_topartist_switcher_byEditions, myModalDataElement, true, true)

}

// Modals - Collector Purchases History
window.wov_render_collector_purchases_history_all = async (targetContainer) => {

    const myModalDataElement = await findElementByID('modal-purchases-history')
    await renderCollectorPurchasesHistory(window.WovAccountReader.getCollectorPurchases(), cTopAll, myModalDataElement, true, true)

}

// Modals - Token Info
window.wov_render_token_info = async (element) => {

    const myTokenID = await getElementData('', 'token-id', element)

    if (myTokenID) {

        const myModalContainerElement = await findElementByID('modal-token-info')
        const myModalDataElement = await findElementByID('modal-token-result')

        if (myModalContainerElement && window.WovAccountReader.isValueValidTokenID(myTokenID)) {

            const myTokenInfo = await window.WovAccountReader.lookupTokenInfo(new Number(myTokenID))

            if (myTokenInfo) {

                await renderTokenLookupInfo(myTokenInfo, myModalDataElement, true, true)

                myModalContainerElement.classList.add('is-active')
            }
        }

    }
}

// Copy Top Collectors Twitter Combined
window.copyColTwitter = async () => {

    await copyElementTextData('col-top-comb-twitter', 'col-top-twitter-copied')
}

// Copy Top Artists Twitter Combined
window.copyArtTwitter = async () => {

    await copyElementTextData('art-top-comb-twitter', 'art-top-twitter-copied')
}

window.copyDataToClipboard = async (element, dataID) => {

    await copyDataIDToClipboard(element, dataID)
}

// Copy Address
window.copyAddress = async (element) => {

    let myAddress = await getElementData('', 'ID', element)

    await copyTextDataToClipboard(myAddress)
}


// Donate
window.wov_donateVET = async (amountVET) => {

    try {

        await hideElement('donate-maybe')
        await hideElement('donate-error')
        await hideElement('donate-success')

        await window.chainConnector.sendVETAmount(amountVET, `Donation of '${amountVET}' VET to support WoV Tools`)

        await hideElement('donate-buttons')

        await showElement('donate-success')

    } catch (error) {

        let myError = error.toString()
        logText(myError)

        if (myError.toLowerCase().includes('user decline')) {

            await showElement('donate-maybe')

        } else {

            await showElement('donate-error')
        }


    }
}

window.wov_donateWOV = async (amountWOV) => {

    try {

        await hideElement('donate-maybe')
        await hideElement('donate-error')
        await hideElement('donate-success')

        await window.chainConnector.sendWOVAmount(amountWOV, `Donation of '${amountWOV}' WOV to support WoV Tools`)

        await hideElement('donate-buttons')

        await showElement('donate-success')

    } catch (error) {

        let myError = error.toString()
        logText(myError)

        if (myError.toLowerCase().includes('user decline')) {

            await showElement('donate-maybe')

        } else {

            await showElement('donate-error')
        }


    }
}


// Sponsor
window.wov_sponsorVET = async (amountVET) => {

    try {

        await hideElement('sponsor-maybe')
        await hideElement('sponsor-error')
        await hideElement('sponsor-success')

        await window.chainConnector.sendVETAmount(amountVET, `Become a sponsor of WoV Tools by contributing '${amountVET}' VET`)

        await hideElement('sponsor-buttons')

        await showElement('sponsor-success')

    } catch (error) {

        let myError = error.toString()
        logText(myError)

        if (myError.toLowerCase().includes('user decline')) {

            await showElement('sponsor-maybe')

        } else {

            await showElement('sponsor-error')
        }


    }
}

window.wov_sponsorWOV = async (amountWOV) => {

    try {

        await hideElement('sponsor-maybe')
        await hideElement('sponsor-error')
        await hideElement('sponsor-success')

        await window.chainConnector.sendWOVAmount(amountWOV, `Become a sponsor of WoV Tools by contributing '${amountWOV}' WOV`)

        await hideElement('sponsor-buttons')

        await showElement('sponsor-success')

    } catch (error) {

        let myError = error.toString()
        logText(myError)

        if (myError.toLowerCase().includes('user decline')) {

            await showElement('sponsor-maybe')

        } else {

            await showElement('sponsor-error')
        }


    }
}



// Refresh Artist
window.wov_refresh_artist = async () => {

    return await window.wov_refresh_page(resetArtistContent)
}

// Refresh Collector
window.wov_refresh_collector = async () => {

    return await window.wov_refresh_page(resetCollectorContent)
}

// Refresh content page
window.wov_refresh_page = async (resetContentFunction) => {

    if (!window.wov_IsRefreshing) {

        window.wov_IsRefreshing = true

        if (window.chainConnector.hasWalletAddress()) {

            await setElementFASpinning(cButtonFARefresh, true)

            await hideApplicationErrorInfo()

            await resetAccountInfo()
            await resetContentFunction()
            await _renderPageWithWallet()

            await setElementFASpinning(cButtonFARefresh, false)

        } else {

            await resetAccountInfo()

            await showWalletConnectNeededInfo()

        }

        window.wov_IsRefreshing = false

    }
}


// Collector refresh missing artists
window.wov_collector_refresh_missing_artists = async () => {

    await renderCollectorMissingArtists(await window.WovAccountReader.getRandomMissingArtists(cMissingArtistsCount), cMissingArtistsCount)

}

// Collector refresh missing artists last on sale
window.wov_collector_refresh_missing_artists_lastonsale = async () => {

    await renderCollectorMissingArtistsLastOnSale(await window.WovAccountReader.getRandomMissingArtists(1, true, true), 1)

}

// Collector refresh missing artists single on sale
window.wov_collector_refresh_missing_artists_singleonsale = async () => {

    await renderCollectorMissingArtistsSingleOnSale(await window.WovAccountReader.getRandomMissingArtists(1, true, false, true), 1)

}

// Auto Refresh
window.wov_start_refresh_artist = () => {

    window.wov_start_refresh(window.wov_refresh_artist).then(() => { logText('Auto refresh for artist started ...') })
}

window.wov_start_refresh_collector = async () => {

    await window.wov_start_refresh(window.wov_refresh_collector)
}

window.wov_start_refresh = async (functionName) => {

    await setElementFAInverted(cButtonFAAutoRefresh, true)

    window.wovRefreshTimeLeft = cIntervalRefreshSeconds

    await wow_refresh_timer_info(false)

    window.wovRefreshTimer = setInterval(
        () => {
            window.wovRefreshTimeLeft = cIntervalRefreshSeconds
            functionName()
        }, cIntervalRefreshSeconds * 1000)

    window.wovRefreshTimerInfo = setInterval(wow_refresh_timer_info, 1000)

    await showAutoRefreshTimer()

}

window.wow_refresh_timer_info = async (updateCounter = true) => {

    if (updateCounter) {
        --window.wovRefreshTimeLeft
    }

    const myRefreshTimeText = getTimeLeftText(window.wovRefreshTimeLeft)

    logText('Refresh time left ' + myRefreshTimeText)

    await updateAutoRefreshTimerValue(myRefreshTimeText)


}

window.wov_reset_refresh = async () => {

    clearInterval(window.wovRefreshTimer)
    clearInterval(window.wovRefreshTimerInfo)

    window.wovRefreshTimer = null
    window.wovRefreshTimerInfo = null

    await setElementFAInverted(cButtonFAAutoRefresh, false)

    await hideAutoRefreshTimer()
}

window.wov_auto_refresh_artist = () => {

    if (window.wovRefreshTimer) {

        window.wov_reset_refresh()

    } else {

        window.wov_start_refresh_artist()
    }
}

window.wov_auto_refresh_collector = () => {

    if (window.wovRefreshTimer) {

        window.wov_reset_refresh()

    } else {

        window.wov_start_refresh_collector()
    }
}

// Download Graph / Printable
window.wov_download_graph = async (element) => {

    var image = new Image()

    image.src = await getElementData('', 'graph', element)

    var w = window.open("");
    w.document.write(image.outerHTML);

}

//TODO - Keep or remove ?
window.wov_copy_graph = async (element) => {

    const base64ImageData = await getElementData('', 'graph', element)

    if (base64ImageData) {

        await copyImageDataToClipboard(base64ImageData, 'image/png')

    }

}




// Move
window.moveToAnchor = (targetAnchorID) => {

    var currentUrl = location.href
    document.location.hash = "#" + targetAnchorID

    // Reset to previous url
    history.replaceState(null, null, currentUrl)

}