
import { findElementByID, hideElement, showElement } from './utils/dom'


const cPageInfoID = "page-info"
const cApplicationErrorID = "page-applicationerror-info"

const showPageInfo = async () => {

    await showElement(cPageInfoID)
}

const hidePageInfo = async () => {

    await hideElement(cPageInfoID)
}

const showApplicationErrorInfo = async () => {

    await showElement(cApplicationErrorID)
}

const hideApplicationErrorInfo = async () => {

    await hideElement(cApplicationErrorID)
}

export {
    showPageInfo,
    hidePageInfo,
    showApplicationErrorInfo,
    hideApplicationErrorInfo
}